import { ServiceLocationPrice } from "../../classes/classes"
import { getValidatedData } from "../constants"

export function getConvertedPrices(prices=[]){

    return prices.filter((k)=>{
      return Object.keys(k).length>0
    }).map((price)=>{

        const id=getValidatedData(price.id,'number')
        const cost=getValidatedData(price.cost,'number')
        const unit=getValidatedData(price.unit,'number')
        const commission = getValidatedData(price.commission, "number");
        const type=getValidatedData(price.type)
        

        return new ServiceLocationPrice(id,cost,unit,type,commission);
    })

}