import React, { Component } from "react";

import { Card, CardBody, CardTitle, Badge, Button } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";

class LatestTranaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [
        {
          id: "customCheck2",
          orderId: "#SK2540",
          billingName: "Neal Matthews",
          Date: "07 Oct, 2019",
          total: "$400",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "customCheck3",
          orderId: "#SK2541",
          billingName: "Jamal Burnett",
          Date: "07 Oct, 2019",
          total: "$380",
          badgeClass: "danger",
          paymentStatus: "Chargeback",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "customCheck4",
          orderId: "#SK2542",
          billingName: "Juan Mitchell",
          Date: "06 Oct, 2019",
          total: "$384",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
        {
          id: "customCheck5",
          orderId: "#SK2543",
          billingName: "Barry Dick",
          Date: "05 Oct, 2019",
          total: "$412",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-mastercard",
          paymentMethod: "Mastercard",
          link: "#",
        },
        {
          id: "customCheck6",
          orderId: "#SK2544",
          billingName: "Ronald Taylor",
          Date: "04 Oct, 2019",
          total: "$404",
          badgeClass: "warning",
          paymentStatus: "Refund",
          methodIcon: "fa-cc-visa",
          paymentMethod: "Visa",
          link: "#",
        },
        {
          id: "customCheck7",
          orderId: "#SK2545",
          billingName: "Jacob Hunter",
          Date: "04 Oct, 2019",
          total: "$392",
          badgeClass: "success",
          paymentStatus: "Paid",
          methodIcon: "fa-cc-paypal",
          paymentMethod: "Paypal",
          link: "#",
        },
      ],
    };
  }

  render() {
    console.log("PROPS :- ",this.props.recentBookings);
    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Latest Bookings</CardTitle>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    {/* <th style={{ width: "20px" }}>
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck1"
                        >
                          &nbsp;
                        </label>
                      </div>
                    </th> */}
                    <th>Booking ID</th>
                    <th>Phone no</th>
                    <th>City</th>
                    <th>Service</th>
                    <th>From Date</th>

                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.recentBookings.length > 0 &&
                    this.props.recentBookings.map((transaction, key) => (
                      <tr key={"_tr_" + key}>
                        {/* <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={transaction.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={transaction.id}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td> */}
                        <td>
                          <Link to="#" className="text-body font-weight-bold">
                            {" "}
                            {transaction.bookingId}{" "}
                          </Link>{" "}
                        </td>

                        <td>{transaction.createdby.phonenumber}</td>
                        <td>{transaction.address.city}</td>
                        <td>
                          {transaction.serviceid
                            ? transaction.serviceid.name
                            : ""}
                        </td>

                        <td>
                          {
                            moment(transaction.fromtime).utc().format("DD MMM YYYY hh:mm:ss a")
                          }
                        </td>

                        <td>
                          <Link to={`/booking/${transaction.id}`}>
                            <Button
                              type="button"
                              color="primary"
                              size="sm"
                              className="btn-rounded waves-effect waves-light"
                              onClick={this.togglemodal}
                            >
                              View Details
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default LatestTranaction;
