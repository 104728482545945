import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Table, Button } from "reactstrap";
import {
  doDeleteOffer,
  doGetAllContacts,
  doGetAllContactsCount,
  doGetAllOffers,
  doGetAllOffersCount,
} from "../../../services/apiServices";
import moment from "moment";
import swal from "sweetalert";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
// import { offerFilterConfig } from "./OfferFilterConfig";

export default class ContactUs extends Component {
  state = {
    bookings: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
    filterQuery: "",
    offers: [],
  };

  getContacts = (
    currentPageNo,
    limit,
    filterQuery = this.state.filterQuery
  ) => {
    let query = `_limit=${limit}&_start=${
      (currentPageNo*limit) + (filterQuery !== "" ? "&" + filterQuery : "")
    }`;
    doGetAllContactsCount(filterQuery !== "" ? "&" + filterQuery : "").then(
      (res1) => {
        console.log(res1.data);
        this.setState((prev) => {
          let st = prev;
          st.pagination.totalPages = Math.ceil(res1.data / st.pagination.limit);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
        doGetAllContacts(query).then(
          (res) => {
            this.setState((prev) => {
              let st = prev;
              st.offers = res.data;
              return st;
            });
          },
          (err) => {}
        );
      }
    );
  };
  componentDidMount() {
    this.getContacts(0, this.state.pagination.limit);
  }

  doPagination = (currentPageNo) => {
    this.getContacts(currentPageNo, this.state.pagination.limit);
  };

  dofilterData = (query = "") => {
    this.getContacts(0, this.state.pagination.limit, query);
  };

  render() {
    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Contacts</h4>
        </div>

        <Row>
          {/* <Col md={12}>
            <FilterView
              doFilter={this.dofilterData}
              filterOptions={offerFilterConfig.filters}
            />
          </Col> */}
          <Col md={12}>
            <Table
              striped
              bordered
              hover
              className="table mb-3 pr-0"
              style={{ verticalAlign: "middle" }}
            >
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Date</th>

              </tr>
              {this.state.offers.map((offer, i) => {
                console.log(offer.newuser);
                return (
                  <tr key={i}>
                    <td>{offer.name}</td>
                    <td>{offer.email}</td>
                    <td>{offer.phone}</td>
                    <td>{offer.subject}</td>
                    <td>{offer.message}</td>
                    <td>{new Date(offer.created_at).toLocaleString()}</td>
                    {/* {console.log("offer.fromdate", offer.fromDate)} */}
                  </tr>
                );
              })}
            </Table>
          </Col>
          <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
