import axios from "axios";
import { doPostAuthLogin } from "../services/authServices";

const allusers = [
  {
    id: 1,
    username: "Admin",
    password: "admin@123",
    email: "admin@igdrones.cloud",
    type: "officialadmin",
  },
  {
    id: 2,
    username: "Pilot",
    password: "pilot@123",
    email: "pilot@igdrones.cloud",
    type: "pilot",
  },
];

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postRegister = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch((err) => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postLogin = async (url, data) => {
  // const validUser = allusers.filter(usr => usr.email === data.username && usr.password === data.password);
  // if (validUser['length'] === 1) {
  //     return validUser[0];
  //   } else {
  //     throw ["error"];
  //   }
  const reqdata = {
    identifier: data.username,
    password: data.password,
  };

  return doPostAuthLogin(JSON.stringify(reqdata)).then(
    (res) => {
      return res.data;
    },
    (err) => {
      //   console.log(err.data);
      throw ["Login Failed"];
    }
  );
  // return axios.post(url, data).then(response => {
  //     if (response.status === 400 || response.status === 500)
  //         throw response.data;
  //     return response.data;
  // }).catch(err => {
  //     throw err[1];
  // });
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  postForgetPwd,
};
