import { ImageModal, LocationModal, LocationPincodeModal } from "../../classes/classes"
import { getValidatedData } from "../constants";
import { getConvertedImages } from "./images";

export function getConvertedLocationsPincodes(pincodes=[],needOnlyActive=false){

    const data= pincodes.filter((k)=>{
      return Object.keys(k).length>0
    }).map((pincode)=>{
            const id=getValidatedData(pincode.id,'number');
            const code=getValidatedData(pincode.number);
            const name = getValidatedData(pincode.name);
            const active=getValidatedData(pincode.active,'boolean');
            return new LocationPincodeModal(id, code, name, active);
        });
        if(needOnlyActive)
        return data.filter((pincode)=>pincode.active);
        return data;
}

export function getConvertedLocations(locations=[],needOnlyActive=false){

    const data=locations.filter((k)=>{
      return Object.keys(k).length>0
    }).map((location)=>{
        const id=getValidatedData(location.id,'number');
        const name=getValidatedData(location.name);
        const description=getValidatedData(location.description);
        const gst = getValidatedData(location.gst_number);
        const active=getValidatedData(location.active,'boolean');
        const displayPics=getConvertedImages([getValidatedData(location.displayPic,'object')]);
        let displayPic=new ImageModal();
        if(displayPics.length>0){
          displayPic=(new ImageModal()).setObj(displayPics[0]);
        }
        const pincodes=getConvertedLocationsPincodes(getValidatedData(location.pincode,'array'),needOnlyActive);
        return new LocationModal(id, name, description, displayPic, gst,active, pincodes);
    })
    if(needOnlyActive)
    return data.filter((location)=>location.active);
    return data;
}