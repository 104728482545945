export class UserRoleModal {
  constructor(id = 0, name = "", type = "") {
    this.id = id;
    this.name = name;
    this.type = type;
  }
}

export class UserModal {
  constructor(
    id = 0,
    firstName = "",
    lastName = "",
    userName = "",
    email = "",
    mobile = "",
    dob = "",
    alternateMobile = "",
    confirmed = true,
    blocked = false,
    loginType = "WEBSITE",
    provider = "local",
    role = new UserRoleModal(),
    password = "",
    addresses = [],
    profilePic = new ImageModal()
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userName = userName;
    this.dob = dob;
    this.email = email;
    this.mobile = mobile;
    this.alternateMobile = alternateMobile;
    this.confirmed = confirmed;
    this.blocked = blocked;
    this.loginType = loginType;
    this.provider = provider;
    this.role = role;
    this.password = password;
    this.addresses = addresses;
    this.profilePic = profilePic;
  }
  getFullName() {
    return (this.firstName + " " + this.lastName).trim();
  }

  getAddRequest(user) {
    return {
      username: this.userName,
      email: this.email,
      provider: this.provider,
      password: this.password === "" ? undefined : this.password,
      confirmed: this.confirmed,
      blocked: this.blocked,
      role: this.role.id,
      firstname: this.firstName,
      lastname: this.lastName,
      logintype: this.loginType,
      phonenumber: this.mobile,
      otp: 0,
      alternatephonenumber: this.alternateMobile,
      created_by: user.id,
      updated_by: user.id,
      address: this.addresses.map((address) => address.getAddRequest()),
      profilepic: this.profilePic.id === 0 ? this.profilePic.id : null,
      dob: (this.dob !== "") ? this.dob : null,
    };
  }
  getUpdateRequest(user) {
    return {
      username: this.userName,
      email: this.email,
      provider: this.provider,
      password: this.password === "" ? undefined : this.password,
      confirmed: this.confirmed,
      blocked: this.blocked,
      role: this.role.id,
      firstname: this.firstName,
      lastname: this.lastName,
      logintype: this.loginType,
      phonenumber: this.mobile,
      otp: 0,
      alternatephonenumber: this.alternateMobile,
      updated_by: user.id,
      address: this.addresses.map((address) => address.getAddRequest()),
      profilepic: this.profilePic.id === 0 ? this.profilePic.id : undefined,
      dob: this.dob,
    };
  }
}

export class LocationPincodeModal {
  constructor(id = 0, code = "", name = "", active = "") {
    this.id = id;
    this.code = code;
    this.name = name;
    this.active = active;
  }
}

export class LocationModal {
  constructor(
    id = 0,
    name = "",
    description = "",
    displayPic = new ImageModal(),
    gst = "",
    active = false,
    pincodes = []
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.gst = gst;
    this.active = active;
    this.pincodes = pincodes;
    this.displayPic = displayPic;
  }

  getAddRequest(user) {
    return {
      name: this.name,
      description: this.description,
      gst_number: this.gst,
      active: this.active,
      displayPic: this.displayPic.id === 0 ? null : this.displayPic.id,
      pincode: this.pincodes.map((pincode) => {
        return {
          id: pincode.id === 0 ? undefined : pincode.id,
          name: pincode.name,
          number: pincode.code,
          active: pincode.active,
        };
      }),
      created_by: user.id,
      updated_by: user.id,
    };
  }
}

export class CategoryModal {
  constructor(id = 0, name = "", description = "", active = false) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.active = active;
  }
}

export class ImageModal {
  constructor(id = 0, name = "", url = "", ext = "", size = 0) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.ext = ext;
    this.size = size;
  }
  setObj(obj) {
    if (null === obj || "object" !== typeof obj) return obj;
    var copy = new ImageModal();
    for (var attr in copy) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    // console.log(copy);
    return copy;
  }
}

export class ServiceLocationPrice {
  constructor(id = 0, cost = 0, unit = 0, type = "", commission = 0) {
    this.id = id;
    this.cost = cost;
    this.unit = unit;
    this.type = type;
    this.commission = commission;
  }
}

export class ServiceLocation {
  constructor(
    id = 0,
    prices = [],
    city = new LocationModal(),
    userStatus = false,
    technicianStatus = false
  ) {
    this.id = id;
    this.prices = prices;
    this.city = city;
    this.userStatus = userStatus;
    this.technicianStatus = technicianStatus;
  }
}

export class FaqModal {
  constructor(id = 0, type = "", title = "", description = "", active = true) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.description = description;
    this.active = active;
  }

  getAddRequest() {
    return {
      id: this.id !== 0 ? this.id : undefined,
      title: this.title,
      description: this.description,
      active: this.active,
      type: this.type,
    };
  }
}

export class SEOModal {
  constructor(
    id = 0,
    title = "",
    description = "",
    keywords = "",
    image = new ImageModal(),
    active = false
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.keywords = keywords;
    this.image = image;
    this.active = active;
  }

  getAddRequest(imageId = 0) {
    let thumbnail = {};
    if (imageId === 0) {
      if (this.image.id === 0) {
        thumbnail = undefined;
      } else {
        thumbnail = { id: this.image.id };
      }
    } else {
      thumbnail = { id: imageId };
    }
    return {
      id: this.id !== 0 ? this.id : undefined,
      metatitle: this.title,
      metadescription: this.description,
      metakeywords: this.keywords,
      active: this.active,
      thumbnail: thumbnail,
    };
  }
}

export class ServiceModal {
  constructor(
    id = 0,
    name = "",
    details = [],
    keywords = "",
    problem = "",
    category = new CategoryModal(),
    serviceLocations = [],
    faqs = [],
    seo = new SEOModal(),
    images = [],
    websiteImages = [],
    displayPic = new ImageModal()
  ) {
    this.id = id;
    this.name = name;
    this.keywords = keywords;
    this.problem = problem;
    this.details = details;
    this.category = category;
    this.serviceLocations = serviceLocations;
    this.faqs = faqs;
    this.seo = seo;
    this.images = images;
    this.websiteImages = websiteImages;
    this.displayPic = displayPic;
  }

  getAddRequest() {
    let request = {};
    request.id = this.id;
    request.name = this.name;
    request.details = this.details.map((data) => {
      return data.getAddRequest();
    });
    request.keywords = this.keywords;
    request.problem = this.problem;
    request.images = this.images.map((img) => ({ id: img.id }));
    request.websiteImages = this.websiteImages.map((img) => ({ id: img.id }));
    request.category = this.category.id;
    request.seo = this.seo.getAddRequest();
    request.displayPic =
      this.displayPic.id === 0 ? null : { id: this.displayPic.id };
    request.faqs = this.faqs.map((faq) => faq.getAddRequest());
    request.service_locations = this.serviceLocations
      .filter((loc) => {
        return loc.city.id !== 0;
      })
      .map((location) => {
        return {
          id: location.id === 0 ? undefined : location.id,
          user_access: location.userStatus,
          technician_access: location.technicianStatus,
          city: location.city.id,
          price: location.prices
            .map((price) => {
              return {
                id: price.id === 0 ? undefined : price.id,
                cost: parseFloat("" + price.cost),
                unit: price.unit,
                type: price.type,
                commission: price.commission,
              };
            })
            .filter((price) => {
              return !(price.cost < 1);
            }),
        };
      });
    return request;
  }
}

export class UserAddressModal {
  constructor(
    id = 0,
    name = "",
    flat = "",
    street = "",
    addressline1 = "",
    landmark = "",
    city = "",
    pincode = "",
    active = true,
    alias = "",
    phoneNumber = "",
    type = "",
  ) {
    this.id = id;
    this.name = name;
    this.flat = flat;
    this.street = street;
    this.addressline1 = addressline1;
    this.landmark = landmark;
    this.city = city;
    this.pincode = pincode;
    this.active = active;
    this.alias = alias;
    this.phoneNumber = phoneNumber;
    this.type = type
  }

  getAddRequest() {
    return {
      id: this.id !== 0 ? this.id : undefined,
      alias: this.name,
      flat: this.flat,
      street: this.street,
      addressline1: this.addressline1,
      landmark: this.landmark,
      active: this.active,
      pincode: this.pincode,
      city: this.city,
      type: this.type,
      phoneNumber: this.phoneNumber
    };
  }
}

export class TechnicianCityPincodeModal {
  constructor(
    id = 0,
    city = "",
    cityId = 0,
    pincodeName = [],
    pincodeId = 0,
    pincodeNumber = [],
    active = true,
    allpincodes = []
  ) {
    this.id = id;
    this.city = city;
    this.cityId = cityId;
    this.pincodeName = pincodeName;
    this.pincodeId = pincodeId;
    this.pincodeNumber = pincodeNumber;
    this.active = active;
    this.allpincodes = allpincodes;
  }

  getAddRequest() {
    return {
      id: this.id !== 0 ? this.id : undefined,
      city: this.city,
      city_id: this.cityId,
      pincode_name: this.pincodeName.join(","),
      active: this.active,
      pincode_id: this.pincodeId,
      pincode_number: this.pincodeNumber.join(","),
    };
  }
}

export class TechnicianServiceModal {
  constructor(
    id = 0,
    service = new ServiceModal(),
    cityPincodes = [],
    active = true
  ) {
    this.cityPincodes = cityPincodes;
    this.service = service;
    this.id = id;
    this.active = active;
  }
  getAddRequest() {
    return {
      id: this.id !== 0 ? this.id : undefined,
      service: this.service.id,
      city_pincode: this.cityPincodes.map((cp) => cp.getAddRequest()),
    };
  }

  getCustomRequest() {
    return this.cityPincodes.map((cp) => {
      return {
        id: cp.id !== 0 ? cp.id : undefined,
        service: this.service.id,
        city: cp.city.id,
        pincodes: cp.pincodes.join(","),
        active: cp.active,
      };
    });
  }
}
export class TechnicianDetailsModal {
  constructor(
    id = 0,
    technician = new UserModal(),
    available = true,
    aadhaar = 0,
    services = [],
    approved = false,
    bankName = "",
    accountHolderName = "",
    bankAccountNumber = "",
    ifsc = "",
    familyContactNumber = "",
    familyContactName = "",
    familyMemberRelationship = "",
    bloodGroup = "",
    experience = 0,
    aadharCardPhoto = [],
    signature = new ImageModal(),
    deposit = false,
    training = false,
    tshirt = false,
    covidcertificate = new ImageModal()
  ) {
    this.id = id;
    this.technician = technician;
    this.available = available;
    this.aadhaar = aadhaar;
    this.services = services;
    this.approved = approved;
    this.bankAccountNumber = bankAccountNumber;
    this.ifsc = ifsc;
    this.bankName = bankName;
    this.accountHolderName = accountHolderName;
    this.familyContactNumber = familyContactNumber;
    this.familyContactName = familyContactName;
    this.familyMemberRelationship = familyMemberRelationship;
    this.bloodGroup = bloodGroup;
    this.experience = experience;
    this.aadharCardPhoto = aadharCardPhoto;
    this.deposit = deposit;
    this.training = training;
    this.tshirt = tshirt;
    this.signature = signature;
    this.covidcertificate = covidcertificate;
  }

  getAddRequest(user = new UserModal()) {
    let services = [];
    const id = this.id;
    const allServices = this.services.map((s) => {
      let s1 = s.getCustomRequest();
      s1 = s1.map((k) => {
        k.technicianuser = user.id;
        if (id !== 0) k.technician = id;
        return k;
      });
      return s1;
    });
    // console.log(allServices);
    allServices.forEach((s) => {
      services = services.concat(s);
    });
    // console.log(services);
    return {
      technician_details: {
        id: this.id !== 0 ? this.id : undefined,
        technician: user.id,
        // availaible: this.available,
        aadhar: this.aadhaar,
        approved: this.approved,
        bank_account_number: this.bankAccountNumber,
        ifsc: this.ifsc,
        bankname: this.bankName,
        accountHolderName: this.accountHolderName,
        family_contact_number: this.familyContactNumber,
        family_contact_name: this.familyContactName,
        family_member_relationship: this.familyMemberRelationship,
        bloodgroup: this.bloodGroup,
        experience: this.experience,
        deposit: this.deposit,
        training: this.training,
        tshirt: this.tshirt,
        aadharcard_photo: this.aadharCardPhoto.map((a) => ({ id: a.id })),
        signature: this.signature.id !== 0 ? this.signature.id : null,
        covidcertificate:
          this.covidcertificate.id !== 0 ? this.covidcertificate.id : null,
      },
      technician_services_map: services,
      // services: this.services.map((s) => s.getAddRequest())
    };
  }
}

export class PaymentModal {
  constructor(
    id = 0,
    uid = "",
    bookingid = 0,
    type = "CASH",
    amount = 0,
    refundId = 0,
    status = false
  ) {
    this.id = id;
    this.uid = uid;
    this.bookingid = bookingid;
    this.amount = amount;
    this.status = status;
    this.refundId = refundId;
    this.type = type;
  }
}

export class DisputeModal {
  constructor(
    id = 0,
    problem = "",
    description = "",
    active = true,
    status = "PENDING"
  ) {
    this.id = id;
    this.problem = problem;
    this.description = description;
    this.active = active;
    this.status = status;
  }
  setObj(obj) {
    if (null === obj || "object" !== typeof obj) return obj;
    var copy = new DisputeModal();
    for (var attr in copy) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    // console.log(copy);
    return copy;
  }

  getAddRequest() {
    return {
      id: this.id === 0 ? undefined : this.id,
      Problem: this.problem,
      description: this.description,
      active: this.active,
      status: this.status,
    };
  }
}

export class ReviewModal {
  constructor(
    id = 0,
    comments = "",
    rating = 0,
    images = [],
    tshirt = true,
    greetedCustomer = true,
    cleanedWorkplace = true,
    active = true
  ) {
    this.id = id;
    this.comments = comments;
    this.rating = rating;
    this.active = active;
    this.images = images;
    this.tshirt = tshirt;
    this.greetedCustomer = greetedCustomer;
    this.cleanedWorkplace = cleanedWorkplace;
  }

  setObj(obj) {
    if (null === obj || "object" !== typeof obj) return obj;
    var copy = new ReviewModal();
    for (var attr in copy) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    // console.log(copy);
    return copy;
  }

  getAddRequest() {
    return {
      id: this.id === 0 ? undefined : this.id,
      comments: this.comments,
      rating: this.rating,
      active: this.active,
      tshirt: this.tshirt,
      greeted_customer: this.greetedCustomer,
      cleaned_workplace: this.cleanedWorkplace,
      images: this.images.map((i) => ({ id: i.id })),
    };
  }
}

export class BookingModal {
  constructor(
    id = 0,
    service = new ServiceModal(),
    code = "",
    fromTime = "",
    toTime = "",
    problem = "",
    gstId = "",
    approvedStatus = "PENDING",
    payment = new PaymentModal(),
    dispute = new DisputeModal(),
    review = new ReviewModal(0),
    address = new UserAddressModal(),
    approvedBy = new UserModal(),
    createdBy = new UserModal(),
    assignedTo = new UserModal(),
    assignedBy = new UserModal(),
    booking_medium = ""
  ) {
    this.id = id;
    this.service = service;
    this.code = code;
    this.fromTime = fromTime;
    this.toTime = toTime;
    this.problem = problem;
    this.gstId = gstId;
    this.approvedStatus = approvedStatus;
    this.payment = payment;
    this.dispute = dispute;
    this.review = review;
    this.address = address;
    this.approvedBy = approvedBy;
    this.createdBy = createdBy;
    this.assignedTo = assignedTo;
    this.assignedBy = assignedBy;
    this.booking_medium = booking_medium;
  }

  setObj(obj) {
    if (null === obj || "object" !== typeof obj) return obj;
    var copy = new BookingModal();
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
    }
    //console.log(copy);
    return copy;
  }

  getAddRequest() {
    return {
      id: this.id === 0 ? undefined : this.id,
      serviceid: this.service.id,
      fromtime: this.fromTime,
      totime: this.toTime,
      problem: this.problem,
      bookingId: this.code,
      gstId: this.gstId,
      approval_status: this.approvedStatus,
      address: this.address.getAddRequest(),
      dispute: this.dispute.id !== 0 ? this.dispute.getAddRequest() : null,
      review: this.review.id !== 0 ? this.review.getAddRequest() : null,
      createdby: this.createdBy.id,
      booking_medium: this.booking_medium
    };
  }
}

export class BookingStatusModal {
  constructor(id = 0, name = "", description = "") {
    this.id = id;
    this.name = name;
    this.description = description;
  }
}

export class BookingStatusMapModal {
  constructor(
    id = 0,
    booking = new BookingModal(),
    bookingStatus = new BookingStatusModal(),
    otp = 0,
    latitude = 0,
    longitude = 0
  ) {
    this.id = id;
    this.booking = booking;
    this.bookingStatus = bookingStatus;
    this.otp = otp;
    this.latitude = latitude;
    this.longitude = longitude;
  }
  getAddRequest() {
    return {
      id: this.id === 0 ? undefined : this.id,
      bookingid: this.booking.id === 0 ? undefined : this.booking.id,
      bookingstatusid: this.bookingStatus.id,
      // latitude: this.latitude,
      // longitude: this.longitude,
    };
  }
}
export class InventoryItemModal {
  constructor(
    item_name = "",
    item_description = "",
    item_type = "",
    active = true,
    item_unit = 0,
    item_price = 0,
    service = new ServiceModal(),
    gst_enabled = true,
    city = new LocationModal(),
    price_mask = "",
    commission = 0
  ) {
    this.item_name = item_name;
    this.item_description = item_description;
    this.item_type = item_type;
    this.active = active;
    this.item_unit = item_unit;
    this.item_price = item_price;
    this.service = service;
    this.gst_enabled = gst_enabled;
    this.city = city;
    this.price_mask = price_mask;
    this.commission = commission;
  }
}

export class TechnicianCityPincodeCustomModal {
  constructor(
    id = 0,
    city = new LocationModal(),
    pincodes = [],
    active = true,
    allpincodes = []
  ) {
    this.id = id;
    this.city = city;
    this.pincodes = pincodes;
    this.active = active;
    this.allpincodes = allpincodes;
  }

  getAddRequest() {
    return {};
  }
}

export class SiteSettingsModal {
  constructor(
    id = 0,
    gstId = "",
    gstpercentage = 0,
    googleanalyticsid = "",
    fburl = "",
    linkedinurl = "",
    twitterurl = "",
    instaurl = "",
    approvalEnabled = false,
    faqs = [],
    seo = new SEOModal(),
    mainSlider = [],
    localAds = [],
    collaborations = [],
    videos = [],
    contactusUrl = "",
    guidelinesUrl = "",
    termsUrl = "",
    referUrl = "",
    registerPartnerUrl = ""
  ) {
    this.id = id;
    this.gstId = gstId;
    this.gstpercentage = gstpercentage;
    this.googleanalyticsid = googleanalyticsid;
    this.fburl = fburl;
    this.linkedinurl = linkedinurl;
    this.twitterurl = twitterurl;
    this.instaurl = instaurl;
    this.approvalEnabled = approvalEnabled;
    this.contactusUrl = contactusUrl;
    this.guidelinesUrl = guidelinesUrl;
    this.termsUrl = termsUrl;
    this.referUrl = referUrl;
    this.registerPartnerUrl = registerPartnerUrl;
    this.faqs = faqs;
    this.seo = seo;
    this.videos = videos;
    this.mainSlider = mainSlider;
    this.localAds = localAds;
    this.collaborations = collaborations;
  }
}

export class ServiceDetailsModal {
  constructor(
    id = 0,
    title = "",
    description = "",
    displayPic = new ImageModal()
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.displayPic = displayPic;
  }

  getAddRequest(imageId = 0) {
    return {
      id: this.id === 0 ? undefined : this.id,
      Title: this.title,
      description: this.description,
      displayPic:
        imageId === 0
          ? this.displayPic.id === 0
            ? undefined
            : this.displayPic.id
          : imageId,
    };
  }
}
