import React,{ Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../constants/constants";
import { getConvertedUsers } from "../../../constants/conversions/users";
import { doGetAllUsers, doPutUpdateUser } from "../../../services/apiServices";
import swal from 'sweetalert';
import { connect } from "react-redux";
import { changePreloaderStatus } from '../../../store/layout/actions';
class Users extends Component{

    state={
        users:[],
        breadcrumbItems:[{
                         active: true,
                         route: '#',
                         title: 'Users',
                     }]
    }

    componentDidMount(){
        this.getAllUsers();
    }

    getAllUsers=()=>{
        this.props.dispatch(changePreloaderStatus(true));
        doGetAllUsers().then((res)=>{
            this.props.dispatch(changePreloaderStatus(false));
            this.setState({
                users:getConvertedUsers(getValidatedData(res.data,'array'))
            })
        },(err)=>{
            this.props.dispatch(changePreloaderStatus(false));
            this.setState({
                users:[]
            })
        })
    }

    doDeleteUser=(user)=>{
        swal({
            title: "Are you sure to delete?",
            text: "You will not be able to recover \""+user.getFullName()+"\" Data",
            type: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                this.props.dispatch(changePreloaderStatus(true));
                doPutUpdateUser(user.id,JSON.stringify({blocked:true})).then((res) => {
                    swal("Success", "Succesfully Deleted", "success");
                    this.getAllUsers();
                }, (err) => {
                        this.props.dispatch(changePreloaderStatus(false));
                        swal("Error", "", "error");
                })
            }
        });
    }

    render(){
        return(<React.Fragment>
                <div className="page-content">
                    <Container fluid>
                    <Breadcrumbs title={('Users')} breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col md="12" className="mb-3 mt-3">
                                <Link to="/users/add" className="float-right"><Button className="btn-success">
                                    <i className="fa fa-plus"></i></Button></Link>
                            </Col>
                            <Col md="12">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>S.no</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Mobile</th>
                                            <th>Role</th>   
                                            <th>Actions</th>   
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.users.map((user,index)=>{
                                                return(<React.Fragment key={user.id}>
                                                    <tr>
                                                        <td>{""+(index+1)}</td>
                                                        <td>{user.getFullName()}</td>
                                                        <td>{user.userName}</td>
                                                        <td>{user.email}</td>
                                                        <td>{user.mobile}</td>
                                                        <td>{user.role.name}</td>   
                                                        <td><Link to={"/users/"+user.id}><i className="fa fa-edit text-warning mr-2" title="Edit"></i></Link> 
                                                        <i onClick={()=>this.doDeleteUser(user)} className="cursor-pointer fa fa-trash text-danger" title="Delete"></i></td>   
                                                    </tr>
                                                </React.Fragment>)
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Container>
                    </div>
                </React.Fragment>)
    }

}

export default connect()(Users);