import React, { Component } from "react";
import {
  Button,
  ButtonToggle,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  BookingModal,
  BookingStatusMapModal,
  BookingStatusModal,
  ServiceModal,
} from "../../../classes/classes";
import {
  doGetAllLocations,
  doGetAllServices,
  doPostOffers,
} from "../../../services/apiServices";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import {
  getValidatedData,
  isNumeric,
  numbersregexp,
} from "../../../constants/constants";
import { getConvertedServices } from "../../../constants/conversions/services";

import moment from "moment";
import { changePreloaderStatus } from "../../../store/layout/actions";
import swal from "sweetalert";
import ReactSwitch from "react-switch";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import TermsCard from "./TermsCard";
const animatedComponents = makeAnimated();
export default class AddOffers extends Component {
  state = {
    booking: new BookingModal(),
    bookingDate: "",
    bookingSlot: "",
    bookingStatusMap: new BookingStatusMapModal(),
    bookingStatus: null,
    breadcrumbItems: [],
    cities: [],
    pincodes: [],
    users: [],
    selectedServices: [],
    services: [],
    statuses: [],
    percentage: 0,
    from_date: "",
    to_date: "",
    min: "",
    max:"",
    new_user: false,
    offer_name: "",
    terms: [],
  };

  componentDidMount() {
    this.getAllCities();
    this.getAllServices();
    // //console.log(this.state.booking.address);
  }
  getAllCities = (city = "") => {
    doGetAllLocations().then(
      (res) => {
        //console.log("S", res);
        const cities = getConvertedLocations(
          getValidatedData(res.data, "array")
        ).filter((c) => c.active);
        let pincodes = [];
        cities.every((c) => {
          if (c.name === city) {
            pincodes = c.pincodes;
            return false;
          }
          return true;
        });
        this.setState({
          cities: cities,
          pincodes: pincodes,
        });
      },
      (err) => {
        this.setState({
          cities: [],
        });
      }
    );
  };
  getAllServices = () => {
    doGetAllServices().then(
      (res) => {
        //console.log(res.data);
        this.setState({
          services: getConvertedServices(getValidatedData(res.data, "array")),
        });
      },
      (err) => {
        this.setState({
          services: [],
        });
      }
    );
  };
  getServicesBasedOnCity = (services = [], cityName = "") => {
    return services.filter((s) => {
      const selectedCities = s.serviceLocations
        .filter((sl) => {
          return sl.city.name.toLowerCase() === cityName.toLowerCase();
        })
        .filter((s) => s.city.active);
      return selectedCities.length > 0;
    });
  };
  onHandleChange = (e) => {
    const { name, value } = e.target;
    //console.log(value);
    switch (name) {
      case "bookingFor":
        this.setState((prev) => {
          let st = prev;
          const users = st.users.filter((s) => s.id === value);
          if (users.length > 0) {
            st.booking.createdBy = users[0];
          }
          return st;
        });
        break;
      case "bookingSlot":
        this.setState({
          bookingSlot: value,
        });
        break;
      case "bookingDate":
        this.setState((prev) => {
          let st = prev;
          let dateCurrentStr = moment().format("YYYY-MM-DD");
          let dateValueStr = moment(value).format("YYYY-MM-DD");
          if (moment(dateValueStr).isSameOrAfter(moment(dateCurrentStr))) {
            st.bookingDate = value;
          }
          return st;
        });
        break;
      case "bookingStatus":
        this.setState((prev) => {
          let st = prev;
          let sStatus = st.statuses.filter((ss) => {
            return ss.id === value;
          });
          st.bookingStatusMap.bookingStatus =
            sStatus.length > 0 ? sStatus[0] : new BookingStatusModal();
          return st;
        });
        break;
      case "latitude":
        this.setState((prev) => {
          let st = prev;
          if (value === "") {
            st.bookingStatusMap.latitude = value;
          } else if (isNumeric(value)) {
            st.bookingStatusMap.latitude = parseFloat(value);
          }
          return st;
        });
        break;
      case "longitude":
        this.setState((prev) => {
          let st = prev;
          if (value === "") {
            st.bookingStatusMap.longitude = value;
          } else if (isNumeric(value)) {
            st.bookingStatusMap.longitude = parseFloat(value);
          }
          return st;
        });
        break;
      case "name":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.name = value;
          return st;
        });
        break;
      case "flat":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.flat = value;
          return st;
        });
        break;
      case "street":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.street = value;
          return st;
        });
        break;
      case "addressline1":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.addressline1 = value;
          return st;
        });
        break;
      case "landmark":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.landmark = value;
          return st;
        });
        break;
      case "city":
        this.setState((prev) => {
          let st = prev;
          let city_name = this.state.cities.find((c) => c.id === value);
          st.booking.address.city = city_name.name;
          st.booking.address.id = value;
          st.booking.address.pincode = "";
          st.pincodes = [];
          // //console.log("s", this.state.cities);
          st.selectedServices = this.getServicesBasedOnCity(
            st.services,
            city_name.name
          );
          st.booking.service = new ServiceModal();

          st.cities.every((c) => {
            if (c.name === city_name.name) {
              st.pincodes = c.pincodes;
              return false;
            }
            return true;
          });
          return st;
        });
        break;
      case "pincode":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value) && value.length <= 6)
            st.booking.address.pincode = value;
          return st;
        });
        break;
      case "active":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.active = value;
          return st;
        });
        break;
      case "service":
        this.setState((prev) => {
          let st = prev;
          let s = st.selectedServices.filter((s1) => {
            return s1.id === value;
          });
          st.booking.service = s.length > 0 ? s[0] : new ServiceModal();
          return st;
        });
        break;
      case "fromTime":
        this.setState((prev) => {
          let st = prev;
          st.booking.fromTime = value;
          return st;
        });
        break;
      case "toTime":
        this.setState((prev) => {
          let st = prev;
          st.booking.toTime = value;
          return st;
        });
        break;
      case "problem":
        this.setState((prev) => {
          let st = prev;
          st.booking.problem = value;
          return st;
        });
        break;
      case "gstId":
        this.setState((prev) => {
          let st = prev;
          st.booking.gstId = value;
          return st;
        });
        break;
      case "approvedStatus":
        this.setState((prev) => {
          let st = prev;
          st.booking.approvedStatus = value;
          return st;
        });
        break;
      case "bookingMedium":
        this.setState((prev) => {
          //console.log("Booking Medium State :- ", this.state.booking);
          let st = prev;
          st.booking.booking_medium = value;
          return st;
        });
        break;

      default:
        break;
    }
  };
  onChangeValue = (event) => {
    this.setState({ new_user: event.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      new_user: this.state.new_user,
      percentage: this.state.percentage,
      fromDate: moment.utc(this.state.from_date).format(),
      toDate: moment.utc(this.state.to_date).format(),
      name: this.state.offer_name,
      city: this.state.booking.address.id,
      service: this.state.booking.service.id,
      terms: this.state.terms,
      min: this.state.min,
      max: this.state.max
    };

    doPostOffers(body).then(
      (res) => {
        if (res.status === 200) {
          this.setState({
            new_user: "",
            percentage: "",
            from_date: "",
            to_date: "",
            offer_name: "",
            city: "",
            service: "",
          });
        }
        // this.props.dispatch(changePreloaderStatus(false));
        swal("Success", "Succesfully Added", "success");
      },
      (err) => {
        swal("Error", "", "error");
        // this.props.dispatch(changePreloaderStatus(false));
      }
    );
    //console.log(body);
  };
  handleTermsChange = (i1, term) => {
    this.setState((prev) => {
      let st = prev;
      st.terms[i1] = term;
      return st;
    });
  };
  deleteTerms = (i) => {
    this.setState((prev) => {
      let st = prev;

      st.terms.splice(i, 1);
      return st;
    });
  };
  addTerms = () => {
    this.setState((prev) => {
      let st = prev;
      st.terms.push({ title: "", description: "", active: false, type: "" });
      return st;
    });
  };
  render() {
    return (
      <div className="page-content">
        <div style={{ marginBottom: "20px" }}>
          <h4 className="mb-0 font-size-18">Create an offer</h4>
        </div>
        <div>
          <Form className="row userForm">
            <Col md={6}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="problem"
                  type="text"
                  className="form-control"
                  value={this.state.offer_name}
                  onChange={(e) => {
                    this.setState({ offer_name: e.target.value });
                  }}
                  placeholder="Offer Name"
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Percentage</Label>
                <Input
                  name="code"
                  type="number"
                  className="form-control"
                  value={this.state.percentage}
                  onChange={(e) => {
                    this.setState({ percentage: e.target.value });
                  }}
                  placeholder=""
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>FromDate</Label>
                <InputGroup>
                  <DatePicker
                    selected={this.state.from_date}
                    className="form-control"
                    showTimeSelect
                    dateFormat="yyyy/MM/dd hh:mm:ss a"
                    onChange={(e) => {
                      this.setState({ from_date: e });
                      //console.log(moment.utc(e).format());
                    }}
                    showYearDropdown
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>ToDate</Label>
                <InputGroup>
                  <DatePicker
                    showTimeSelect
                    dateFormat="yyyy/MM/dd hh:mm:ss a"
                    selected={this.state.to_date}
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ to_date: e });
                    }}
                    showYearDropdown
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Minimum Billing Amount</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="problem"
                  type="text"
                  className="form-control"
                  value={this.state.min}
                  onChange={(e) => {
                    this.setState({ min: e.target.value });
                  }}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Maximum Discount Amount</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="problem"
                  type="text"
                  className="form-control"
                  value={this.state.max}
                  onChange={(e) => {
                    this.setState({ max: e.target.value });
                  }}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>City</Label>
                <Select
                  name="city"
                  value={{
                    label: this.state.booking.address.city,
                    value: this.state.booking.address.id,
                  }}
                  isMulti={false}
                  onChange={(e) =>
                    this.onHandleChange({
                      target: {
                        name: "city",
                        value: e.value,
                      },
                    })
                  }
                  options={this.state.cities.map((c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  classNamePrefix="select2-selection"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Service</Label>
                <Select
                  name="approvedStatus"
                  value={{
                    label: this.state.booking.service.name,
                    value: this.state.booking.service.id,
                  }}
                  isMulti={false}
                  onChange={(e) =>
                    this.onHandleChange({
                      target: {
                        name: "service",
                        value: e.value,
                      },
                    })
                  }
                  options={this.state.selectedServices.map((s) => ({
                    label: s.name,
                    value: s.id,
                  }))}
                  classNamePrefix="select2-selection"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Label>New User</Label>

              <FormGroup check>
                <ReactSwitch
                  name="active"
                  uncheckedIcon={<Offsymbol />}
                  className="mr-1"
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() =>
                    this.setState({ new_user: !this.state.new_user })
                  }
                  checked={this.state.new_user}
                  // checked={address.active}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <div className="bg-light px-2 py-3">
                <h5>
                  Terms{" "}
                  <i
                    className="fa fa-plus cursor-pointer text-success float-right mr-3"
                    onClick={() => this.addTerms()}
                  ></i>
                </h5>
                {this.state.terms.map((term, i1) => {
                  return (
                    <TermsCard
                      term={term}
                      i1={i1}
                      handleTermsChange={this.handleTermsChange}
                      deleteTerms={() => this.deleteTerms(i1)}
                    />
                  );
                })}
              </div>
            </Col>
            <Col md={12}>
              <Button
                color="primary"
                type=""
                onClick={(e) => this.handleSubmit(e)}
              >
                Save
              </Button>
            </Col>
            <Col></Col>
          </Form>
        </div>
      </div>
    );
  }
}
