import { SEOModal, SiteSettingsModal } from "../../classes/classes";
import { getValidatedData } from "../constants";
import { getConvertedFaqs } from "./faqs";
import { getConvertedImages } from "./images";
import { getConvertedSEOS } from "./seo";

export function getConvertedSiteSettings(settings=[]){

    return settings.filter((k)=>{
      return Object.keys(k).length>0
    }).map((setting)=>{

        const id=getValidatedData(setting.id,'number');
        const gstId=getValidatedData(setting.gstId);
        const gstpercentage=getValidatedData(setting.gstpercentage,'number');
        const googleanalyticsid=getValidatedData(setting.googleanalyticsid);
        const fburl=getValidatedData(setting.fburl);
        const linkedinurl=getValidatedData(setting.linkedinurl);
        const twitterurl=getValidatedData(setting.twitterurl);
        const instaurl=getValidatedData(setting.instaurl);
        const contactusUrl=getValidatedData(setting.contactusUrl);
        const guidelinesUrl=getValidatedData(setting.guidelinesUrl);
        const approvalEnabled=getValidatedData(setting.approvalEnabled,'boolean');
        const termsUrl=getValidatedData(setting.termsUrl);
        const referUrl=getValidatedData(setting.referUrl);
        const registerPartnerUrl=getValidatedData(setting.registerPartnerUrl);


        const seos=(getValidatedData(setting.seo,'object',true))?getConvertedSEOS([getValidatedData(setting.seo,'object')]):[]
        const seo = (seos.length>0)?seos[0]:new SEOModal()


        const faqs=getConvertedFaqs(getValidatedData(setting.faqs,'array'))
        const mainSlider=getConvertedImages(getValidatedData(setting.mainSlider,'array'))
        const videos=getConvertedImages(getValidatedData(setting.videos,'array'))
        const localAds=getConvertedImages(getValidatedData(setting.localAds,'array'))
        const collaborations=getConvertedImages(getValidatedData(setting.collaborations,'array'))

        return new SiteSettingsModal(id,gstId,gstpercentage,googleanalyticsid,fburl,linkedinurl,twitterurl,instaurl,approvalEnabled,faqs,seo,mainSlider,localAds,collaborations,videos,contactusUrl,guidelinesUrl,termsUrl,referUrl,registerPartnerUrl);
    })

}