import {
  GET_BOOKINGSTATUSMAP_ALL_URL,
  GET_ALL_REVIEWS_URL,
  GET_BOOKINGSTATUS_ALL_URL,
  POST_TECHINICIAN_DETAILS_TECH_URL,
  GET_ELIGIBLE_TECHNICIANS_URL,
  GET_BOOKINGS_ALL_URL,
  GET_CATEGORIES_ALL_URL,
  GET_FAQS_ALL_URL,
  GET_LOCATIONS_ALL_URL,
  GET_SEOS_ALL_URL,
  GET_SERVICES_ALL_URL,
  GET_TECHINICIAN_DETAILS_ALL_URL,
  GET_USERS_ALL_URL,
  GET_USER_ROLES_URL,
  INVENTORY_ADD_ITEM,
  POST_CREATE_USER_URL,
  POST_UPLOAD_FILES_URL,
  PUT_ASSIGN_BOOKING_URL,
  GET_TECHINICIAN_DETAILS_TECH_URL,
  PUT_TECHINICIAN_DETAILS_TECH_URL,
  POST_BOOKING_URL,
  GET_ALL_DISPUTES_URL,
  PUT_CANCEL_BOOKING_URL,
  PUT_RESCHEDULE_BOOKING_URL,
  PUT_REVISIT_BOOKING_URL,
  GET_ALL_SITESETTINGS_URL,
  PUT_BOOKING_PENALTY_URL,
  GET_BOOKING_PENALTY_URL,
  GET_OFFERS_URL,
  GET_INCOMPLETE_TRANSACTIONS_URL,
  PUT_COMPLETE_PAYOUT,
  GET_WALLETS_URL,
  GET_TRANSACTIONS_URL,
  GET_SLIDERS_URL,
  GET_OFFER_URL,
  GET_PAYMENTS_URL,
  GET_BILLING_DETAILS_URL,
  POST_NEW_TECHNICIAN_URL,
  POST_NEW_USER_URL,
  GET_CONTACTS_URL,
} from "../constants/apiserviceurls";
import { getAuthToken } from "../constants/constants";
import { RestAxiosService } from "./RestApiService";

export function doGetAllUsers(id = "", query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_USERS_ALL_URL +
      (id !== "" ? "/" + id : "") +
      (query === "" ? "" : "?" + query),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutUpdateUser(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_USERS_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doDeleteUser(id) {
  console.log("id",id);
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_USERS_ALL_URL + `/${id}`,
      "DELETE",
      // body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllUserRoles() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_USER_ROLES_URL, "GET", false, headers).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doPostCreateCustomer(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(POST_NEW_USER_URL, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doPostCreateAdmin(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(POST_CREATE_USER_URL, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doPostCreateUser(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(POST_CREATE_USER_URL, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doPostCreateTechnician(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      POST_NEW_TECHNICIAN_URL,
      "POST",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

/* Inventory Api Services */
export function doPostAddInventoryItem(body) {
  let headers = {
    "Content-Type": "application/json",
    ...getAuthToken(),
  };
  return new Promise((resolve, reject) => {
    return RestAxiosService(INVENTORY_ADD_ITEM, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetInventoryItems(id = "", query = "") {
  let headers = {
    "Content-Type": "application/json",
    ...getAuthToken(),
  };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      INVENTORY_ADD_ITEM +
      (id !== "" ? "/" + id : "") +
      "?_sort=item_name" +
      (query !== "" ? "&" + query : ""),
      "GET",
      {},
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutDeleteInventoryItem(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      INVENTORY_ADD_ITEM + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutUpdateInventoryItem(id, body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      INVENTORY_ADD_ITEM + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/*  Location Api Services */

export function doGetAllLocations(id = "", query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_LOCATIONS_ALL_URL +
      (id !== "" ? "/" + id : "") +
      "?_sort=name" +
      (query !== "" ? "&" + query : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutUpdateLocation(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_LOCATIONS_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPostCreateLocation(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_LOCATIONS_ALL_URL, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

/*  Category Api Services */

export function doGetAllCategories(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_CATEGORIES_ALL_URL + (id !== "" ? "/" + id : "") + "?_sort=id",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutUpdateCategory(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_CATEGORIES_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Services Api Services */

export function doGetAllServices(id = "", query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SERVICES_ALL_URL +
      (id !== "" ? "/" + id : "") +
      "?_sort=id" +
      (query !== "" ? "&" + query : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPostAddService(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_SERVICES_ALL_URL, "POST", body, headers).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutUpdateService(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SERVICES_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Faqs Api Services */

export function doGetAllFaqs(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_FAQS_ALL_URL + (id !== "" ? "/" + id : "") + "?_sort=id",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* SEO's Api Services */

export function doGetAllSeos(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SEOS_ALL_URL + (id !== "" ? "/" + id : "") + "?_sort=id",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/*  Upload Files Service*/

export function doPostUploadFiles(files = []) {
  let headers = { ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return Promise.all(
      files.map((file) =>
        RestAxiosService(POST_UPLOAD_FILES_URL, "POST", file, headers)
      )
    )
      .then((responses) => Promise.all(responses.map((res) => res)))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/* TECHINICIAN DETAILS Api Services */

export function doGetTechincianService(id = "", technicianId = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TECHINICIAN_DETAILS_ALL_URL +
      (id !== "" ? "/" + id : "") +
      "?_sort=id:DESC&technician_eq=" +
      technicianId,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPostTechincianService(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TECHINICIAN_DETAILS_ALL_URL,
      "POST",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutTechincianService(id = "", body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TECHINICIAN_DETAILS_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Bookings Api Services */

export function doGetBookings(id = "", query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGS_ALL_URL +
      (id !== "" ? "/" + id : "") +
      "?_sort=id:DESC" +
      (query !== "" ? "&" + query : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// TODO: Get Inventory item Data
export function doGetInventoryItem(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      INVENTORY_ADD_ITEM + (id !== "" ? "/" + id : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPostAllAddInventoryItems(body) {
  let headers = {
    "Content-Type": "application/json",
    ...getAuthToken(),
  };
  return new Promise((resolve, reject) => {
    return Promise.all(
      body.map((b) => RestAxiosService(INVENTORY_ADD_ITEM, "POST", b, headers))
    )
      .then((responses) => Promise.all(responses.map((res) => res)))
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function doPostBookings(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      POST_BOOKING_URL,
      "POST",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doUpdateBookings(id = "", body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGS_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

// Getting Booking Status Map at Booking Overview
export function doGetBookingStatusMapsById(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGSTATUSMAP_ALL_URL + "?_sort=id:DESC&bookingid=" + id,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Booking Status Map Api Services */

export function doGetBookingStatusMaps(id = "", bookingid = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGSTATUSMAP_ALL_URL +
      (id !== "" ? "/" + id : "") +
      "?_sort=id:DESC&bookingid.id=" +
      bookingid,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doUpdateBookingStatusMaps(id = "", body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGSTATUSMAP_ALL_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Booking Status Api Services */

export function doGetBookingStatus(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGSTATUS_ALL_URL + (id !== "" ? "/" + id : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doUpdateAssignBookings(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_ASSIGN_BOOKING_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Booking Status Api Services */

export function doGetEligibleTechnicians(bookingid = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ELIGIBLE_TECHNICIANS_URL + (bookingid !== "" ? "/" + bookingid : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Technician Details by Technician Api Services */

export function doGetTechnicianDetailByTech(technicianId = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TECHINICIAN_DETAILS_TECH_URL +
      (technicianId !== "" ? "/" + technicianId : ""),
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Technician Details Save by Technician Api Services */

export function doPostTechincalDetails(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      POST_TECHINICIAN_DETAILS_TECH_URL,
      "POST",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Technician Details Update by Technician Api Services */

export function doUpdateTechincalDetails(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_TECHINICIAN_DETAILS_TECH_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Dispute Details Update Api Services */

export function doPostDisputeDetails(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ALL_DISPUTES_URL,
      "POST",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Dispute Details Update Api Services */

export function doUpdateDisputeDetails(id = "", body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ALL_DISPUTES_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Review Details Update Api Services */

export function doPostReviewDetails(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ALL_REVIEWS_URL,
      "POST",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Cancel booking Api Services */

export function doCancelBooking(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_CANCEL_BOOKING_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* Revisit booking Api Services */

export function doRevisitBooking(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_REVISIT_BOOKING_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* ReSchedule booking Api Services */

export function doRescheduleBooking(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_RESCHEDULE_BOOKING_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* GET SITE SETTINGS Api Services */

export function doGetSettingsDetails(id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ALL_SITESETTINGS_URL + (id !== "" ? "/" + id : ""),
      "GET",
      null,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

/* UPDATE SITE SETTINGS Api Services */

export function doPutSettingsDetails(id = "", body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_ALL_SITESETTINGS_URL + (id !== "" ? "/" + id : ""),
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutBookingPenalty(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  //console.log(body);
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      PUT_BOOKING_PENALTY_URL,
      "PUT",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetBookingPenalty(bookingId) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  //console.log(bookingId);
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKING_PENALTY_URL + `=HV Penalty(${bookingId})`,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllOffers(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_OFFERS_URL + "?" + query,
      // GET_OFFERS_URL + "/count?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetAllOffersCount(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_OFFERS_URL + "/count",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doPostOffers(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_OFFERS_URL,
      "POST",
      JSON.stringify(body),
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetIncompleteTransaction() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_INCOMPLETE_TRANSACTIONS_URL,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        function groupBy(xs, f) {
          return xs.reduce(
            (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
            {}
          );
        }
        const result = groupBy(res.data, (c) => c.wallet.id);
        //console.log(res, result);
        resolve(result);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetIncompleteTransactionCount() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TRANSACTIONS_URL + "/count?isCompleted=false",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        // function groupBy(xs, f) {
        //   return xs.reduce(
        //     (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
        //     {}
        //   );
        // }
        // const result = groupBy(res.data, (c) => c.wallet.id);
        // //console.log(res, result);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPutCompletePayout(body = []) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  //console.log(body);
  const obj = {
    transactions: body,
  };
  return new Promise((resolve, reject) => {
    return RestAxiosService(PUT_COMPLETE_PAYOUT, "PUT", obj, headers).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doDeleteOffer(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  //console.log(body);

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_OFFERS_URL + `/${body}`,
      "DELETE",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doPutOffer(body, id) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  //console.log(body);

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_OFFERS_URL + `/${id}`,
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetOfferById(body) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_OFFERS_URL + `/${body}`,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllWallets(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_WALLETS_URL + "?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function walletSummary(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_WALLETS_URL + "/filterAndSum?type=" + query,
      "GET",
      // false,
      // headers
    ).then(
      (res) => {
        console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllWalletCount(query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_WALLETS_URL + "/count?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetWalletById(id, query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TRANSACTIONS_URL + `?wallet=${id}&${query}`,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetAllWalletByIdCount(id) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_TRANSACTIONS_URL + `/count?wallet=${id}`,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doPostSlider(body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_SLIDERS_URL, "POST", body, headers).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doPutSlider(id, body = {}) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SLIDERS_URL + `/${id}`,
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetSliderById(id) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SLIDERS_URL + `/${id}`,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllSliders(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SLIDERS_URL + "?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllSlidersCount(query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_SLIDERS_URL + "/count?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doDeleteSlider(sliderBody) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  let body = {
    title: sliderBody.title,
    type: sliderBody.type,
    order: sliderBody.order,
    image: sliderBody.image.id,
    iframeUrl: sliderBody.iframeUrl,
    description: sliderBody.description,
    active: !sliderBody.active,
  };
  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_SLIDERS_URL + `/${sliderBody.id}`,
      "PUT",
      body,
      headers
    ).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllPayments() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_PAYMENTS_URL, "GET", false, headers).then(
      (res) => {
        // //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetPaymentsById(id) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_PAYMENTS_URL + "?_where[0][booking.bookingId]=" + id,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        // //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetBillingDetails(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BILLING_DETAILS_URL + "?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        // //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetTechnicianDetailsForExcel(query, id = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_TECHINICIAN_DETAILS_ALL_URL + ((id !== "") ? "/" + id : "") + "?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetAllContacts(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_CONTACTS_URL + "?" + query,
      // GET_OFFERS_URL + "/count?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetAllContactsCount(query) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_CONTACTS_URL + "/count",
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function doGetCustomerBookingCount(query = "") {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      // GET_OFFERS_URL + "?" + query,
      GET_BOOKINGS_ALL_URL + "/count?" + query,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        //console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
export function doGetCustomerBooking(id) {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };

  return new Promise((resolve, reject) => {
    return RestAxiosService(
      GET_BOOKINGS_ALL_URL +
      "?_sort=created_at&_where[0][createdby.username]=" +
      id,
      "GET",
      false,
      headers
    ).then(
      (res) => {
        console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err);
      }
    );
  });
}
