import React, { Component } from "react";
import { Card, CardBody, CardTitle, Progress } from "reactstrap";

class TopCities extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let total = 0;
    for (let i = 0; i < this.props.topSellingCities.length; i++) {
      total += this.props.citySellingFrequency[this.props.topSellingCities[i]];
    }

    return (
      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Top Cities Selling Product</CardTitle>
            <div className="text-center">
              <div className="mb-4">
                <i className="bx bx-map-pin text-primary display-4"></i>
              </div>
              <h3>
                {this.props.topSellingCities.length > 0 &&
                  this.props.citySellingFrequency[
                    this.props.topSellingCities[0]
                  ]}
              </h3>
              <p>{this.props.topSellingCities[0]}</p>
            </div>

            <div className="table-responsive mt-4">
              <table className="table table-centered table-nowrap mb-2">
                <tbody>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <p className="mb-0">{this.props.topSellingCities[0]}</p>
                    </td>
                    <td style={{ width: "25%" }}>
                      <h5 className="mb-0">
                        {this.props.topSellingCities.length > 0 &&
                          this.props.citySellingFrequency[
                            this.props.topSellingCities[0]
                          ]}
                      </h5>
                    </td>
                    <td>
                      <Progress
                        value={
                          this.props.topSellingCities.length > 0 &&
                          (this.props.citySellingFrequency[
                            this.props.topSellingCities[0]
                          ] /
                            total) *
                            100
                        }
                        color="primary"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="mb-0">{this.props.topSellingCities[1]}</p>
                    </td>
                    <td>
                      <h5 className="mb-0">
                        {this.props.topSellingCities.length > 1 &&
                          this.props.citySellingFrequency[
                            this.props.topSellingCities[1]
                          ]}
                      </h5>
                    </td>
                    <td>
                      <Progress
                        value={
                          ((this.props.topSellingCities.length > 1 &&
                            this.props.citySellingFrequency[
                              this.props.topSellingCities[1]
                            ]) /
                            total) *
                          100
                        }
                        color="success"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="mb-0">{this.props.topSellingCities[2]}</p>
                    </td>
                    <td>
                      <h5 className="mb-0">
                        {this.props.topSellingCities.length > 2 &&
                          this.props.citySellingFrequency[
                            this.props.topSellingCities[2]
                          ]}
                      </h5>
                    </td>
                    <td>
                      <Progress
                        value={
                          ((this.props.topSellingCities.length > 2 &&
                            this.props.citySellingFrequency[
                              this.props.topSellingCities[2]
                            ]) /
                            total) *
                          100
                        }
                        color="warning"
                        className="bg-transparent"
                        size="sm"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default TopCities;
