export const adminFilterConfig={
    filters:[
        {
            label:"First Name",
            placeholder:"Search First Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"firstname",
        },
        {
            label:"Last Name",
            placeholder:"Search Last Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"lastname",
        },
        {
            label:"Username",
            placeholder:"Search Username",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"username",
        },
        {
            label:"Email",
            placeholder:"Search Email",
            filterPostfix:"_contains",
            value:"",
            type:"email",
            key:"email",
        },
        {
            label:"Mobile",
            placeholder:"Search Mobile",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"phonenumber",
        },
        {
            label:"Role",
            placeholder:"Search Role",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"role.name",
        }
    ]
}