import React, { Component } from "react";

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";

import ApexRadial from "./ApexRadial";
import moment from "moment";

class WalletAmount extends Component {
  constructor(props) {
  
    super(props);
    this.state = {
      currentMonthrevenue: 0,
    };
  }

  render() {
    const { technicianWallet, customerWallet } = this.props;

    return (
      <React.Fragment>
        {" "}
        <Card>
          <CardBody style={{padding:"1rem"}}>
            <CardTitle className="mb-0">Total Wallet Amount </CardTitle>
            <Row>
              <Col sm="6">
                <p className="text-muted">Customer</p>
                <h3>
                  ₹{customerWallet.toFixed(2)}
                </h3>

                {/* <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    View More <i className="mdi mdi-arrow-right ml-1"></i>
                  </Link>
                </div> */}
              </Col>
              <Col sm="6">
              <p className="text-muted">Technician</p>
                <h3>
                  ₹{technicianWallet.toFixed(2)}
                </h3>
                {/* <div className="mt-4 mt-sm-0">
                  <ApexRadial />
                </div> */}
              </Col>
            </Row>
            {/* <p className="text-muted mt-4 mb-3">
              We craft digital, graphic and dimensional thinking.
            </p> */}
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default WalletAmount;
