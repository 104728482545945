import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Table,
} from "reactstrap";
// Table Css
//import "../tables.css";
import { BookingModal, UserModal, ReviewModal } from "../../../classes/classes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { convertToFormData, getExt, getImageUrl, getValidatedData } from "../../../constants/constants";
import { getConvertedBookings } from "../../../constants/conversions/bookings";
import { addMomentMinutes } from "../../../constants/functions";
import { getConvertedUsers } from "../../../constants/conversions/users";
import {
  doGetAllUsers,
  doGetBookings,
  doGetEligibleTechnicians,
  doPostReviewDetails,
  doPostUploadFiles,
  doUpdateAssignBookings,
  doUpdateBookings,
} from "../../../services/apiServices";
import { changePreloaderStatus } from "../../../store/layout/actions";
import Select from "react-select";
import ReactSwitch from "react-switch";
import { Offsymbol, OnSymbol } from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import swal from "sweetalert";
import makeAnimated from "react-select/animated";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
import { bookingFilterConfig } from "./BookingFilterConfig";

const animatedComponents = makeAnimated();
const restrictedext = ["png", "jpg", "jpeg", "webp"];

class Bookings extends Component {
  state = {
    bookings: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 5,
    },
    filterQuery: "",
    bookingAssigned: {
      assigned: true,
      comments: "",
    },
    openPopUp: false,
    reviewModal: false,
    reviewImages: [],
    reviewFileCount: 0,
    selectedBooking: new BookingModal(),
    technicians: [],
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Bookings",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount = (currentPageNo = this.state.pagination.currentPageNo, filterQuery = this.state.filterQuery) => {
    doGetBookings("count", filterQuery !== "" ? "&" + filterQuery : "").then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllBookings(currentPageNo, this.state.pagination.limit, filterQuery);
          }
        );
      },
      (err) => {}
    );
  };

  getAllTechnicians = (bookingid = 0) => {
    doGetEligibleTechnicians(bookingid).then(
      (res) => {
        let technicians = getValidatedData(res.data, "array")
          .map((t) => {
            if (t && t.technician && t.technician.technician) {
              return t.technician.technician;
            }
            return -1;
          })
          .filter((t) => t !== -1);
        this.setState({
          technicians: getConvertedUsers(getValidatedData(res.data, "array")),
        });
      },
      (err) => {
        this.setState({
          technicians: [],
        });
      }
    );
  };

  doPagination = (currentPageNo) => {
    this.getAllBookings(currentPageNo, this.state.pagination.limit);
  };

  getAllBookings = (currentPageNo = 0, limit = 1, filterQuery = this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetBookings(
      "",
      "_limit=" + limit + "&_start=" + currentPageNo * limit + (filterQuery !== "" ? "&" + filterQuery : "")
    ).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev) => {
          let st = prev;
          st.bookings = getConvertedBookings(getValidatedData(res.data, "array"));
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          bookings: [],
        });
      }
    );
  };

  setOpenPopUp = (value, booking = new BookingModal()) => {
    this.setState((prev) => {
      let st = prev;
      st.openPopUp = value;
      if (st.openPopUp) {
        this.getAllTechnicians(booking.id);
      }
      st.bookingAssigned = {
        assigned: false,
        comments: "",
      };
      if (booking.assignedTo.id !== 0) {
        st.bookingAssigned = {
          assigned: true,
          comments: "",
        };
      }
      st.selectedBooking = new BookingModal().setObj(booking);
      return st;
    });
  };

  onAssignChange = (key = "", value) => {
    if (key === "technician") {
      this.setState((prev) => {
        let st = prev;
        const selectedTech = st.technicians.filter((t) => t.id === value);
        if (selectedTech.length > 0) {
          st.selectedBooking.assignedTo = selectedTech[0];
        } else {
          st.selectedBooking.assignedTo = new UserModal();
        }
        return st;
      });
    } else if (key === "comments") {
      this.setState((prev) => {
        let st = prev;
        st.bookingAssigned.comments = value;
        return st;
      });
    }
  };

  onAssign = () => {
    if (this.state.selectedBooking.assignedTo.id !== 0) {
      const request = {
        bookingId: this.state.selectedBooking.id,
        technicianId: this.state.selectedBooking.assignedTo.id,
        comments: this.state.bookingAssigned.comments,
      };
      this.props.dispatch(changePreloaderStatus(true));
      doUpdateAssignBookings(request).then(
        (res) => {
          this.setState({
            openPopUp: false,
            selectedBooking: new BookingModal(),
          });
          swal("Success", "Successfully Updated", "success");
          this.getAllBookings(this.state.pagination.currentPageNo, this.state.pagination.limit);
        },
        (err) => {
          swal("Error", "", "error");
          this.props.dispatch(changePreloaderStatus(false));
        }
      );
    }
  };

  onReviewFormChange = (e) => {
    const { name, files, value } = e.target;
    if (this.state.selectedBooking.review.id === 0)
      switch (name) {
        case "images":
          if (files.length > 0) {
            let imagesSelected = [];
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
                imagesSelected.push({
                  name: "files",
                  value: file,
                  filename: file.name,
                });
              }
            }
            imagesSelected = imagesSelected.filter((file, pos) => {
              const index = imagesSelected.findIndex((item) => item.filename === file.filename);
              return index === pos;
            });

            this.setState((prev) => {
              let st = prev;
              st.reviewImages = [...imagesSelected];
              return st;
            });
          }
          break;
        case "rating":
          this.setState((prev) => {
            let st = prev;
            st.selectedBooking.review.rating = value;
            return st;
          });
          break;
        case "comments":
          this.setState((prev) => {
            let st = prev;
            st.selectedBooking.review.comments = value;
            return st;
          });
          break;
        case "tshirt":
          this.setState((prev) => {
            let st = prev;
            st.selectedBooking.review.tshirt = value;
            return st;
          });
          break;
        case "greetedCustomer":
          this.setState((prev) => {
            let st = prev;
            st.selectedBooking.review.greetedCustomer = value;
            return st;
          });
          break;
        case "cleanedWorkplace":
          this.setState((prev) => {
            let st = prev;
            st.selectedBooking.review.cleanedWorkplace = value;
            return st;
          });
          break;
        default:
          break;
      }
  };

  reviewSubmit = (e) => {
    e.preventDefault();
    const request = this.state.selectedBooking.getAddRequest();
    const review = this.state.selectedBooking.review.getAddRequest();
    this.props.dispatch(changePreloaderStatus(true));
    if (this.state.selectedBooking.review.id === 0) {
      const callback = (images = []) => {
        review.images = review.images.concat(images.map((i) => ({ id: i.id })));
        doPostReviewDetails(review).then(
          (res) => {
            request.review = res.data.id;
            doUpdateBookings(request.id, request).then(
              (res1) => {
                this.props.dispatch(changePreloaderStatus(false));
                swal("Success", "Succesfully Updated", "success");
                this.toggleReviewModal();
                this.getAllBookings(this.state.pagination.currentPageNo, this.state.pagination.limit);
              },
              (err1) => {
                this.props.dispatch(changePreloaderStatus(false));
                swal("Error", "", "error");
              }
            );
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      };

      if (this.state.reviewImages.length > 0) {
        const formDatas = this.state.reviewImages.map((file) => convertToFormData(file));
        doPostUploadFiles(formDatas).then(
          (res) => {
            // console.log(res);
            callback(res.map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 })).filter((r) => r.id !== -1));
          },
          (err) => {
            callback([]);
          }
        );
      } else {
        callback([]);
      }
    }
  };

  toggleReviewModal = (value = false, booking = new BookingModal()) => {
    this.setState((prev) => {
      let st = prev;
      st.reviewModal = value;
      st.reviewFileCount++;
      st.reviewImages = [];
      st.selectedBooking = new BookingModal().setObj(booking);
      return st;
    });
  };

  dofilterData = (query = "") => {
    this.getCount(0, query);
  };

  render() {
    return (
      <>
        <Modal isOpen={this.state.openPopUp} backdrop="static" toggle={() => this.setOpenPopUp(false)}>
          <ModalHeader toggle={() => this.setOpenPopUp(false)}>
            Technician Assignment for Booking {this.state.selectedBooking.code}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Select a Technician</Label>
                  <Select
                    value={{
                      label: this.state.selectedBooking.assignedTo.getFullName(),
                      value: this.state.selectedBooking.assignedTo.id,
                    }}
                    isMulti={false}
                    onChange={(e) => this.onAssignChange("technician", e.value)}
                    options={this.state.technicians.map((t) => ({
                      label: t.getFullName(),
                      value: t.id,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              {this.state.bookingAssigned.assigned ? (
                <></>
              ) : (
                <>
                  <Col md={12}>
                    <FormGroup>
                      <Label>Comments</Label>
                      <Input
                        type="textarea"
                        value={this.state.bookingAssigned.comments}
                        onChange={(e) => this.onAssignChange("comments", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Button onClick={this.onAssign}>Assign</Button>
                  </Col>
                </>
              )}
            </Row>
          </ModalBody>
        </Modal>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Bookings"} breadcrumbItems={this.state.breadcrumbItems} />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                <Link to="/bookings/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={bookingFilterConfig.filters} />
              </Col>
              <Col md={12}>
                <Table striped bordered hover className="table mb-3 pr-0" style={{ verticalAlign: "middle" }}>
                  <thead>
                    <tr>
                      <th>Sno</th>
                      <th>BookingId</th>
                      <th>Service</th>
                      <th>From Time</th>
                      <th>To Time</th>
                      <th>Review</th>
                      <th>Assigned</th>
                      <th>View</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.bookings.map((booking, index) => {
                      return (
                        <tr key={"bookingTr_" + index}>
                          <td className="center-field">
                            {index + this.state.pagination.currentPageNo * this.state.pagination.limit + 1}
                          </td>
                          <td className="center-field">{booking.code}</td>
                          <td className="center-field">{booking.service.name}</td>
                          <td>
                            {/* {addMomentMinutes(moment(booking.fromTime).utc())
                              .format("DD MMM YYYY HH:mm:ss")} */}
                            {moment(booking.fromTime).utc().format("DD MMM YYYY hh:mm")}
                          </td>
                          <td>
                            {/* {addMomentMinutes(moment(booking.toTime).utc())
                              .format("DD MMM YYYY HH:mm:ss")} */}
                            {moment(booking.toTime).utc().format("DD MMM YYYY hh:mm")}
                          </td>
                          <td>
                            {booking.review.id !== 0 ? (
                              <span
                                onClick={(e) => this.toggleReviewModal(true, booking)}
                                className="badge-soft-primary badge badge-success cursor-pointer badge-pill"
                              >
                                Reviewed
                              </span>
                            ) : (
                              <Button
                                onClick={(e) => this.toggleReviewModal(true, booking)}
                                color="danger"
                                className="btn px-1 py-0"
                              >
                                {"Not Reviewed"}
                              </Button>
                            )}
                          </td>
                          <td>
                            {booking.assignedTo.id !== 0 ? (
                              <span
                                onClick={(e) => this.setOpenPopUp(true, booking)}
                                className="badge-soft-primary badge badge-success cursor-pointer badge-pill"
                              >
                                {booking.assignedTo.getFullName()}
                              </span>
                            ) : (
                              <Button onClick={(e) => this.setOpenPopUp(true, booking)} color="danger" className="btn px-1 py-0">
                                {"Not Assigned"}
                              </Button>
                            )}
                          </td>
                          <td>
                            <Link to={"/booking/" + booking.id} className="btn btn-light">
                              <i className="mdi mdi-folder-information"></i>
                            </Link>
                          </td>
                          <td>
                            <Link to={"/bookings/" + booking.id}>
                              <i className="fa fa-edit text-warning"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          toggle={() => {
            this.toggleReviewModal();
          }}
          isOpen={this.state.reviewModal}
        >
          <ModalHeader>Add A Review</ModalHeader>
          <Form onSubmit={this.reviewSubmit}>
            <ModalBody>
              <Row>
                <Col lg={6}>
                  <div className="form-group star-group">
                    <label>Rating</label>
                    {/* Commented By Nilesh - 21/09/2024 */}
                    {/* <RatingTooltip
                      max={5}
                      onChange={(rate) => {
                        this.onReviewFormChange({
                          target: { name: "rating", value: rate },
                        });
                      }}
                      ActiveComponent={
                        <i
                          key={"active_1"}
                          className="mdi mdi-star text-primary"
                        />
                      }
                      InActiveComponent={
                        <i
                          key={"active_01"}
                          className="mdi mdi-star-outline text-muted"
                        />
                      }
                      disabled={this.state.selectedBooking.review.id !== 0}
                      defaultRating={this.state.selectedBooking.review.rating}
                    /> */}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group star-group">
                    <label>Comments</label>
                    <Input
                      type="textarea"
                      value={this.state.selectedBooking.review.comments}
                      name="comments"
                      onChange={this.onReviewFormChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group star-group">
                    <label className="d-block">TShirt</label>
                    <ReactSwitch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={(e) =>
                        this.onReviewFormChange({
                          target: {
                            name: "tshirt",
                            value: !this.state.selectedBooking.review.tshirt,
                          },
                        })
                      }
                      checked={this.state.selectedBooking.review.tshirt}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group star-group">
                    <label className="d-block">Greeted Customer</label>
                    <ReactSwitch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={(e) =>
                        this.onReviewFormChange({
                          target: {
                            name: "greetedCustomer",
                            value: !this.state.selectedBooking.review.greetedCustomer,
                          },
                        })
                      }
                      checked={this.state.selectedBooking.review.greetedCustomer}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group star-group">
                    <label className="d-block">Cleaned WorkSpace</label>
                    <ReactSwitch
                      uncheckedIcon={<Offsymbol />}
                      className="mr-1"
                      checkedIcon={<OnSymbol />}
                      onColor="#626ed4"
                      onChange={(e) =>
                        this.onReviewFormChange({
                          target: {
                            name: "cleanedWorkplace",
                            value: !this.state.selectedBooking.review.cleanedWorkplace,
                          },
                        })
                      }
                      checked={this.state.selectedBooking.review.cleanedWorkplace}
                    />
                  </div>
                </Col>
                {this.state.selectedBooking.review.id === 0 ? (
                  <>
                    <Col lg={12}>
                      <div className="form-group star-group">
                        <label>Images</label>
                        <Input
                          type="file"
                          multiple
                          name="images"
                          key={"review" + this.state.reviewFileCount}
                          onChange={this.onReviewFormChange}
                        />
                      </div>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
                <Col lg={12}>
                  <div className="form-group">
                    {this.state.selectedBooking.review.images.map((image) => {
                      return <img className="img-fluid mr-2" width="100" src={getImageUrl(image.url)} alt="thumbnail" />;
                    })}
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              {this.state.selectedBooking.review.id === 0 ? (
                <>
                  <Button type="submit" color="success">
                    {this.state.selectedBooking.review.id === 0 ? "Save" : "Update"}
                  </Button>
                </>
              ) : (
                <></>
              )}
              <Button
                type="button"
                onClick={() => {
                  this.toggleReviewModal();
                }}
                color="danger"
              >
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default connect(mapStateToProps)(Bookings);
