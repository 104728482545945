import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import swal from "sweetalert";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
import {
  doDeleteSlider,
  doGetAllSliders,
  doGetAllSlidersCount,
} from "../../../services/apiServices";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { sliderFilterConfig } from "./SliderFilterConfig";

class ViewSlider extends Component {
  state = {
    sliders: [],
    filterQuery: "",
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
  };
  componentDidMount() {
    this.getSliders(0, this.state.pagination.limit);
  }
  getSliders = (currentPageNo, limit, filterQuery = this.state.filterQuery) => {
    let query = `_limit=${limit}&_start=${(currentPageNo*limit) + ((filterQuery !== "") ? "&" + filterQuery : "")}`;
    doGetAllSlidersCount(((filterQuery !== "") ? "&" + filterQuery : "")).then((res1) => {
      this.setState((prev) => {
        let st = prev;
        st.pagination.totalPages = Math.ceil(res1.data / st.pagination.limit);
        st.pagination.currentPageNo = currentPageNo;
        return st;
      });
      doGetAllSliders(query).then(
        (res) => {
          this.setState((prev) => {
            let st = prev;
            st.sliders = res.data;

            return st;
          });
        },
        (err) => { }
      );
    });
  };
  doPagination = (currentPageNo) => {
    this.getSliders(currentPageNo, this.state.pagination.limit);
  };
  handleSliderDelete = (id) => {
    swal({
      title: "Are you sure to delete?",
      text: 'You will not be able to recover data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doDeleteSlider(id).then(
          (res) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Success", "Slider Updated successfully", "success");
            this.getSliders(0, this.state.pagination.limit);
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  dofilterData = (query = "") => {
    this.getSliders(0, this.state.pagination.limit,query);
  }

  render() {
    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Sliders</h4>
          <span>
            <Link
              to="/slider/add"
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              Add New Slider <i className="mdi mdi-arrow-right ml-1"></i>
            </Link>
          </span>
        </div>

        <Row>
          <Col md={12}>
            <FilterView doFilter={this.dofilterData} filterOptions={sliderFilterConfig.filters} />
          </Col>
          <Col md={12}>
            <Table
              striped
              bordered
              hover
              className="table mb-3 pr-0"
              style={{ verticalAlign: "middle" }}
            >
              <tr>
                <th>Title</th>
                <th>Image</th>
                <th>Type</th>
                <th>Order</th>
                <th>Active</th>
                <th>IframeUrl</th>
              </tr>
              {this.state.sliders.length > 0 &&
                this.state.sliders.map((slider, i) => {
                  return (
                    <tr key={i}>
                      <td>{slider.title}</td>
                      <td>
                        {" "}
                        <img
                          src={slider.image ? slider.image.url : ""}
                          alt=""
                          width="40px"
                          height="40px"
                          onError={(e) => {
                            e.target.style.display = 'none'
                          }}
                        />
                      </td>
                      <td>{slider.type}</td>
                      <td>{slider.order}</td>
                      <td>{slider.active ? "true" : "false"}</td>

                      <td>{slider.iframeUrl}</td>

                      <td>
                        <Link to={`slider/edit/${slider.id}`}>
                          {" "}
                          <Button>
                            <i className="fas fa-edit"></i>
                          </Button>
                        </Link>
                        {"    "}
                        <Button
                          color="danger"
                          onClick={() => this.handleSliderDelete(slider.id)}
                        >
                          <i
                            className={
                              slider.active ? "fas fa-trash-alt" : "fas fa-undo"
                            }
                          ></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </Table>
          </Col>
          <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(ViewSlider);