export const walletFilterConfig={
    filters:[
        {
            label:"First Name",
            placeholder:"Search First Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"user.firstname",
        },
        {
            label:"Last Name",
            placeholder:"Search Last Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"user.lastname",
        },
        {
            label:"Email",
            placeholder:"Search Email",
            filterPostfix:"_contains",
            value:"",
            type:"email",
            key:"user.email",
        },
        {
            label:"Mobile",
            placeholder:"Search Mobile",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"user.phonenumber",
        },
        {
            label:"Type",
            placeholder:"ALL",
            filterPostfix:"_contains",
            value:"",
            options:[{label:"Technician",value:"TECHNICIAN"},{label:"Customer",value:"CUSTOMER"}],
            type:"select",
            key:"type",
        }
    ]
}