import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Table } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../../constants/constants";
import { getConvertedUsers } from "../../../../constants/conversions/users";
import {
  doGetAllUsers,
  doPutUpdateUser,
} from "../../../../services/apiServices";
// Table Css
// import "../../tables.css";
import swal from "sweetalert";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../../store/layout/actions";
import CustomPagination from "../../../../components/Common/CustomPagination";
import FilterView from "../../../../components/Common/FilterView";
import { adminFilterConfig } from "./AdminFilterConfig";

class Admins extends Component {
  state = {
    users: [],
    pagination:{
      count:0,
      currentPageNo:0,
      totalPages:0,
      limit:5,
    },
    filterQuery:"",
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Admins",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount=(currentPageNo=this.state.pagination.currentPageNo,filterQuery=this.state.filterQuery)=>{
    doGetAllUsers("count", "blocked_eq=false&role.type_in=super_admin&role.type_in=admin"+((filterQuery!=="")?"&"+filterQuery:"")).then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllUsers(
              currentPageNo,
              this.state.pagination.limit,
              filterQuery
            );
          }
        );
      },
      (err) => {}
    );
  }

  doPagination=(currentPageNo)=>{
    this.getAllUsers(currentPageNo,this.state.pagination.limit);
  }

  getAllUsers = (currentPageNo=0,limit=1,filterQuery=this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers("", "blocked_eq=false&role.type_in=super_admin&role.type_in=finance_admin&role.type_in=service_admin&_limit="+limit+"&_start="+(currentPageNo*limit)+((filterQuery!=="")?"&"+filterQuery:"")).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev)=>{
          let st=prev;
          st.users= getConvertedUsers(getValidatedData(res.data, "array"))
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages=Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          users: [],
        });
      }
    );
  };

  doDeleteUser = (user) => {
    swal({
      title: "Are you sure to delete?",
      text: 'You will not be able to recover "' + user.getFullName() + '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doPutUpdateUser(user.id, JSON.stringify({ blocked: true })).then(
          (res) => {
            swal("Success", "Succesfully Deleted", "success");
            this.getAllUsers(this.state.pagination.currentPageNo, this.state.pagination.limit);
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  dofilterData=(query="") => {
    this.getCount(0,query);
  }

  render() {
    const currentSno=this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Admins"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                <Link to="/users/admins/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={adminFilterConfig.filters} />
              </Col>
              <Col md="12">
                <Table striped bordered className="table">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Username</th>
                      <th>Email</th>
                      <th>Mobile</th>
                      <th>Role</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.users.map((user, index) => {
                      return (
                        <React.Fragment key={user.id}>
                          <tr>
                            <td>{"" + (currentSno+index + 1)}</td>
                            <td>{user.getFullName()}</td>
                            <td>{user.userName}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.role.name}</td>
                            <td>
                              <Link to={"/users/admins/" + user.id}>
                                <i
                                  className="fa fa-edit text-warning mr-2"
                                  title="Edit"
                                ></i>
                              </Link>
                              <i
                                onClick={() => this.doDeleteUser(user)}
                                className="cursor-pointer fa fa-trash text-danger"
                                title="Delete"
                              ></i>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                  <CustomPagination
                    currentPage={this.state.pagination.currentPageNo}
                    totalPages={this.state.pagination.totalPages}
                    perSlide={5}
                    doPagination={this.doPagination}
                  />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Admins);
