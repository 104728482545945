import { LocationModal, ServiceLocation } from "../../classes/classes"
import { getValidatedData } from "../constants";
import { getConvertedLocations } from "./locations";
import { getConvertedPrices } from "./prices";

export function getConvertedServiceLocations(locations=[]){

    return locations.filter((k)=>{
      return Object.keys(k).length>0
    }).map((location)=>{

        const id=getValidatedData(location.id,'number');
        const prices=getConvertedPrices(getValidatedData(location.price,'array'))
        const cities=(getValidatedData(location.city,'object',true))?getConvertedLocations([getValidatedData(location.city,'object')]):[]
        const city = (cities.length>0)?cities[0]:new LocationModal();
        const userStatus = getValidatedData(location.user_access, "boolean");
        const technicianStatus = getValidatedData(location.technician_access, "boolean");
        return new ServiceLocation(id, prices, city, userStatus, technicianStatus);
    })

}