import React, { Component } from "react";
import { Col, Container, Row, Card, Table } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../constants/constants";
import {
  doGetAllCategories,
  doPutUpdateCategory,
} from "../../../services/apiServices";
// Table Styles
// import "../tables.css";
import swal from "sweetalert";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { getConvertedCategories } from "../../../constants/conversions/categories";
import ReactSwitch from "react-switch";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";

class Categories extends Component {
  state = {
    categories: [],
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Categories",
      },
    ],
  };

  componentDidMount() {
    this.getAllCategories();
  }

  getAllCategories = () => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllCategories().then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          categories: getConvertedCategories(
            getValidatedData(res.data, "array")
          ),
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          categories: [],
        });
      }
    );
  };

  doUpdateActiveCategory = (location, active) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateCategory(location.id, JSON.stringify({ active: active })).then(
      (res) => {
        swal("Success", "Succesfully Updated", "success");
        this.getAllCategories();
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        swal("Error", "", "error");
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Categories"}
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col md="12" className="mb-3 mt-3">
                {/* <Link to="/categories/add" className="float-right"><Button className="btn-success">
                                    <i className="fa fa-plus"></i></Button></Link> */}
              </Col>
              <Col md="12">
                <Table
                  striped
                  bordered
                  hover
                  className="table mb-3 pr-0"
                >
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.categories.map((category, index) => {
                      return (
                        <React.Fragment key={category.id}>
                          <tr>
                            <td>{"" + (index + 1)}</td>
                            <td>{category.name}</td>
                            <td>
                              {category.description}
                            </td>
                            <td>
                              <ReactSwitch
                                name="active"
                                uncheckedIcon={<Offsymbol />}
                                className="mr-1"
                                checkedIcon={<OnSymbol />}
                                onChange={() =>
                                  this.doUpdateActiveCategory(
                                    category,
                                    !category.active
                                  )
                                }
                                onColor="#626ed4"
                                checked={category.active}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Categories);
