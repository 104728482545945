import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Button,
  Col,
  Container,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import swal from "sweetalert";
// Css for Tables
// import "../tables.css";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ExcelSample from "../../../assets/documents/inventoryExcel.xlsx";
import {
  doGetAllLocations,
  doGetAllServices,
  doGetInventoryItems,
  doPostAllAddInventoryItems,
  doPutDeleteInventoryItem,
  doPutUpdateInventoryItem,
} from "../../../services/apiServices";
import * as XLSX from "xlsx";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import { getExt, getValidatedData } from "../../../constants/constants";
import {
  LocationModal,
  LocationPincodeModal,
  ServiceModal,
} from "../../../classes/classes";
import { getConvertedServices } from "../../../constants/conversions/services";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { connect } from "react-redux";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
import { inventoryFilterconfig } from "./InventoryFilterData";

const animatedComponents = makeAnimated();

export const restrictedext = ["xls", "xlsx", "csv"];

class InventoryItems extends Component {
  state = {
    items: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 5,
    },
    filterQuery:"",
    excelUpload: {
      open: false,
      file: null,
      fileCount: 0,
      city: new LocationModal(),
      service: new ServiceModal(),
      allcities: [],
      selectedServices: [],
      allservices: [],
    },
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Inventory",
      },
    ],
  };

  doGetExcelData = (file, service, city) => {
    const reader = new FileReader();
    const k = this;
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      data.splice(0, 1);
      data = data.map((d) => {
        return {
          item_name: d[0],
          city: city.id,
          service: service.id,
          item_type: d[1],
          item_description: d[2],
          item_price: getValidatedData(d[3],'number'),
          price_mask: "" + (d[4]),
          commission: getValidatedData(d[5],"number"),
          active: true,
        };
      });
      console.log(data);
      k.doPostAllInventories(data);
    };
    reader.readAsBinaryString(file);
  };

  doPostAllInventories = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPostAllAddInventoryItems(request).then(
      (res) => {
        swal("Success", "Uploaded Successfully", "success");
        this.props.dispatch(changePreloaderStatus(false));

        this.getInventoryItems(
          this.state.pagination.currentPageNo,
          this.state.pagination.limit
        );
        this.setState((prev) => {
          let st = prev;
          st.excelUpload.open = false;
          st.excelUpload.file = null;
          st.excelUpload.fileCount++;
          st.excelUpload.city = new LocationModal();
          st.excelUpload.service = new ServiceModal();
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
      }
    );
  };

  getInventoryItems = (currentPageNo = 0, limit = 1,filterQuery=this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetInventoryItems(
      "",
      "active_eq=true&_limit=" + limit + "&_start=" + (currentPageNo*limit)+((filterQuery!=="")?"&"+filterQuery:"")
    ).then(
      (response) => {
        this.setState((prev) => {
          let st = prev;
          st.items = response.data;
          st.pagination.limit = limit;
          st.filterQuery=filterQuery;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
        this.props.dispatch(changePreloaderStatus(false));
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        //console.log("Get Inventory Error :- ", err);
        swal("Error", "SomeThing Unexpected Happened", "error").then(
          (res) => {}
        );
        this.props.dispatch(changePreloaderStatus(false));
      }
    );
  };

  componentDidMount() {
    this.getAllLocations();
    this.getAllServices();
    this.getCount();
  }

  getCount = () => {
    doGetInventoryItems("count", "active_eq=true").then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getInventoryItems(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit
            );
          }
        );
      },
      (err) => {}
    );
  };

  doPagination = (currentPageNo) => {
    this.getInventoryItems(currentPageNo, this.state.pagination.limit);
  };

  deleteItem = (item) => {
    swal({
      title: "Are you sure to delete?",
      text: 'You will not be able to recover "' + item.item_name + '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        doPutDeleteInventoryItem(
          item.id,
          JSON.stringify({ active: false })
        ).then(
          (res) => {
            //console.log("Deletion Response :- ", res);
            swal("Success", "Deletion Success", "success");
            this.getInventoryItems(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit
            );
          },
          (err) => {
            //console.log("Deletion Error :- ", err);
            swal("Error", "SomeThing Unexpected Happened", "error");
          }
        );
      }
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.excelUpload.service.id !== 0 &&
      this.state.excelUpload.city.id !== 0 &&
      this.state.excelUpload.file !== null
    ) {
      this.doGetExcelData(
        this.state.excelUpload.file,
        this.state.excelUpload.service,
        this.state.excelUpload.city
      );
    }
  };

  getServicesBasedOnCity = (services = [], cityName = "") => {
    return services.filter((s) => {
      const selectedCities = s.serviceLocations
        .filter((sl) => {
          return sl.city.name.toLowerCase() === cityName.toLowerCase();
        })
        .filter((s) => s.city.active);
      return selectedCities.length > 0;
    });
  };

  setOpen = (value) => {
    this.setState((prev) => {
      let st = prev;
      st.excelUpload.open = value;
      if (!value) {
        st.excelUpload.file = null;
        st.excelUpload.fileCount++;
        st.excelUpload.city = new LocationModal();
        st.excelUpload.service = new ServiceModal();
      }
      return st;
    });
  };

  getAllLocations = () => {
    doGetAllLocations().then(
      (res) => {
        this.setState((prev) => {
          let st = prev;
          st.excelUpload.allcities = getConvertedLocations(
            getValidatedData(res.data, "array")
          ).filter((s) => s.active);
          return st;
        });
      },
      (err) => {
        this.setState((prev) => {
          let st = prev;
          st.excelUpload.allcities = [];
          return st;
        });
      }
    );
  };

  getAllServices = () => {
    doGetAllServices().then(
      (res) => {
        //console.log(res.data);
        this.setState((prev) => {
          let st = prev;
          st.excelUpload.allservices = getConvertedServices(
            getValidatedData(res.data, "array")
          );
          st.excelUpload.selectedServices = [];
          return st;
        });
      },
      (err) => {
        this.setState((prev) => {
          let st = prev;
          st.excelUpload.allservices = [];
          st.excelUpload.selectedServices = [];
          return st;
        });
      }
    );
  };

  onHandleChange = (e) => {
    const { name, files, value } = e.target;
    switch (name) {
      case "city":
        this.setState((prev) => {
          let st = prev;
          const selectedCities = st.excelUpload.allcities.filter(
            (c) => c.id === value
          );
          if (selectedCities.length > 0) {
            st.excelUpload.city = selectedCities[0];
            st.excelUpload.service = new ServiceModal();
            st.excelUpload.selectedServices = this.getServicesBasedOnCity(
              st.excelUpload.allservices,
              selectedCities[0].name
            );
          }
          return st;
        });
        break;
      case "service":
        this.setState((prev) => {
          let st = prev;
          const services = st.excelUpload.selectedServices.filter(
            (c) => c.id === value
          );
          if (services.length > 0) {
            st.excelUpload.service = services[0];
          }
          return st;
        });
        break;
      case "file":
        this.setState((prev) => {
          let st = prev;
          if (
            files.length > 0 &&
            restrictedext.indexOf(getExt(files[0].name).toLowerCase()) !== -1
          ) {
            st.excelUpload.file = files[0];
          }
          return st;
        });
        break;
      default:
    }
  };

  dofilterData=(query="") => {
    this.getInventoryItems(0,this.state.pagination.limit,query);
  }

  render() {
    const currentSno =
      this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <>
        <div className="page-content">
          <Modal
            isOpen={this.state.excelUpload.open}
            backdrop={"static"}
            keyboard={false}
            toggle={() => {
              this.setOpen(!this.state.excelUpload.open);
            }}
          >
            <ModalHeader
              toggle={() => {
                this.setOpen(!this.state.excelUpload.open);
              }}
            >
              Upload Excel
            </ModalHeader>
            <ModalBody>
              <Form className="row" onSubmit={this.onFormSubmit}>
                <Col md={12}>
                  <FormGroup>
                    <Label>City</Label>
                    <Select
                      name="city"
                      value={{
                        label: this.state.excelUpload.city.name,
                        value: this.state.excelUpload.city.id,
                      }}
                      isMulti={false}
                      onChange={(e) =>
                        this.onHandleChange({
                          target: {
                            name: "city",
                            value: e.value,
                          },
                        })
                      }
                      options={this.state.excelUpload.allcities.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Service</Label>
                    <Select
                      name="pincode"
                      value={{
                        label: this.state.excelUpload.service.name,
                        value: this.state.excelUpload.service.id,
                      }}
                      isMulti={false}
                      onChange={(e) =>
                        this.onHandleChange({
                          target: {
                            name: "service",
                            value: e.value,
                          },
                        })
                      }
                      options={this.state.excelUpload.selectedServices.map(
                        (c) => ({ label: c.name, value: c.id })
                      )}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Upload File</Label>
                    <Input
                      type="file"
                      onChange={this.onHandleChange}
                      name="file"
                      key={"upFile" + this.state.excelUpload.fileCount}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Button type="submit" color="success">
                    Submit
                  </Button>
                </Col>
              </Form>
            </ModalBody>
          </Modal>
          <Container fluid>
            <Breadcrumbs
              title={"Inventory"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                <Button
                  onClick={(e) => this.setOpen(true)}
                  color="primary"
                  className="float-right ml-3"
                >
                  Upload Excel
                </Button>
                <Link
                  to={ExcelSample}
                  target={"_blank"}
                  className="float-right ml-3"
                >
                  <Button className="btn btn-success">
                    <i className="fa fa-download"></i> Sample File
                  </Button>
                </Link>
                <Link to="/inventory/add" className="float-right">
                  <Button className="btn btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={inventoryFilterconfig.filters} />
              </Col>
              <Col md="12">
                {/* TODO: Card For Table */}
                <Table striped bordered hover className="table mb-3 pr-0">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Name</th>
                      <th>Service Type</th>
                      <th>Price</th>
                      <th>Commission</th>
                      <th>Price Mask</th>
                      <th>City</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.items
                      .filter((el) => el.active === true)
                      .map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>{currentSno + index + 1}</td>
                            {/* <td>{item.id}</td> */}
                            <td>{item.item_name}</td>
                            <td>{item.service.name}</td>
                            <td>{item.item_price}</td>
                            <td>{item.commission + " %"}</td>
                            <td>
                              {item.price_mask === "" ||
                              item.price_mask === null
                                ? "--"
                                : item.price_mask}
                            </td>
                            {/* <td>{item.item_unit}</td> */}
                            <td>{item.city.name}</td>

                            <td>
                              <Link
                                to={"/inventory/" + item.id}
                                className="btn btn-light"
                              >
                                <i className="cursor-pointer fas fa-edit"></i>
                              </Link>
                            </td>
                            <td>
                              <i
                                className="cursor-pointer fas fa-trash text-danger"
                                title="Delete"
                                onClick={() => this.deleteItem(item)}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default connect()(InventoryItems);
