import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
  ModalFooter,
  CardHeader,
  Input,
  Table,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../store/layout/actions";

// Styles
import "./BookingStyles.css";
import ReactSwitch from "react-switch";
import { Offsymbol, OnSymbol } from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import swal from "sweetalert";

//Import Image
import img1 from "../../../assets/images/companies/img-1.png";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import {
  doGetBookings,
  doGetBookingStatusMapsById,
  doPostDisputeDetails,
  doPostReviewDetails,
  doUpdateBookings,
  doPostUploadFiles,
  doUpdateDisputeDetails,
  doGetAllUsers,
  doCancelBooking,
  doRevisitBooking,
  doRescheduleBooking,
  doPutBookingPenalty,
  doGetBookingPenalty,
  doGetBillingDetails,
  doGetPaymentsById,
} from "../../../services/apiServices";
import { getValidatedData, getExt, convertToFormData, getImageUrl, bookingSlots } from "../../../constants/constants";
import Rating from "react-rating";
import { BookingModal, DisputeModal, ReviewModal } from "../../../classes/classes";
import { getConvertedBookings } from "../../../constants/conversions/bookings";
import { getConvertedUsers } from "../../../constants/conversions/users";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const animatedComponents = makeAnimated();
const statuses = ["PENDING", "INPROGRESS", "RESOLVED", "CANCELLED"];
const restrictedext = ["png", "jpg", "jpeg", "webp"];

class BookingOverview extends Component {
  state = {
    otherBookings: [],
    booking: new BookingModal(),
    cancelModal: {
      open: false,
      comments: "",
    },
    revisitModal: {
      open: false,
      comments: "",
      date: "",
      slot: "",
    },
    rescheduleModal: {
      open: false,
      comments: "",
      date: "",
      slot: "",
    },
    users: [],
    bookingDate: "",
    bookingSlot: "",
    disputeModal: false,
    disputeData: new DisputeModal(),
    reviewModal: false,
    reviewData: new ReviewModal(),
    bookingStatusMaps: [],
    reviewFileCount: 0,
    reviewImages: [],
    penaltyModal: false,
    penaltyData: 0,
    penaltyArray: [],
    billingDetails: [],
  };
  toggleDisputeModal = () => {
    // console.log(new DisputeModal().setObj(this.state.booking.dispute));
    this.setState({
      disputeModal: !this.state.disputeModal,
      disputeData: new DisputeModal().setObj(this.state.booking.dispute),
    });
  };

  toggleReviewModal = () => {
    this.setState({
      reviewModal: !this.state.reviewModal,
      reviewData: new ReviewModal().setObj(this.state.booking.review),
    });
  };
  togglePenaltyModal = () => {
    this.setState({
      penaltyModal: !this.state.penaltyModal,
    });
  };

  getAllUsers = () => {
    doGetAllUsers("", "blocked_eq=false").then(
      (res) => {
        this.setState((prev) => {
          let st = prev;
          st.users = getConvertedUsers(getValidatedData(res.data, "array"));
          return st;
        });
      },
      (err) => {
        this.setState({
          users: [],
        });
      }
    );
  };

  getBookingData = (id = "") => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetBookings(id).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        // console.log("Do Get Booking of 1 Response :- ", res.data);
        const selectedBookings = getConvertedBookings([getValidatedData(res.data, "object")]);
        // console.log(selectedBookings);
        const booking = selectedBookings.length > 0 ? selectedBookings[0] : new BookingModal();
        let bookingDate = "",
          bookingSlot = "9am-12pm";
        if (booking.fromTime !== "" && booking.toTime !== "") {
          bookingDate = moment(booking.fromTime).format("DD MMM YYYY");
          let startTime = moment(booking.fromTime).format("hha").replace(/^0+/, "");
          let toTime = moment(booking.toTime).format("hha").replace(/^0+/, "");
          bookingSlot = startTime + "-" + toTime;
        }
        this.getBookingPenalty(booking.code);
        this.getBillingDetailsById(id, booking.code);
        this.setState(
          {
            reviewFileCount: 0,
            reviewImages: [],
            bookingDate: bookingDate,
            bookingSlot: bookingSlot,
            booking: booking,
          },
          () => {
            if (booking.createdBy.id !== 0) {
              this.getAllPreviousBookings(booking.id, booking.createdBy.id);
            }
          }
        );
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));

        // console.log("Do Get Booking of 1 Error :- ", err);
      }
    );
  };

  getAllPreviousBookings = (id = "", userId = "") => {
    doGetBookings("", "id_ne=" + id + "&createdby.id=" + userId).then(
      (res) => {
        this.setState((prev) => {
          let st = prev;
          st.otherBookings = getConvertedBookings(getValidatedData(res.data, "array"));
          return st;
        });
      },
      (err) => {
        this.setState({
          otherBookings: [],
        });
      }
    );
  };

  getBookingStatusMap = (bookingId = "") => {
    doGetBookingStatusMapsById(bookingId).then(
      (res) => {
        // console.log("booking Status Maps :- ", res.data);
        this.setState({
          bookingStatusMaps: res.data,
        });
      },
      (err) => {
        // console.log("Booking Status Maps Error :- ", err);
      }
    );
  };

  componentDidMount() {
    // console.log(this.props.match);
    this.getAllUsers();
    this.initData();
  }
  initData = () => {
    this.getBookingData(this.props.match.params.id);
    this.getBookingStatusMap(this.props.match.params.id);
  };

  onDisputeFormChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "problem":
        this.setState((prev) => {
          let st = prev;
          st.disputeData.problem = value;
          return st;
        });
        break;
      case "description":
        this.setState((prev) => {
          let st = prev;
          st.disputeData.description = value;
          return st;
        });
        break;
      case "status":
        this.setState((prev) => {
          let st = prev;
          st.disputeData.status = value;
          return st;
        });
        break;
      default:
        break;
    }
  };

  onReviewFormChange = (e) => {
    const { name, files, value } = e.target;
    if (this.state.reviewData.id === 0)
      switch (name) {
        case "images":
          if (files.length > 0) {
            let imagesSelected = [];
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
                imagesSelected.push({
                  name: "files",
                  value: file,
                  filename: file.name,
                });
              }
            }
            imagesSelected = imagesSelected.filter((file, pos) => {
              const index = imagesSelected.findIndex((item) => item.filename === file.filename);
              return index === pos;
            });

            this.setState((prev) => {
              let st = prev;
              st.reviewImages = [...imagesSelected];
              return st;
            });
          }
          break;
        case "rating":
          this.setState((prev) => {
            let st = prev;
            st.reviewData.rating = value;
            return st;
          });
          break;
        case "comments":
          this.setState((prev) => {
            let st = prev;
            st.reviewData.comments = value;
            return st;
          });
          break;
        case "tshirt":
          this.setState((prev) => {
            let st = prev;
            st.reviewData.tshirt = value;
            return st;
          });
          break;
        case "greetedCustomer":
          this.setState((prev) => {
            let st = prev;
            st.reviewData.greetedCustomer = value;
            return st;
          });
          break;
        case "cleanedWorkplace":
          this.setState((prev) => {
            let st = prev;
            st.reviewData.cleanedWorkplace = value;
            return st;
          });
          break;
        default:
          break;
      }
  };

  reviewSubmit = (e) => {
    e.preventDefault();
    const request = this.state.booking.getAddRequest();
    const review = this.state.reviewData.getAddRequest();
    this.props.dispatch(changePreloaderStatus(true));
    if (this.state.booking.review.id === 0) {
      const callback = (images = []) => {
        review.images = review.images.concat(images.map((i) => ({ id: i.id })));
        // console.log("Review Data :- ", review);
        doPostReviewDetails(review).then(
          (res) => {
            request.review = res.data.id;
            doUpdateBookings(request.id, request).then(
              (res1) => {
                this.initData();
                swal("Success", "Succesfully Updated", "success");
                this.toggleReviewModal();
                this.initData();
              },
              (err1) => {
                this.props.dispatch(changePreloaderStatus(false));
                swal("Error", "", "error");
              }
            );
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      };

      if (this.state.reviewImages.length > 0) {
        const formDatas = this.state.reviewImages.map((file) => convertToFormData(file));
        doPostUploadFiles(formDatas).then(
          (res) => {
            // console.log(res);
            callback(res.map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 })).filter((r) => r.id !== -1));
          },
          (err) => {
            callback([]);
          }
        );
      } else {
        callback([]);
      }
    }
  };

  disputeSubmit = (e) => {
    e.preventDefault();
    const request = this.state.booking.getAddRequest();
    const dispute = this.state.disputeData.getAddRequest();
    this.props.dispatch(changePreloaderStatus(true));
    if (this.state.booking.dispute.id === 0) {
      doPostDisputeDetails(dispute).then(
        (res) => {
          request.dispute = res.data.id;
          doUpdateBookings(request.id, request).then(
            (res1) => {
              this.initData();
              swal("Success", "Succesfully Updated", "success");
              this.toggleDisputeModal();
              this.initData();
            },
            (err1) => {
              this.props.dispatch(changePreloaderStatus(false));
              swal("Error", "", "error");
            }
          );
        },
        (err) => {
          this.props.dispatch(changePreloaderStatus(false));
          swal("Error", "", "error");
        }
      );
    } else {
      doUpdateDisputeDetails(dispute.id, dispute).then(
        (res1) => {
          this.toggleDisputeModal();
          this.initData();
          swal("Success", "Succesfully Updated", "success");
        },
        (err1) => {
          swal("Error", "", "error");
        }
      );
    }
  };

  setCancelPopUp = (open = false) => {
    this.setState((prev) => {
      let st = prev;
      st.cancelModal.open = open;
      st.cancelModal.comments = "";
      return st;
    });
  };

  onHandleCancelPopup = (value = "") => {
    this.setState((prev) => {
      let st = prev;
      st.cancelModal.comments = value;
      return st;
    });
  };

  onCancelBooking = () => {
    if (this.state.cancelModal.comments !== "") {
      const body = {
        bookingId: this.state.booking.id,
        comments: this.state.cancelModal.comments,
      };
      this.props.dispatch(changePreloaderStatus(true));
      doCancelBooking(body).then(
        () => {
          this.initData();
          this.setCancelPopUp(false);
          swal("Success", "Succesfully Cancelled", "success");
        },
        () => {
          this.props.dispatch(changePreloaderStatus(false));
          swal("Error", "", "error");
        }
      );
    }
  };

  combineBookingDateSlots = (date, slots = "") => {
    const dateStr = moment(date).format("YYYY-MM-DD");
    const splitslots = slots.split("-");
    const slot = {
      fromTime: "",
      toTime: "",
    };
    const data = splitslots
      .map((s) => {
        let number = parseInt(s.match(/\d+/)[0]);
        let number1 = ":00:00";
        const meridian = s.replace("" + number, "");
        if (meridian === "am") {
          if (number < 10) {
            number1 = "0" + number + "" + number1;
          } else {
            number1 = "" + number + "" + number1;
          }
        } else if (meridian === "pm") {
          number += 12;
          number1 = number + number1;
        }
        // console.log(number1);
        return moment(dateStr + " " + number1, "YYYY-MM-DD HH:mm:ss");
      })
      .forEach((m, i) => {
        if (i === 0) {
          slot.fromTime = m.local().toDate();
        } else {
          slot.toTime = m.local().toDate();
        }
      });
    return slot;
  };

  setRevisitPopUp = (open = false) => {
    this.setState((prev) => {
      let st = prev;
      st.revisitModal.open = open;
      st.revisitModal.comments = "";
      st.revisitModal.date = "";
      st.revisitModal.slot = "";
      return st;
    });
  };

  onRevisitBooking = () => {
    if (this.state.revisitModal.comments !== "" && this.state.revisitModal.date !== "" && this.state.revisitModal.slot !== "") {
      const dateslot = this.combineBookingDateSlots(this.state.revisitModal.date, this.state.revisitModal.slot);
      const body = {
        bookingId: this.state.booking.id,
        comments: this.state.revisitModal.comments,
        fromTime: dateslot.fromTime,
        toTime: dateslot.toTime,
      };
      this.props.dispatch(changePreloaderStatus(true));
      doRevisitBooking(body).then(
        () => {
          this.initData();
          this.setRevisitPopUp(false);
          swal("Success", "Succesfully Wil be Revisited", "success");
        },
        () => {
          this.props.dispatch(changePreloaderStatus(false));
          swal("Error", "", "error");
        }
      );
    }
  };

  onRevisitChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "comments":
        this.setState((prev) => {
          let st = prev;
          st.revisitModal.comments = value;
          return st;
        });
        break;
      case "bookingSlot":
        this.setState((prev) => {
          let st = prev;
          st.revisitModal.slot = value;
          return st;
        });
        break;
      case "bookingDate":
        this.setState((prev) => {
          let st = prev;
          let dateCurrentStr = moment().format("YYYY-MM-DD");
          let dateValueStr = moment(value).format("YYYY-MM-DD");
          if (moment(dateValueStr).isSameOrAfter(moment(dateCurrentStr))) {
            st.revisitModal.date = value;
          }
          return st;
        });
        break;
      default:
    }
  };

  setReschedulePopUp = (open = false) => {
    this.setState((prev) => {
      let st = prev;
      st.rescheduleModal.open = open;
      st.rescheduleModal.comments = "";
      st.rescheduleModal.date = "";
      st.rescheduleModal.slot = "";
      return st;
    });
  };

  onRescheduleBooking = () => {
    if (
      this.state.rescheduleModal.comments !== "" &&
      this.state.rescheduleModal.date !== "" &&
      this.state.rescheduleModal.slot !== ""
    ) {
      const dateslot = this.combineBookingDateSlots(this.state.rescheduleModal.date, this.state.rescheduleModal.slot);
      const body = {
        bookingId: this.state.booking.id,
        comments: this.state.rescheduleModal.comments,
        fromTime: dateslot.fromTime,
        toTime: dateslot.toTime,
      };
      this.props.dispatch(changePreloaderStatus(true));
      doRescheduleBooking(body).then(
        () => {
          this.initData();
          this.setReschedulePopUp(false);
          swal("Success", "Succesfully Wil be Revisited", "success");
        },
        () => {
          this.props.dispatch(changePreloaderStatus(false));
          swal("Error", "", "error");
        }
      );
    }
  };

  onRescheduleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "comments":
        this.setState((prev) => {
          let st = prev;
          st.rescheduleModal.comments = value;
          return st;
        });
        break;
      case "bookingSlot":
        this.setState((prev) => {
          let st = prev;
          st.rescheduleModal.slot = value;
          return st;
        });
        break;
      case "bookingDate":
        this.setState((prev) => {
          let st = prev;
          let dateCurrentStr = moment().format("YYYY-MM-DD");
          let dateValueStr = moment(value).format("YYYY-MM-DD");
          if (moment(dateValueStr).isSameOrAfter(moment(dateCurrentStr))) {
            st.rescheduleModal.date = value;
          }
          return st;
        });
        break;
      default:
    }
  };
  handlePenaltyChange = (value = "") => {
    this.setState((prev) => {
      let st = prev;
      st.penaltyData = value;
      return st;
    });
  };
  handlePostPenalty = (e) => {
    e.preventDefault();
    const body = {
      bookingId: this.state.booking.id,
      coins: this.state.penaltyData,
    };
    doPutBookingPenalty(body).then(
      (res1) => {
        this.initData();
        swal("Success", "Succesfully Updated", "success");
        this.togglePenaltyModal();
        this.initData();
        this.setState((prev) => {
          let st = prev;
          st.penaltyData = 0;
          return st;
        });
      },
      (err1) => {
        this.props.dispatch(changePreloaderStatus(false));
        swal("Error", "", "error");
        this.setState((prev) => {
          let st = prev;
          st.penaltyData = 0;
          return st;
        });
      }
    );
  };
  getBookingPenalty = (code) => {
    doGetBookingPenalty(code).then((res) => {
      // console.log(res, "Res");
      this.setState((prev) => {
        let st = prev;
        st.penaltyArray = res.data;
        return st;
      });
    });
    // console.log("asda", data);
  };
  getActivityName = (status) => {
    let data;
    switch (status.toLowerCase()) {
      case "booking_completed":
        data = "Booking Completed";
        break;
      case "booking_cancelled":
        data = "Booking Cancelled";
        break;
      case "booking_created":
        data = "Booking Created";
        break;
      case "booking_assigned":
        data = "Booking Assigned";
        break;
      case "technician_started":
        data = "Technician Started";
        break;
      case "technician_reached":
        data = "Technician Reached";
        break;
      case "quotation_created":
        data = "Quotation Created";
        break;
      case "quotation_approved":
        data = "Quotation Approved";
        break;
      case "quotation_rejected":
        data = "Quotation Rejected";
        break;
      case "payment_completed":
        data = "Payment Completed";
    }
    return data;
  };
  getBillingDetailsById = (id, code) => {
    let query = `bookingId=${id}`;
    doGetBillingDetails(query).then((res) => {
      this.setState((prev) => {
        let st = prev;
        st.billingDetails = res.data;
        return st;
      });
      doGetPaymentsById(code).then((res1) => {
        this.setState((prev) => {
          let st = prev;
          st.booking.payments = res1.data;
          return st;
        });
        // console.log(res1.data, "res1");
      });
      // console.log(res.data, "Billing Details");
    });
  };

  render() {
    let amount = 0;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title={"Booking ID : " + this.state.booking.id} breadcrumbItem="Booking Overview" />

            <Row>
              <Col lg="8">
                <Card>
                  <CardBody>
                    <Media>
                      {/* {console.log(this.state.booking, "Booking")} */}
                      <img
                        src={this.state.booking.createdBy.profilePic.url}
                        alt=""
                        className="avatar-sm mr-4"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = img1;
                        }}
                      />
                      <Media className="overflow-hidden mainServiceContainer" body>
                        <div>
                          <h5 className="text-truncate font-size-15">{this.state.booking.createdBy.getFullName()}</h5>
                          <h5 className="text-truncate font-size-15">{"Phone: " + this.state.booking.createdBy.mobile}</h5>
                          <p className="text-muted">{this.state.booking.service.name}</p>
                        </div>
                        <div>
                          <div className="btnContainer">
                            <Button className="rescheduleBtn" color="primary" onClick={() => this.setReschedulePopUp(true)}>
                              Re-Schedule
                            </Button>
                            {/* <Button
                              className="rescheduleBtn"
                              color="info"
                              onClick={() => this.setRevisitPopUp(true)}
                            >
                              Re-Visit
                            </Button> */}
                            <Button className="rescheduleBtn" color="danger" onClick={() => this.setCancelPopUp(true)}>
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </Media>
                    </Media>
                    <div className="bookingDetailsContainer">
                      <div>
                        <h5 className="font-size-15 mt-4">Problem :</h5>

                        <p className="text-muted">{this.state.booking.problem}</p>
                      </div>
                      <div>
                        <h5 className="font-size-15 mt-4">Address :</h5>
                        <p>
                          {this.state.booking.address.flat +
                            " , " +
                            this.state.booking.address.addressline1 +
                            " , " +
                            this.state.booking.address.street +
                            " , " +
                            this.state.booking.address.city}
                        </p>
                      </div>
                    </div>

                    <Row className="task-dates">
                      <Col sm="4" xs="4">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar mr-1 text-primary"></i> Booking Date
                          </h5>
                          <p className="text-muted mb-0">{this.state.bookingDate}</p>
                        </div>
                      </Col>

                      <Col sm="4" xs="4">
                        <div className="mt-4">
                          <h5 className="font-size-14">
                            <i className="bx bx-calendar-check mr-1 text-primary"></i> Booking Slot
                          </h5>
                          <p className="text-muted mb-0">{this.state.bookingSlot}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              {this.state.booking.assignedTo.firstName === "" || this.state.booking.assignedTo.LastName === "" ? (
                ""
              ) : (
                <Col lg="4">
                  <Card className="overflow-hidden">
                    <CardHeader className="bg-soft-primary">
                      <CardTitle>Technician Details</CardTitle>
                    </CardHeader>
                    <CardBody className="pt-2">
                      <Row>
                        <Col sm="4">
                          <div className="avatar-md profile-user-wid">
                            <img
                              src={this.state.booking.assignedTo.profilePic.url}
                              // onError={avatar2}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = avatar2;
                              }}
                              alt=""
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </Col>

                        <Col sm="8">
                          <div className="pt-0">
                            <h5 className="font-size-15">{this.state.booking.assignedTo.getFullName()}</h5>

                            <h6>
                              <i className="mdi mdi-email"></i>
                              {"  "}
                              {this.state.booking.assignedTo.email}
                            </h6>
                            <h6>
                              <i className="mdi mdi-cellphone"></i>
                              {"  "}
                              {this.state.booking.assignedTo.mobile}
                            </h6>
                          </div>
                          <div>
                            <i className="mdi mdi-map-marker"></i>
                            {"  "}
                            {this.state.booking.assignedTo.addresses.length > 0
                              ? this.state.booking.assignedTo.addresses[0].city
                              : ""}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>

            <Row>
              <Col lg="4">
                <Card>
                  <CardBody>
                    <CardTitle>Activity</CardTitle>
                    {this.state.bookingStatusMaps.map((statusMap, index) => {
                      if (statusMap.bookingstatusid === null) return <></>;
                      return (
                        <ul className="verti-timeline list-unstyled">
                          <li className="event-list createdAt">
                            <div className="event-timeline-dot">
                              {index === 0 ? (
                                <i className="bx text-danger bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
                              ) : (
                                <i className="bx bx-right-arrow-circle font-size-18"></i>
                              )}
                            </div>
                            <Media>
                              <div className="mr-3">
                                <p>
                                  {/* {statusMap.bookingstatusid.name.toLowerCase() ===
                                  "booking_assigned"
                                    ? "BOOKING ASSIGNED"
                                    : statusMap.bookingstatusid.name.toLowerCase() ===
                                      "booking_created"
                                    ? "BOOKING CREATED"
                                    : ""} */}
                                  {this.getActivityName(statusMap.bookingstatusid.name)}
                                  <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ml-2"></i>
                                </p>
                              </div>
                              <Media body>
                                <h5 className="font-size-12">
                                  <div className="updationDetails">
                                    <div>
                                      {statusMap.updatedby === null ? (
                                        <h6>No Updation Found</h6>
                                      ) : (
                                        <h6>{statusMap.updatedby.username}</h6>
                                      )}
                                    </div>
                                    <div>
                                      <p className="activityDate">
                                        {moment(statusMap.created_at).utc().format("DD MMM YYYY HH:mm:ss")}
                                      </p>
                                    </div>
                                  </div>
                                </h5>
                              </Media>
                            </Media>
                          </li>
                        </ul>
                      );
                    })}
                  </CardBody>
                </Card>

                {/* {this.state.otherBookings.length > 0 ? (
                  <>
                    <Card>
                      <CardBody>
                        <CardTitle>Other Bookings</CardTitle>
                        <Table
                          striped
                          bordered
                          hover
                          className="table mb-3 pr-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          <thead>
                            <tr>
                              <th>Sno</th>
                              <th>BookingId</th>
                              <th>Service</th>
                              <th>From Time</th>
                              <th>To Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.bookings &&
                              this.this.state.bookings.map((booking, index) => {
                                return (
                                  <tr>
                                    <td className="center-field">
                                      {index + 1}
                                    </td>
                                    <td className="center-field">
                                      {booking.code}
                                    </td>
                                    <td className="center-field">
                                      {booking.service.name}
                                    </td>
                                    <td>
                                      {moment(booking.fromTime)
                                        .utc()
                                        .format("DD MMM YYYY HH:mm:ss")}
                                    </td>
                                    <td>
                                      {moment(booking.toTime)
                                        .utc()
                                        .format("DD MMM YYYY HH:mm:ss")}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                  </>
                ) : (
                  <></>
                )} */}
              </Col>

              <Col lg="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Billing Details</CardTitle>
                    <div className="NotFoundIcon">
                      <Table>
                        <tr>
                          <th>Type</th>
                          <th>Technician Cost</th>
                          <th>Homvery Cost</th>
                          <th>Total</th>
                        </tr>
                        {this.state.billingDetails.length > 0 ? (
                          this.state.billingDetails.map((bill, i) => {
                            amount += bill.cost;
                            return (
                              <tr key={i}>
                                <td>{bill.type}</td>
                                <td>{bill.technician_cost}</td>
                                <td>{bill.homvery_cost}</td>
                                <td>{bill.cost}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <>
                            {" "}
                            <i className="bx bx bx-receipt bx-md"></i>
                            <h4 style={{ color: "#C7C7CD" }}>No Billing Info Found</h4>
                          </>
                        )}
                        {amount > 0 ? (
                          <tr>
                            <td colSpan={2}></td>
                            <th>Amount: </th>
                            <td>{amount}</td>
                          </tr>
                        ) : (
                          ""
                        )}

                        <tr>
                          <th colSpan={4}>Payments</th>
                        </tr>
                        {this.state.booking.payments &&
                        Array.isArray(this.state.booking.payments) &&
                        this.state.booking.payments.length > 0 ? (
                          <tr>
                            <td>{this.state.booking.payments[0].type}</td>
                            <td>{this.state.booking.payments[0].amount}</td>
                          </tr>
                        ) : (
                          <tr>
                            <td>No Payments Found</td>
                          </tr>
                        )}
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="4">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Reviews</CardTitle>
                    {this.state.booking.review.id === 0 ? (
                      <div className="NotFoundIcon">
                        <i className="mdi mdi-newspaper-variant-outline mdi-36px"></i>
                        <h4>Review Not Found</h4>
                        <Button onClick={this.toggleReviewModal} color="info">
                          <i className="fas fa-plus"></i>
                        </Button>
                      </div>
                    ) : (
                      <Media className="mb-4">
                        <div className="mr-3">
                          <img className="media-object rounded-circle avatar-xs" alt="" src={avatar4} />
                        </div>
                        <Media body>
                          <div className="reviewHeader">
                            <div>
                              <h5 className="font-size-13 mb-1">{this.state.booking.createdBy.getFullName()}</h5>
                              <p className="text-muted mb-1">{this.state.booking.review.comments}</p>
                            </div>
                            <div>
                              {/* TODO: Review Stars */}
                              {/* Commented By Nilesh - 21/09/2024 */}
                              {/* <RatingTooltip
                                max={5}
                                ActiveComponent={
                                  <i
                                    key={"active_1"}
                                    className="mdi mdi-star text-primary"
                                  />
                                }
                                InActiveComponent={
                                  <i
                                    key={"active_01"}
                                    className="mdi mdi-star-outline text-muted"
                                  />
                                }
                                disabled
                                defaultRating={this.state.booking.review.rating}
                              /> */}
                            </div>
                          </div>
                        </Media>
                      </Media>
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Dispute</CardTitle>
                    {this.state.booking.dispute.id === 0 ? (
                      <div className="NotFoundIcon">
                        <i className="far fa-sticky-note fa-2x"></i>
                        <h4>No Dispute Info Found</h4>
                        <Button onClick={this.toggleDisputeModal} color="info">
                          <i className="fas fa-plus"></i>
                        </Button>
                      </div>
                    ) : (
                      <Media className="mb-4">
                        <Media body className="text-center">
                          <h4 className="mb-1">
                            {this.state.booking.dispute.problem}
                            <span className="font-size-16 ml-2 font-weight-bold">({this.state.booking.dispute.status})</span>
                          </h4>

                          <p className="text-muted mb-1">{this.state.booking.dispute.description}</p>
                          <Button onClick={this.toggleDisputeModal} color="info">
                            <i className="fas fa-edit"></i>
                          </Button>
                        </Media>
                      </Media>
                    )}
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Penalty</CardTitle>
                    {this.state.penaltyArray.length > 0
                      ? this.state.penaltyArray.map((penalty, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                textAlign: "left",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <span> {moment(penalty.created_at).utc().format("DD MMM YYYY HH:mm:ss")}</span>
                              <span>{penalty.createdby.username}</span>
                              <span>
                                {" "}
                                {" Amount "}
                                {penalty.amount}
                              </span>
                            </div>
                          );
                        })
                      : ""}
                    <div className="NotFoundIcon" style={{ marginTop: "20px" }}>
                      {/* <i className="far fa-sticky-note fa-2x"></i> */}

                      <h5>Enter the Penalty Amount</h5>

                      <input
                        type={"number"}
                        className="form-control"
                        placeholder="Enter the Penalty Amount"
                        value={this.state.penaltyData}
                        style={{ textAlign: "center", margin: "20px" }}
                        onChange={(e) => this.handlePenaltyChange(e.target.value)}
                      />
                      <Button onClick={this.togglePenaltyModal} color="info">
                        <i className="fas fa-check-square"></i>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div>
              <Modal isOpen={this.state.disputeModal} toggle={this.toggleDisputeModal}>
                <ModalHeader>Raise A Dispute</ModalHeader>
                <Form onSubmit={this.disputeSubmit}>
                  <ModalBody>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>Problem</label>
                          <Input
                            required
                            type="text"
                            className="form-control"
                            name="problem"
                            value={this.state.disputeData.problem}
                            onChange={this.onDisputeFormChange}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>Status</label>
                          <Select
                            value={{
                              label: this.state.disputeData.status,
                              value: this.state.disputeData.status,
                            }}
                            isMulti={false}
                            onChange={(e) =>
                              this.onDisputeFormChange({
                                target: {
                                  name: "status",
                                  value: e.value,
                                },
                              })
                            }
                            options={statuses.map((s) => ({
                              label: s,
                              value: s,
                            }))}
                            classNamePrefix="select2-selection"
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>Description</label>
                          <Input
                            required
                            type="textarea"
                            className="form-control"
                            name="description"
                            value={this.state.disputeData.description}
                            onChange={this.onDisputeFormChange}
                          />
                        </div>
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button type="submit" color="success">
                      {this.state.disputeData.id === 0 ? "Save" : "Update"}
                    </Button>
                    <Button type="button" onClick={this.toggleDisputeModal} color="danger">
                      Cancel
                    </Button>
                  </ModalFooter>
                </Form>
              </Modal>
            </div>
          </Container>
          <Modal toggle={this.toggleReviewModal} isOpen={this.state.reviewModal}>
            <ModalHeader>Add A Review</ModalHeader>
            <Form onSubmit={this.reviewSubmit}>
              <ModalBody>
                <Row>
                  <Col lg={6}>
                    <div className="form-group star-group">
                      <label>Rating</label>
                      {/* Commented By Nilesh - 21/09/2024 */}
                      {/* <RatingTooltip
                        max={5}
                        onChange={(rate) => {
                          this.onReviewFormChange({
                            target: { name: "rating", value: rate },
                          });
                        }}
                        ActiveComponent={<i key={"active_1"} className="mdi mdi-star text-primary" />}
                        InActiveComponent={<i key={"active_01"} className="mdi mdi-star-outline text-muted" />}
                        disabled={this.state.reviewData.id !== 0}
                        defaultRating={this.state.reviewData.rating}
                      /> */}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group star-group">
                      <label>Comments</label>
                      <Input
                        type="textarea"
                        value={this.state.reviewData.comments}
                        name="comments"
                        onChange={this.onReviewFormChange}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group star-group">
                      <label className="d-block">TShirt</label>
                      <ReactSwitch
                        uncheckedIcon={<Offsymbol />}
                        className="mr-1"
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={(e) =>
                          this.onReviewFormChange({
                            target: {
                              name: "tshirt",
                              value: !this.state.reviewData.tshirt,
                            },
                          })
                        }
                        checked={this.state.reviewData.tshirt}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group star-group">
                      <label className="d-block">Greeted Customer</label>
                      <ReactSwitch
                        uncheckedIcon={<Offsymbol />}
                        className="mr-1"
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={(e) =>
                          this.onReviewFormChange({
                            target: {
                              name: "greetedCustomer",
                              value: !this.state.reviewData.greetedCustomer,
                            },
                          })
                        }
                        checked={this.state.reviewData.greetedCustomer}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group star-group">
                      <label className="d-block">Cleaned WorkSpace</label>
                      <ReactSwitch
                        uncheckedIcon={<Offsymbol />}
                        className="mr-1"
                        checkedIcon={<OnSymbol />}
                        onColor="#626ed4"
                        onChange={(e) =>
                          this.onReviewFormChange({
                            target: {
                              name: "cleanedWorkplace",
                              value: !this.state.reviewData.cleanedWorkplace,
                            },
                          })
                        }
                        checked={this.state.reviewData.cleanedWorkplace}
                      />
                    </div>
                  </Col>
                  {this.state.reviewData.id === 0 ? (
                    <>
                      <Col lg={6}>
                        <div className="form-group">
                          <label>Images</label>
                          <Input
                            type="file"
                            multiple
                            name="images"
                            key={"review" + this.state.reviewFileCount}
                            onChange={this.onReviewFormChange}
                          />
                        </div>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col lg={12}>
                    <div className="form-group">
                      {this.state.reviewData.images.map((image) => {
                        return <img className="img-fluid mr-2" width="100" src={getImageUrl(image.url)} alt="thumbnail" />;
                      })}
                    </div>
                  </Col>
                </Row>
              </ModalBody>

              <ModalFooter>
                {this.state.reviewData.id === 0 ? (
                  <>
                    <Button type="submit" color="success">
                      {this.state.reviewData.id === 0 ? "Save" : "Update"}
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                <Button type="button" onClick={this.toggleReviewModal} color="danger">
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>

          <Modal isOpen={this.state.cancelModal.open} backdrop="static" toggle={() => this.setCancelPopUp(false)}>
            <ModalHeader toggle={() => this.setCancelPopUp(false)}>
              Cancellation for Booking {this.state.booking.code}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Comments</Label>
                    <Input
                      type="textarea"
                      value={this.state.cancelModal.comments}
                      onChange={(e) => this.onHandleCancelPopup(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Button onClick={this.onCancelBooking}>Submit</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.penaltyModal} toggle={this.togglePenaltyModal}>
            <ModalHeader>Raise A Penalty</ModalHeader>
            <Form onSubmit={this.handlePostPenalty}>
              <ModalBody>
                <Row>
                  <Col lg={12}>
                    <div className="form-group">
                      <label>Are you sure to charge a penalty of {this.state.penaltyData} ?</label>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="success">
                  Submit
                </Button>
                <Button type="button" onClick={this.togglePenaltyModal} color="danger">
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
          <Modal isOpen={this.state.revisitModal.open} backdrop="static" toggle={() => this.setRevisitPopUp(false)}>
            <ModalHeader toggle={() => this.setRevisitPopUp(false)}>Revist for Booking {this.state.booking.code}</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Booking Date</Label>
                    <InputGroup>
                      <DatePicker
                        selected={this.state.revisitModal.date}
                        className="form-control"
                        onChange={(e) =>
                          this.onRevisitChange({
                            target: { name: "bookingDate", value: e },
                          })
                        }
                        showYearDropdown
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Booking Slot</Label>
                    <Select
                      name="bookingStatus"
                      value={{
                        label: this.state.revisitModal.slot,
                        value: this.state.revisitModal.slot,
                      }}
                      isMulti={false}
                      onChange={(e) =>
                        this.onRevisitChange({
                          target: {
                            name: "bookingSlot",
                            value: e.value,
                          },
                        })
                      }
                      options={bookingSlots.map((s) => ({
                        label: s,
                        value: s,
                      }))}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Comments</Label>
                    <Input
                      type="textarea"
                      name="comments"
                      value={this.state.revisitModal.comments}
                      onChange={this.onRevisitChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Button onClick={this.onRevisitBooking}>Submit</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.rescheduleModal.open} backdrop="static" toggle={() => this.setReschedulePopUp(false)}>
            <ModalHeader toggle={() => this.setReschedulePopUp(false)}>
              Reschedule for Booking {this.state.booking.code}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <FormGroup>
                    <Label>Booking Date</Label>
                    <InputGroup>
                      <DatePicker
                        selected={this.state.rescheduleModal.date}
                        className="form-control"
                        onChange={(e) =>
                          this.onRescheduleChange({
                            target: { name: "bookingDate", value: e },
                          })
                        }
                        showYearDropdown
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Booking Slot</Label>
                    <Select
                      name="bookingStatus"
                      value={{
                        label: this.state.rescheduleModal.slot,
                        value: this.state.rescheduleModal.slot,
                      }}
                      isMulti={false}
                      onChange={(e) =>
                        this.onRescheduleChange({
                          target: {
                            name: "bookingSlot",
                            value: e.value,
                          },
                        })
                      }
                      options={bookingSlots.map((s) => ({
                        label: s,
                        value: s,
                      }))}
                      classNamePrefix="select2-selection"
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup>
                    <Label>Comments</Label>
                    <Input
                      type="textarea"
                      name="comments"
                      value={this.state.rescheduleModal.comments}
                      onChange={this.onRescheduleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <Button onClick={this.onRescheduleBooking}>Submit</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(BookingOverview);
