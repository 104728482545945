import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { logoutUser } from '../../store/actions';
import { doGetAuthUpdateSession } from '../../services/authServices';

class CheckSession extends Component {
    /**
     * Redirect to login
     */

    constructor(props) {
		super(props);
		this.state = {  };
		this.interval=null;
	}

    componentWillReceiveProps(nextProps){
		if(this.props.login.login_stats!==nextProps.login.login_stats){
			if(nextProps.login.login_stats){
				if(this.interval!==null){
					clearInterval(this.interval)
				}
				this.interval=setInterval(this.updateSession,20000);
			}else{
				if(this.interval!==null){
					clearInterval(this.interval)
				}
			}
		}
	}

	updateSession=()=>{
		// doGetAuthUpdateSession().then((res)=>{

		// },(err)=>{
		// 	if(this.interval!==null)
		// 	clearInterval(this.interval)
		// 	this.props.logoutUser(this.props.history);
		// })
	}
 
    render() {
        return <React.Fragment></React.Fragment>;
    }
}

const mapStateToProps = state => {
	return {
		layout: state.Layout,
		login:state.Login
	};
};
export default withRouter(
    connect(
        mapStateToProps,
        { logoutUser }
    )(CheckSession)
);
