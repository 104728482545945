import { CategoryModal } from "../../classes/classes"
import { getValidatedData } from "../constants";


export function getConvertedCategories(categories=[],needOnlyActive=false){
    const data=categories.filter((k)=>{
      return Object.keys(k).length>0
    }).map((category)=>{
        const id=getValidatedData(category.id,'number');
        const name=getValidatedData(category.name);
        const description=getValidatedData(category.description);
        const active=getValidatedData(category.active,'boolean');
        return new CategoryModal(id,name,description,active);
    })
    if(needOnlyActive)
        return data.filter((pincode)=>pincode.active);
        return data;
}