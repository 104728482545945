import React, { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Table, Button } from "reactstrap";
import {
  doDeleteOffer,
  doGetAllOffers,
  doGetAllOffersCount,
} from "../../../services/apiServices";
import moment from "moment";
import swal from "sweetalert";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
import { offerFilterConfig } from "./OfferFilterConfig";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { connect } from "react-redux";

class Offers extends Component {
  state = {
    bookings: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
    filterQuery: "",
    offers: [],
  };
 
  getOffers = (currentPageNo, limit, filterQuery = this.state.filterQuery) => {
    let query = `_limit=${limit}&_start=${
      (currentPageNo*limit) + (filterQuery !== "" ? "&" + filterQuery : "")
    }`;
    doGetAllOffersCount(filterQuery !== "" ? "&" + filterQuery : "").then(
      (res1) => {
        // console.log(res1.data);
        this.setState((prev) => {
          let st = prev;
          st.pagination.totalPages = Math.ceil(res1.data / st.pagination.limit);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
        doGetAllOffers(query).then(
          (res) => {
            this.setState((prev) => {
              let st = prev;
              st.offers = res.data;
              return st;
            });
          },
          (err) => {}
        );
      }
    );
  };
  componentDidMount() {
    this.getOffers(0, this.state.pagination.limit);
  }
  handleOfferDelete = (id) => {
    swal({
      title: "Are you sure to delete?",
      text: 'You will not be able to recover data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doDeleteOffer(id).then(
          (res) => {
            swal("Success", "Offer Deleted Successfully", "success");
            this.props.dispatch(changePreloaderStatus(false));
            this.getOffers();
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
    
  };

  handleEditOffer = (id) => {
    // history.push("/offers/edit/" + id);
  };
  doPagination = (currentPageNo) => {
    this.getOffers(currentPageNo, this.state.pagination.limit);
  };

  dofilterData = (query = "") => {
    this.getOffers(0, this.state.pagination.limit, query);
  };

  render() {
    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Offers</h4>
          <span>
            <Link
              to="/offers/add"
              style={{ padding: "7px" }}
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              Add New Offer <i className="mdi mdi-arrow-right ml-1"></i>
            </Link>
          </span>
        </div>

        <Row>
          <Col md={12}>
            <FilterView
              doFilter={this.dofilterData}
              filterOptions={offerFilterConfig.filters}
            />
          </Col>
          <Col md={12}>
            <Table
              striped
              bordered
              hover
              className="table mb-3 pr-0"
              style={{ verticalAlign: "middle" }}
            >
              <tr>
                <th>Name</th>
                <th>City</th>
                <th>Service</th>
                <th>Percentage</th>
                <th>Minimum Amount</th>
                <th>From Date</th>
                <th>To Date</th>
                <th>New User</th>
              </tr>
              {this.state.offers.map((offer, i) => {
                console.log(offer.newuser);
                return (
                  <tr key={i}>
                    <td>{offer.name}</td>
                    <td>{offer.city.name}</td>
                    <td>{offer.service.name}</td>
                    <td>{offer.percentage}</td>
                    <td>{offer.min}</td>
                    {/* {console.log("offer.fromdate", offer.fromDate)} */}
                    <td>
                      {moment(offer.fromDate).format("DD MMM YYYY hh:mm:ss a")}
                    </td>
                    <td>
                      {moment(offer.toDate).format("DD MMM YYYY hh:mm:ss a")}
                    </td>
                    <td>{offer.newuser ? "true" : "false"}</td>

                    <td>
                      <Link to={`offers/edit/${offer.id}`}>
                        {" "}
                        <Button>
                          <i className="fas fa-edit"></i>
                        </Button>
                      </Link>
                      {"    "}
                      <Button
                        color="danger"
                        onClick={() => this.handleOfferDelete(offer.id)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </Table>
          </Col>
          <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(Offers);