import { BASE_URL } from "./constants";

/* Authorization */
export const POST_AUTH_LOGIN_URL = BASE_URL + "/auth/local";
export const GET_AUTH_PROFILE_URL = BASE_URL + "/users/me";

/* Users */
export const GET_USERS_ALL_URL = BASE_URL + "/users";
export const POST_CREATE_USER_URL = BASE_URL + "/auth/local/register";

export const POST_NEW_USER_URL = BASE_URL + "/registerUser";

export const POST_NEW_TECHNICIAN_URL = BASE_URL + "/registerPartner";

/* User Roles */
export const GET_USER_ROLES_URL = BASE_URL + "/users-permissions/roles";

/* Locations */
export const GET_LOCATIONS_ALL_URL = BASE_URL + "/locations";

/* Categories */
export const GET_CATEGORIES_ALL_URL = BASE_URL + "/categories";

/* Services */
export const GET_SERVICES_ALL_URL = BASE_URL + "/services";

/* Faq */
export const GET_FAQS_ALL_URL = BASE_URL + "/faqs";

/* Seo */
export const GET_SEOS_ALL_URL = BASE_URL + "/seos";

/* Upload Files */
export const POST_UPLOAD_FILES_URL = BASE_URL + "/upload";

/* Techincial Details */
export const GET_TECHINICIAN_DETAILS_ALL_URL = BASE_URL + "/techniciandetails";
export const GET_TECHINICIAN_DETAILS_TECH_URL = BASE_URL + "/technician/get";
export const POST_TECHINICIAN_DETAILS_TECH_URL = BASE_URL + "/technician/save";
export const PUT_TECHINICIAN_DETAILS_TECH_URL = BASE_URL + "/technician/update";

/* Bookings */
export const GET_BOOKINGS_ALL_URL = BASE_URL + "/bookings";
export const POST_BOOKING_URL = BASE_URL + "/saveBooking";

/* Bookings Status Map */
export const GET_BOOKINGSTATUSMAP_ALL_URL = BASE_URL + "/bookingstatusmaps";

/* Bookings Status  */
export const GET_BOOKINGSTATUS_ALL_URL = BASE_URL + "/bookingstatuses";

// Inventory Add Item
export const INVENTORY_ADD_ITEM = BASE_URL + "/inventories";

/* Update Booking Assignment Technician  */
export const PUT_ASSIGN_BOOKING_URL = BASE_URL + "/booking/assignBooking";

/* Update Booking Cancel  */
export const PUT_CANCEL_BOOKING_URL = BASE_URL + "/booking/cancelBooking";

/* Update Booking Revisit  */
export const PUT_REVISIT_BOOKING_URL = BASE_URL + "/booking/revisitBooking";

/* Update Booking Reschedule  */
export const PUT_RESCHEDULE_BOOKING_URL =
  BASE_URL + "/booking/rescheduleBooking";

/* GET ELIGIBLE Technicians for Booking  */
export const GET_ELIGIBLE_TECHNICIANS_URL =
  BASE_URL + "/booking/eligibleTechnicians";

/* GET ALL DISPUTES  */
export const GET_ALL_DISPUTES_URL = BASE_URL + "/disputes";

/* GET ALL Review  */
export const GET_ALL_REVIEWS_URL = BASE_URL + "/reviews";

/* GET ALL SITESETTINGS  */
export const GET_ALL_SITESETTINGS_URL = BASE_URL + "/settings";

// PENALTY SECTION
export const PUT_BOOKING_PENALTY_URL = BASE_URL + "/booking/penalty";

export const GET_BOOKING_PENALTY_URL =
  BASE_URL + "/transactions?details_contains";

export const GET_OFFERS_URL = BASE_URL + "/offers";
export const GET_OFFER_URL = BASE_URL + "/offer";

export const GET_INCOMPLETE_TRANSACTIONS_URL =
  BASE_URL + "/transactions?isCompleted=false";

export const PUT_COMPLETE_PAYOUT = BASE_URL + "/complete/payout";

export const GET_WALLETS_URL = BASE_URL + "/wallets";

export const GET_TRANSACTIONS_URL = BASE_URL + "/transactions";

export const GET_SLIDERS_URL = BASE_URL + "/sliders";

export const GET_PAYMENTS_URL = BASE_URL + "/payments";

export const GET_BILLING_DETAILS_URL = BASE_URL + "/billingdetails";

export const GET_CONTACTS_URL = BASE_URL + "/contacts";
