import { ImageModal } from "../../classes/classes"
import { getValidatedData } from "../constants"

export function getConvertedImages(images=[]){

    return images.filter((k)=>{
      return k && Object.keys(k).length > 0;
    }).map((image)=>{

        const id=getValidatedData(image.id,'number')
        const name=getValidatedData(image.name)
        const ext=getValidatedData(image.ext)
        const url=getValidatedData(image.url)
        const size=getValidatedData(image.size,'number')
        
        return new ImageModal(id,name,url,ext,size);
    })

}