import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Button,
  Card,
  CardBody,
  UncontrolledCollapse,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import {
  convertToFormData,
  decimalregexp,
  getExt,
  getImageUrl,
  getValidatedData,
  isNumeric,
  numbersregexp,
} from "../../../constants/constants";
import { changePreloaderStatus } from "../../../store/layout/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import swal from "sweetalert";

//i18n
import { withNamespaces } from "react-i18next";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import {
  FaqModal,
  ServiceLocation,
  ServiceLocationPrice,
  ServiceModal,
  ServiceDetailsModal,
  ImageModal,
} from "../../../classes/classes";
import {
  doGetAllCategories,
  doGetAllFaqs,
  doGetAllLocations,
  doGetAllSeos,
  doGetAllServices,
  doPostAddService,
  doPostUploadFiles,
  doPutUpdateService,
} from "../../../services/apiServices";
import { getConvertedServices } from "../../../constants/conversions/services";
import { getConvertedCategories } from "../../../constants/conversions/categories";
import { getConvertedFaqs } from "../../../constants/conversions/faqs";
import { getConvertedSEOS } from "../../../constants/conversions/seo";
import classnames from "classnames";
import { Link } from "react-router-dom";

const animatedComponents = makeAnimated();

const servicePriceTypes = ["VISITINGCHARGE", "SERVICECHARGE"];
const restrictedext = ["png", "jpg", "jpeg", "webp"];

class AddService extends Component {
  state = {
    action: "",
    activeTab: 1,
    service: new ServiceModal(),
    imagesSelected: [],
    imagesFileCount: 0,
    websiteImagesSelected: [],
    websiteImagesFileCount: 0,
    detailsImages: [],
    displayPicSelected: {
      name: "files",
      value: "",
      filename: "",
    },
    displayPicCount: 0,
    seoImageSelected: {
      name: "files",
      value: "",
      filename: "",
    },
    seoFileCount: 0,
    breadcrumbItems: [],
    categories: [],
    cities: [],
  };

  getCategories = () => {
    doGetAllCategories().then(
      (res) => {
        this.setState({
          categories: getConvertedCategories(
            getValidatedData(res.data, "array"),
            true
          ),
        });
      },
      (err) => {
        this.setState({
          categories: [],
        });
      }
    );
  };

  getCities = () => {
    doGetAllLocations().then(
      (res) => {
        this.setState({
          cities: getConvertedLocations(
            getValidatedData(res.data, "array"),
            true
          ),
        });
      },
      (err) => {
        this.setState({
          cities: [],
        });
      }
    );
  };

  componentDidMount() {
    this.getCategories();
    this.getCities();
    const queryPath = this.props.match.params;
    this.initData(queryPath.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        service: new ServiceModal(),
        imagesSelected: [],
        websiteImagesSelected: [],
        imagesFileCount: this.state.imagesFileCount + 1,
        websiteImagesFileCount: this.state.websiteImagesFileCount + 1,
        activeTab: 1,
        seoImageSelected: {
          name: "files",
          value: "",
          filename: "",
        },
        seoFileCount: this.state.seoFileCount + 1,
        breadcrumbItems: [
          {
            active: true,
            route: "/services/all",
            title: "Services",
          },
          {
            active: true,
            route: "#",
            title: "New Service",
          },
        ],
      });
    } else {
      this.getServiceData(id);
    }
  };

  getServiceData = (id) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllServices(id).then(
      (res) => {
        // console.log(res.data);
        this.props.dispatch(changePreloaderStatus(false));
        const services = getConvertedServices([
          getValidatedData(res.data, "object"),
        ]);
        // console.log(services[0]);
        this.setState({
          action: "edit",
          service: services[0],
          imagesSelected: [],
          websiteImagesSelected: [],
          activeTab: 1,
          imagesFileCount: this.state.imagesFileCount + 1,
          websiteImagesFileCount: this.state.websiteImagesFileCount + 1,
          seoImageSelected: {
            name: "files",
            value: "",
            filename: "",
          },
          seoFileCount: this.seoFileCount + 1,
          breadcrumbItems: [
            {
              active: true,
              route: "/services/all",
              title: "Services",
            },
            {
              active: true,
              route: "/services/" + id,
              title: services[0].name,
            },
          ],
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          action: "add",
          pincode: "",
          location: new ServiceModal(),
          imagesSelected: [],
          websiteImagesSelected: [],
          activeTab: 1,
          imagesFileCount: this.state.imagesFileCount + 1,
          websiteImagesFileCount: this.state.websiteImagesFileCount + 1,
          seoImageSelected: {
            name: "files",
            value: "",
            filename: "",
          },
          seoFileCount: this.seoFileCount + 1,
          breadcrumbItems: [
            {
              active: true,
              route: "/services/all",
              title: "Services",
            },
            {
              active: true,
              route: "#",
              title: "New Service",
            },
          ],
        });
      }
    );
  };

  deleteDisplayPic = () => {
    this.setState((prev) => {
      let st = prev;
      st.service.displayPic = new ImageModal();
      return st;
    });
  };

  onHandleChange = (e) => {
    console.log("e", e.target.value);
    const { name, value, files } = e.target;
    switch (name) {
      case "metatitle":
        this.setState((prev) => {
          let st = prev;
          st.service.seo.title = value;
          return st;
        });
        break;
      case "metadescription":
        this.setState((prev) => {
          let st = prev;
          st.service.seo.description = value;
          return st;
        });
        break;
      case "metakeywords":
        this.setState((prev) => {
          let st = prev;
          st.service.seo.keywords = value;
          return st;
        });
        break;
      case "name":
        this.setState((prev) => {
          let st = prev;
          st.service.name = value;
          return st;
        });
        break;
      case "keywords":
        this.setState((prev) => {
          let st = prev;
          st.service.keywords = value;
          return st;
        });
        break;
      case "problem":
        this.setState((prev) => {
          let st = prev;
          st.service.problem = value;
          return st;
        });
        break;
      case "details":
        this.setState((prev) => {
          let st = prev;
          st.service.details = value;
          return st;
        });
        break;
      case "category":
        this.setState((prev) => {
          let st = prev;
          const selectedCategories = st.categories.filter(
            (category) => category.id === value
          );
          if (selectedCategories.length > 0)
            st.service.category = selectedCategories[0];
          return st;
        });
        break;
      case "displayPic":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.displayPicSelected = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.displayPicSelected = {
              name: "files",
              value: "",
              filename: "",
            };
            st.displayPicCount++;
            return st;
          });
        }

        break;
      case "metathumbnail":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.seoImageSelected = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.seoImageSelected = {
              name: "files",
              value: "",
              filename: "",
            };
            st.seoImageCount++;
            return st;
          });
        }

        break;
      case "files":
        //console.log(e.target);
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          imagesSelected = imagesSelected.filter((file, pos) => {
            const index = imagesSelected.findIndex(
              (item) => item.filename === file.filename
            );
            return index === pos;
          });
          this.setState((prev) => {
            let st = prev;
            st.imagesSelected = [...imagesSelected];
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.imagesSelected = [];
            st.imagesFileCount++;
            return st;
          });
        }

        break;
      case "websitefiles":
        //console.log(e.target);
        if (files.length > 0) {
          let websiteImagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              websiteImagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          websiteImagesSelected = websiteImagesSelected.filter((file, pos) => {
            const index = websiteImagesSelected.findIndex(
              (item) => item.filename === file.filename
            );
            return index === pos;
          });
          this.setState((prev) => {
            let st = prev;
            st.websiteImagesSelected = [...websiteImagesSelected];
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.websiteImagesSelected = [];
            st.websiteImagesFileCount++;
            return st;
          });
        }
        break;
      default:
    }
  };

  onHandleFaqChange = (e, index) => {
    const { name, value } = e.target;
    switch (name) {
      case "faqtitle":
        this.setState((prev) => {
          let st = prev;
          st.service.faqs[index].title = value;
          return st;
        });
        break;
      case "faqdescription":
        this.setState((prev) => {
          let st = prev;
          st.service.faqs[index].description = value;
          return st;
        });
        break;
      case "faqtype":
        this.setState((prev) => {
          let st = prev;
          st.service.faqs[index].type = value;
          return st;
        });
        break;
      case "faqstatus":
        this.setState((prev) => {
          let st = prev;
          st.service.faqs[index].active = value;
          return st;
        });
        break;
      default:
    }
  };

  onHandleServiceLocations = (e, index, index1) => {
    const { name, value } = e.target;
    switch (name) {
      case "city":
        this.setState((prev) => {
          let st = prev;
          const cities = st.cities.filter((city) => city.id === value);
          if (cities.length > 0) {
            st.service.serviceLocations[index].city = cities[0];
          }
          return st;
        });
        break;
      case "cost":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].prices[index1].cost =
            value !== "" ? parseFloat("" + value) : "";
          return st;
        });
        break;
      case "unit":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].prices[index1].unit =
            value !== "" ? parseFloat("" + value) : "";
          return st;
        });
        break;
      case "commission":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].prices[index1].commission =
            value !== "" ? parseFloat("" + value) : "";
          return st;
        });
        break;
      case "type":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].prices[index1].type = value;
          return st;
        });
        break;
      case "userAccess":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].userStatus = value;
          return st;
        });
        break;
      case "technicianAccess":
        this.setState((prev) => {
          let st = prev;
          st.service.serviceLocations[index].technicianStatus = value;
          return st;
        });
        break;
      default:
    }
  };

  onAddServicePrice = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.service.serviceLocations[index].prices.push(
        new ServiceLocationPrice()
      );
      return st;
    });
  };

  onAddServiceLocation = () => {
    this.setState((prev) => {
      let st = prev;
      st.service.serviceLocations.push(new ServiceLocation());
      return st;
    });
  };

  onUploadFiles = async (files, callback = () => { }) => {
    const formDatas = files.map((file) => convertToFormData(file));
    let result = [];
    // if(formDatas.length>0){
    await doPostUploadFiles(formDatas).then(
      (res) => {
        // console.log(res);
        result = res
          .map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 }))
          .filter((r) => r.id !== -1);
        callback(result);
      },
      (err) => {
        callback([]);
      }
    );
    // }
    return result;
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    if (this.state.activeTab === 3) {
      let seoImageId = undefined;
      const formCallback = async (images = []) => {
        const images1 = images.map((image) => ({ id: image.id }));
        let request = this.state.service.getAddRequest();
        request.seo.thumbnail = seoImageId ? { id: seoImageId } : undefined;
        request.images = request.images.concat([...images1]);

        if (this.state.displayPicSelected.value !== "") {
          const displayPics = await this.onUploadFiles([
            this.state.displayPicSelected,
          ]);
          if (displayPics.length > 0) {
            request.displayPic = { id: displayPics[0].id };
          }
        }
        if (this.state.websiteImagesSelected.value !== "") {
          const websiteImages = await this.onUploadFiles([
            this.state.websiteImagesSelected,
          ]);
          if (websiteImages.length > 0) {
            const images2 = websiteImages.map((image) => ({ id: image.id }));
            let res = request.websiteImages.concat([...images2]);
            request.websiteImages = res;
          }
        }
        const formCallback1 = (request1 = {}) => {
          (request1.id !== 0
            ? doPutUpdateService(request1.id, JSON.stringify(request1))
            : doPostAddService(JSON.stringify(request1))
          ).then(
            (res) => {
              this.props.dispatch(changePreloaderStatus(false));
              if (request1.id === 0) {
                swal("Success", "Succesfully Submitted", "success");
                this.props.history.push("/services/" + res.data.id);
              } else {
                this.initData(request1.id);
                swal("Success", "Succesfully Updated", "success");
              }
            },
            (err) => {
              this.props.dispatch(changePreloaderStatus(false));
              swal("Error", "", "error");
              // console.log(err);
            }
          );
        };

        if (this.state.detailsImages.length > 0) {
          const callback1 = (dimages = []) => {
            const images1 = dimages.map((image) => ({ id: image.id }));
            for (let i = 0; i < this.state.detailsImages.length; i++) {
              request.details[
                this.state.detailsImages[i].detailsIndex
              ].displayPic = images1[i];
            }
            formCallback1(request);
          };
          const detailsImagesAll = this.state.detailsImages.map((di) => {
            return di.data;
          });
          await this.onUploadFiles(detailsImagesAll, callback1);
        } else {
          formCallback1(request);
        }
      };
      this.props.dispatch(changePreloaderStatus(true));

      if (this.state.seoImageSelected.value !== "") {
        const callback1 = (images) => {
          const images1 = images.map((image) => ({ id: image.id }));
          if (images1.length) seoImageId = images1[0].id;
          this.onUploadFiles(this.state.imagesSelected, formCallback);
          // this.onUploadFiles(this.state.websiteImagesSelected, formCallback);
        };
        const seoImageData =
          this.state.seoImageSelected.value !== ""
            ? [this.state.seoImageSelected]
            : [];
        this.onUploadFiles(seoImageData, callback1);
      } else {
        if (this.state.seoImageSelected.value !== "")
          seoImageId = this.state.service.seo.image.id;
        this.onUploadFiles(this.state.imagesSelected, formCallback);
        // this.onUploadFiles(this.state.websiteImagesSelected, formCallback);
      }
    } else {
      this.setActiveTab(this.state.activeTab + 1);
    }
  };

  onDeleteImage = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.service.images.splice(index, 1);
      return st;
    });
  };
  onDeleteWebsiteImage = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.service.websiteImages.splice(index, 1);
      return st;
    });
  };

  onDeleteServiceLocation = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.service.serviceLocations.splice(index, 1);
      return st;
    });
  };

  onDeleteServicePrice = (index, index1) => {
    this.setState((prev) => {
      let st = prev;
      st.service.serviceLocations[index].prices.splice(index1, 1);
      return st;
    });
  };

  getUnSelectedLocation = () => {
    const locations = this.state.service.serviceLocations;
    return this.state.cities.filter((city) => {
      for (let index = 0; index < locations.length; index++) {
        if (locations[index].city.id === city.id) {
          return false;
        }
      }
      return true;
    });
  };

  onAddFaq = () => {
    this.setState((prev) => {
      let st = prev;
      st.service.faqs.push(new FaqModal());
      return st;
    });
  };

  onDeleteFaq = (i) => {
    this.setState((prev) => {
      let st = prev;
      // if (st.service.faqs[i].id!==0){
      //   st.service.faqs[i].active = false;
      // }else{
      st.service.faqs.splice(i, 1);
      // }
      return st;
    });
  };

  setActiveTab = (i) => {
    if (i > 0)
      this.setState({
        activeTab: i,
      });
  };

  onAddDetails = () => {
    this.setState((prev) => {
      let st = prev;
      st.service.details.push(new ServiceDetailsModal());
      return st;
    });
  };

  onDeleteServiceDetail = (i) => {
    this.setState((prev) => {
      let st = prev;
      st.service.details.splice(i, 1);
      const ims = st.detailsImages.findIndex((d) => {
        return d.detailsIndex === i;
      });
      if (ims !== -1) {
        st.detailsImages.splice(ims, 1);
      }
      return st;
    });
  };

  onDeleteServiceDetailsPic = (i) => {
    this.setState((prev) => {
      let st = prev;
      st.service.details[i].displayPic = new ImageModal();
      return st;
    });
  };

  onHandleServiceDetails = (e, index) => {
    const { name, value, files } = e.target;
    switch (name) {
      case "title":
        this.setState((prev) => {
          let st = prev;
          st.service.details[index].title = value;
          return st;
        });
        break;
      case "description":
        this.setState((prev) => {
          let st = prev;
          st.service.details[index].description = value;
          return st;
        });
        break;
      case "displayPic":
        if (files.length > 0) {
          const file = files[0];
          this.setState((prev) => {
            let st = prev;
            const ims = st.detailsImages.findIndex((d) => {
              return d.detailsIndex === index;
            });

            if (ims !== -1) {
              st.detailsImages[ims] = {
                detailsIndex: index,
                data: {
                  name: "files",
                  value: file,
                  filename: file.name,
                },
              };
            } else {
              st.detailsImages.push({
                detailsIndex: index,
                data: {
                  name: "files",
                  value: file,
                  filename: file.name,
                },
              });
            }
            return st;
          });
        }
        break;
      default:
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " Service Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })}
                          >
                            <span className="step-number mr-2">01</span>
                            Service Overview
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 2,
                            })}
                          >
                            <span className="step-number mr-2">02</span>
                            <span>Seo</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 3,
                            })}
                          >
                            <span className="step-number mr-2">03</span>
                            Faqs
                          </NavLink>
                        </NavItem>
                      </ul>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form className="row" onSubmit={this.onFormSubmit}>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Name</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="name"
                                  type="text"
                                  className="form-control"
                                  value={this.state.service.name}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Category</Label>
                                <Select
                                  name="category"
                                  value={{
                                    label: this.state.service.category.name,
                                    value: this.state.service.category.id,
                                  }}
                                  isMulti={false}
                                  onChange={(e) =>
                                    this.onHandleChange({
                                      target: {
                                        name: "category",
                                        value: e.value,
                                      },
                                    })
                                  }
                                  options={this.state.categories.map(
                                    (category) => ({
                                      label: category.name,
                                      value: category.id,
                                    })
                                  )}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <abbr style={{ textDecoration: "none" }} title="enter comma after typing each keyword "><Label>Keywords</Label></abbr>
                                <Input
                                  // placeholder="separate keywords with a comma"
                                  onChange={this.onHandleChange}
                                  name="keywords"
                                  type="textarea"
                                  className="form-control"
                                  value={this.state.service.keywords}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>

                                <abbr style={{ textDecoration: "none" }} title="enter comma after typing each problem "><Label>Problem suggestions</Label></abbr>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="problem"
                                  type="text"
                                  className="form-control"
                                  value={this.state.service.problem}
                                  pattern="[^,\r\n]{0,}(,[^,\r\n]{0,}){0,4}"
                                  title="Please enter up to five problems separated by commas"
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <Card>
                                <CardBody>
                                  <h5 className="mb-0">
                                    Details{" "}
                                    <i
                                      className="cursor-pointer fa fa-plus text-success float-right"
                                      onClick={this.onAddDetails}
                                    ></i>
                                  </h5>
                                  {this.state.service.details.map(
                                    (detail, index) => {
                                      return (
                                        <div
                                          key={"serviceDetailsData" + index}
                                          className="mt-3 bg-light p-2 position-relative"
                                        >
                                          <div
                                            id={"serviceDetailstoggler" + index}
                                            className="cursor-pointer"
                                          >
                                            <p className="mb-0 font-weight-bold">
                                              {detail.title === ""
                                                ? "New Details"
                                                : detail.title}{" "}
                                              <i
                                                onClick={() =>
                                                  this.onDeleteServiceDetail(
                                                    index
                                                  )
                                                }
                                                className="cursor-pointer fa fa-trash text-danger float-right mr-3"
                                              ></i>
                                            </p>
                                          </div>
                                          <UncontrolledCollapse
                                            toggler={
                                              "#serviceDetailstoggler" + index
                                            }
                                          >
                                            <Card className="mt-2">
                                              <CardBody>
                                                <Row>
                                                  <Col md={6}>
                                                    <FormGroup>
                                                      <Label>Title</Label>
                                                      <Input
                                                        type="text"
                                                        name="title"
                                                        value={detail.title}
                                                        placeholder="Title"
                                                        onChange={(e) =>
                                                          this.onHandleServiceDetails(
                                                            e,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={6}></Col>
                                                  <Col md={6}>
                                                    <FormGroup>
                                                      <Label>Description</Label>
                                                      <Input
                                                        type="textarea"
                                                        name="description"
                                                        value={
                                                          detail.description
                                                        }
                                                        placeholder="Description"
                                                        onChange={(e) =>
                                                          this.onHandleServiceDetails(
                                                            e,
                                                            index
                                                          )
                                                        }
                                                      />
                                                    </FormGroup>
                                                  </Col>
                                                  <Col md={6}>
                                                    <FormGroup>
                                                      <Label>Image</Label>
                                                      {detail.displayPic.id ===
                                                        0 ? (
                                                        <Input
                                                          type="file"
                                                          name="displayPic"
                                                          accept="image/*"
                                                          onChange={(e) =>
                                                            this.onHandleServiceDetails(
                                                              e,
                                                              index
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        <div className="d-flex align-items-center">
                                                          <img
                                                            src={getImageUrl(
                                                              detail.displayPic
                                                                .url
                                                            )}
                                                            className="img-fluid"
                                                            width="100px"
                                                            alt="."
                                                          />
                                                          <i
                                                            className="fas fa-trash text-danger ml-2 cursor-pointer"
                                                            onClick={() =>
                                                              this.onDeleteServiceDetailsPic(
                                                                index
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      )}
                                                    </FormGroup>
                                                  </Col>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </UncontrolledCollapse>
                                        </div>
                                      );
                                    }
                                  )}
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md={12}>
                              <Card>
                                <CardBody>
                                  <h5 className="mb-0">
                                    Service Locations{" "}
                                    <i
                                      className="cursor-pointer fa fa-plus text-success float-right"
                                      onClick={this.onAddServiceLocation}
                                    ></i>
                                  </h5>
                                  {this.state.service.serviceLocations.map(
                                    (location, index) => {
                                      return (
                                        <div className="mt-3 bg-light p-2 position-relative">
                                          <div
                                            id={
                                              "serviceLocationstoggler" + index
                                            }
                                            className="cursor-pointer"
                                          >
                                            <p className="mb-0 font-weight-bold">
                                              {location.city.name === ""
                                                ? "New Location"
                                                : location.city.name}{" "}
                                              <i
                                                onClick={() =>
                                                  this.onDeleteServiceLocation(
                                                    index
                                                  )
                                                }
                                                className="cursor-pointer fa fa-trash text-danger float-right mr-3"
                                              ></i>
                                            </p>
                                          </div>
                                          <UncontrolledCollapse
                                            toggler={
                                              "#serviceLocationstoggler" + index
                                            }
                                          >
                                            <Card className="mt-2">
                                              <CardBody>
                                                <h6>City</h6>
                                                <Select
                                                  name="city"
                                                  value={{
                                                    label: location.city.name,
                                                    value: location.city.id,
                                                  }}
                                                  isMulti={false}
                                                  onChange={(e) =>
                                                    this.onHandleServiceLocations(
                                                      {
                                                        target: {
                                                          name: "city",
                                                          value: e.value,
                                                        },
                                                      },
                                                      index
                                                    )
                                                  }
                                                  options={this.getUnSelectedLocation().map(
                                                    (city) => ({
                                                      label: city.name,
                                                      value: city.id,
                                                    })
                                                  )}
                                                  classNamePrefix="select2-selection"
                                                  closeMenuOnSelect={true}
                                                  components={
                                                    animatedComponents
                                                  }
                                                  required
                                                />
                                              </CardBody>
                                            </Card>
                                            <Card className="mt-2">
                                              <CardBody>
                                                <h6>Access</h6>
                                                <div className="d-flex flex-wrap">
                                                  <div className="col">
                                                    <p className="font-weight-bold">
                                                      User Access
                                                    </p>
                                                    <ReactSwitch
                                                      name="userAccess"
                                                      uncheckedIcon={
                                                        <Offsymbol />
                                                      }
                                                      className="mr-1"
                                                      checkedIcon={<OnSymbol />}
                                                      onColor="#626ed4"
                                                      onChange={(e) =>
                                                        this.onHandleServiceLocations(
                                                          {
                                                            target: {
                                                              name: "userAccess",
                                                              value:
                                                                !location.userStatus,
                                                            },
                                                          },
                                                          index
                                                        )
                                                      }
                                                      checked={
                                                        location.userStatus
                                                      }
                                                    />
                                                  </div>
                                                  <div className="col">
                                                    <p className="font-weight-bold">
                                                      Techinician Access
                                                    </p>
                                                    <ReactSwitch
                                                      name="technicianAccess"
                                                      uncheckedIcon={
                                                        <Offsymbol />
                                                      }
                                                      className="mr-1"
                                                      checkedIcon={<OnSymbol />}
                                                      onColor="#626ed4"
                                                      onChange={(e) =>
                                                        this.onHandleServiceLocations(
                                                          {
                                                            target: {
                                                              name: "technicianAccess",
                                                              value:
                                                                !location.technicianStatus,
                                                            },
                                                          },
                                                          index
                                                        )
                                                      }
                                                      checked={
                                                        location.technicianStatus
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </CardBody>
                                            </Card>

                                            <Card className="mt-2 mb-2">
                                              <CardBody>
                                                <h6>
                                                  Prices{" "}
                                                  <i
                                                    onClick={() =>
                                                      this.onAddServicePrice(
                                                        index
                                                      )
                                                    }
                                                    className="fa fa-plus text-success cursor-pointer float-right mr-3"
                                                  ></i>
                                                </h6>
                                                {location.prices.map(
                                                  (price, index1) => {
                                                    return (
                                                      <div className="mb-3 bg-light p-2">
                                                        <div
                                                          id={
                                                            "serviceLocationsPricestoggler" +
                                                            index +
                                                            "_" +
                                                            index1
                                                          }
                                                          className="cursor-pointer"
                                                        >
                                                          <p className="mb-0 font-weight-bold">
                                                            {price.type === ""
                                                              ? "New Price"
                                                              : price.type}{" "}
                                                            <i
                                                              onClick={() =>
                                                                this.onDeleteServicePrice(
                                                                  index,
                                                                  index1
                                                                )
                                                              }
                                                              className="fa fa-trash text-danger cursor-pointer float-right mr-3"
                                                            ></i>
                                                          </p>
                                                        </div>
                                                        <UncontrolledCollapse
                                                          toggler={
                                                            "#serviceLocationsPricestoggler" +
                                                            index +
                                                            "_" +
                                                            index1
                                                          }
                                                        >
                                                          <div className="mb-2 bg-light p-2">
                                                            <Card className="mt-1">
                                                              <CardBody>
                                                                <Row>
                                                                  <Col md={6}>
                                                                    <FormGroup>
                                                                      <Label>
                                                                        Cost
                                                                      </Label>
                                                                      <Input
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.onHandleServiceLocations(
                                                                            e,
                                                                            index,
                                                                            index1
                                                                          )
                                                                        }
                                                                        name="cost"
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={
                                                                          price.cost
                                                                        }
                                                                        required
                                                                      />
                                                                    </FormGroup>
                                                                  </Col>
                                                                  <Col md={6}>
                                                                    <FormGroup>
                                                                      <Label>
                                                                        Unit
                                                                      </Label>
                                                                      <Input
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.onHandleServiceLocations(
                                                                            e,
                                                                            index,
                                                                            index1
                                                                          )
                                                                        }
                                                                        name="unit"
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={
                                                                          price.unit
                                                                        }
                                                                        required
                                                                      />
                                                                    </FormGroup>
                                                                  </Col>
                                                                  <Col md={6}>
                                                                    <FormGroup>
                                                                      <Label>
                                                                        Commission
                                                                      </Label>
                                                                      <Input
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.onHandleServiceLocations(
                                                                            e,
                                                                            index,
                                                                            index1
                                                                          )
                                                                        }
                                                                        name="commission"
                                                                        type="number"
                                                                        className="form-control"
                                                                        value={
                                                                          price.commission
                                                                        }
                                                                        required
                                                                      />
                                                                    </FormGroup>
                                                                  </Col>
                                                                  <Col md={6}>
                                                                    <FormGroup>
                                                                      <Label>
                                                                        Type
                                                                      </Label>
                                                                      <Select
                                                                        name="type"
                                                                        value={{
                                                                          label:
                                                                            price.type,
                                                                          value:
                                                                            price.type,
                                                                        }}
                                                                        isMulti={
                                                                          false
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) =>
                                                                          this.onHandleServiceLocations(
                                                                            {
                                                                              target:
                                                                              {
                                                                                name: "type",
                                                                                value:
                                                                                  e.value,
                                                                              },
                                                                            },
                                                                            index,
                                                                            index1
                                                                          )
                                                                        }
                                                                        options={servicePriceTypes.map(
                                                                          (
                                                                            type
                                                                          ) => ({
                                                                            label:
                                                                              type,
                                                                            value:
                                                                              type,
                                                                          })
                                                                        )}
                                                                        classNamePrefix="select2-selection"
                                                                        closeMenuOnSelect={
                                                                          true
                                                                        }
                                                                        components={
                                                                          animatedComponents
                                                                        }
                                                                        required
                                                                      />
                                                                    </FormGroup>
                                                                  </Col>
                                                                </Row>
                                                              </CardBody>
                                                            </Card>
                                                          </div>
                                                        </UncontrolledCollapse>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </CardBody>
                                            </Card>
                                          </UncontrolledCollapse>
                                        </div>
                                      );
                                    }
                                  )}
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Display Service Picture</Label>
                                {this.state.service.displayPic.id === 0 ? (
                                  <Input
                                    type="file"
                                    key={"files" + this.state.displayPicCount}
                                    name="displayPic"
                                    accept="image/*"
                                    onChange={this.onHandleChange}
                                  />
                                ) : (
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={getImageUrl(
                                        this.state.service.displayPic.url
                                      )}
                                      className="img-fluid"
                                      width="100px"
                                      alt="."
                                    />
                                    <i
                                      className="fas fa-trash text-danger ml-2 cursor-pointer"
                                      onClick={this.deleteDisplayPic}
                                    ></i>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}></Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Images</Label>
                                <Input
                                  key={"files" + this.state.imagesFileCount}
                                  onChange={this.onHandleChange}
                                  name="files"
                                  type="file"
                                  multiple
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Website Images</Label>
                                <Input
                                  key={
                                    "files" + this.state.websiteImagesFileCount
                                  }
                                  onChange={this.onHandleChange}
                                  name="websitefiles"
                                  type="file"
                                  multiple
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            {this.state.service.images.length > 0 ? (
                              <>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>All Images</Label>
                                    <div className="">
                                      {this.state.service.images.map(
                                        (img, index) => {
                                          return (
                                            <div class="d-inline-block pl-3 pr-3 position-relative">
                                              <i
                                                onClick={() =>
                                                  this.onDeleteImage(index)
                                                }
                                                className="cursor-pointer fa fa-trash text-danger position-absolute"
                                                style={{ top: 0, right: 0 }}
                                              ></i>
                                              <img
                                                width="100"
                                                src={getImageUrl(img.url)}
                                                className="img-fluid"
                                                alt="hhg"
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                            {this.state.service.websiteImages.length > 0 ? (
                              <>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>All Website Images</Label>
                                    <div className="">
                                      {this.state.service.websiteImages.map(
                                        (img, index) => {
                                          return (
                                            <div class="d-inline-block pl-3 pr-3 position-relative">
                                              <i
                                                onClick={() =>
                                                  this.onDeleteWebsiteImage(
                                                    index
                                                  )
                                                }
                                                className="cursor-pointer fa fa-trash text-danger position-absolute"
                                                style={{ top: 0, right: 0 }}
                                              ></i>
                                              <img
                                                width="100"
                                                src={getImageUrl(img.url)}
                                                className="img-fluid"
                                                alt="hhg"
                                              />
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                            <Col md={12}>
                              <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li
                                  className={
                                    this.state.activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      this.setActiveTab(
                                        this.state.activeTab - 1
                                      );
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li className={"next"}>
                                  <Button color="primary" type="submit">
                                    {this.state.activeTab === 3
                                      ? "Save"
                                      : "Next"}
                                  </Button>
                                </li>
                              </ul>
                            </Col>
                          </Form>
                        </TabPane>

                        <TabPane tabId={2}>
                          <Form className="row" onSubmit={this.onFormSubmit}>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Meta Title</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="metatitle"
                                  type="text"
                                  className="form-control"
                                  value={this.state.service.seo.title}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Meta Description</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="metadescription"
                                  type="textarea"
                                  className="form-control"
                                  value={this.state.service.seo.description}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Meta Keywords</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="metakeywords"
                                  type="text"
                                  className="form-control"
                                  value={this.state.service.seo.keywords}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Meta Thumbnail</Label>
                                <Input
                                  key={
                                    "filesThumbnail" + this.state.seoFileCount
                                  }
                                  onChange={this.onHandleChange}
                                  name="metathumbnail"
                                  type="file"
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            {this.state.service.seo.image.id !== 0 ? (
                              <>
                                <Col md={{ size: 6, offset: 6 }}>
                                  <img
                                    className="img-fluid"
                                    width="100"
                                    src={getImageUrl(
                                      this.state.service.seo.image.url
                                    )}
                                    alt="thumbnail"
                                  />
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                            <Col md={12}>
                              <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li
                                  className={
                                    this.state.activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      this.setActiveTab(
                                        this.state.activeTab - 1
                                      );
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li className={"next"}>
                                  <Button color="primary" type="submit">
                                    {this.state.activeTab === 3
                                      ? "Save"
                                      : "Next"}
                                  </Button>
                                </li>
                              </ul>
                            </Col>
                          </Form>
                        </TabPane>

                        <TabPane tabId={3}>
                          <Form className="row" onSubmit={this.onFormSubmit}>
                            <Col md={12}>
                              <div className="d-flex mb-3">
                                <i
                                  className="fa fa-plus text-success ml-auto mr-3 cursor-pointer"
                                  onClick={this.onAddFaq}
                                ></i>
                              </div>
                            </Col>
                            <Col md={12}>
                              {this.state.service.faqs.map((faq, index1) => {
                                return (
                                  <>
                                    <Card className="border">
                                      <CardBody>
                                        <div className="row">
                                          <Col md={12}>
                                            <h5>
                                              {faq.title !== ""
                                                ? faq.title
                                                : "New Faq"}
                                              <i
                                                className="fa fa-trash text-danger float-right cursor-danger"
                                                onClick={() =>
                                                  this.onDeleteFaq(index1)
                                                }
                                              ></i>
                                            </h5>
                                          </Col>
                                          <Col md={6}>
                                            <FormGroup>
                                              <Label>Title</Label>
                                              <Input
                                                onChange={(e) =>
                                                  this.onHandleFaqChange(
                                                    e,
                                                    index1
                                                  )
                                                }
                                                name="faqtitle"
                                                type="text"
                                                className="form-control"
                                                value={faq.title}
                                                required
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md={6}>
                                            <FormGroup>
                                              <Label>Type</Label>
                                              {/* <Input
                                                onChange={(e) =>
                                                  this.onHandleFaqChange(
                                                    e,
                                                    index1
                                                  )
                                                }
                                                name="faqtype"
                                                type="text"
                                                className="form-control"
                                                value={faq.type}
                                                required
                                              /> */}
                                              <Select
                                                name="faqtype"
                                                onChange={(e) => {
                                                  this.onHandleFaqChange(
                                                    {
                                                      target: {
                                                        name: "faqtype",
                                                        value: e.value,
                                                      },
                                                    },
                                                    index1
                                                  );
                                                  //console.log(
                                                  //   "Faq Type E :- ",
                                                  //   e
                                                  // );
                                                }}
                                                options={[
                                                  {
                                                    label: "service",
                                                    value: "SERVICE",
                                                  },
                                                  {
                                                    label: "website",
                                                    value: "WEBSITE",
                                                  },
                                                ]}
                                                value={{
                                                  label: faq.type,
                                                  value: faq.type,
                                                }}
                                                classNamePrefix="select2-selection"
                                                closeMenuOnSelect={true}
                                                components={animatedComponents}
                                                required
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md={6}>
                                            <FormGroup>
                                              <Label>Description</Label>
                                              <Input
                                                onChange={(e) =>
                                                  this.onHandleFaqChange(
                                                    e,
                                                    index1
                                                  )
                                                }
                                                name="faqdescription"
                                                type="textarea"
                                                className="form-control"
                                                value={faq.description}
                                                required
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col md={6}>
                                            <FormGroup>
                                              <Label className="d-block">
                                                Status
                                              </Label>
                                              <ReactSwitch
                                                name="faqstatus"
                                                uncheckedIcon={<Offsymbol />}
                                                className="mr-1"
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                onChange={(e) =>
                                                  this.onHandleFaqChange(
                                                    {
                                                      target: {
                                                        name: "faqstatus",
                                                        value: !faq.active,
                                                      },
                                                    },
                                                    index1
                                                  )
                                                }
                                                checked={faq.active}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </>
                                );
                              })}
                            </Col>
                            <Col md={12}>
                              <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li
                                  className={
                                    this.state.activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      this.setActiveTab(
                                        this.state.activeTab - 1
                                      );
                                    }}
                                  >
                                    Previous
                                  </Link>
                                </li>
                                <li className={"next"}>
                                  <Button color="primary" type="submit">
                                    {this.state.activeTab === 3
                                      ? "Save"
                                      : "Next"}
                                  </Button>
                                </li>
                              </ul>
                            </Col>
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withNamespaces()(connect(mapStateToProps)(AddService));
