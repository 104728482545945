import React, { useState, useEffect } from "react";
import { getImageUrl, getValidatedData } from "../../../constants/constants";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
//i18n
import { withNamespaces } from "react-i18next";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (props.loginUser.id !== 0) {
      setUserName(props.loginUser.getFullName());
    } else {
      setUserName("Admin");
    }
  }, [props.loginUser]);
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menuOpen}
        toggle={() => setMenuOpen(!menuOpen)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={
              props.loginUser.profilePic.id === 0
                ? user1
                : getImageUrl(props.loginUser.profilePic.url)
            }
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ml-2 mr-1">{userName}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          {/* <DropdownItem tag="a" href="#">
                            <i className="bx bx-user font-size-16 align-middle mr-1"></i>
                            {this.props.t('Profile')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="/crypto-wallet">
                            <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
                            {this.props.t('My Wallet')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="#">
                            <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                            {this.props.t('Settings')}
                        </DropdownItem>
                        <DropdownItem tag="a" href="auth-lock-screen">
                            <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{this.props.t('Lock screen')}
                        </DropdownItem>
                        <div className="dropdown-divider"></div> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  // console.log(state.Login)
  return { loginUser: state.Login.user };
};

export default withRouter(
  withNamespaces()(connect(mapStatetoProps)(ProfileMenu))
);
