export const inventoryFilterconfig={
    filters:[
        {
            label:"Name",
            placeholder:"Search Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            options:[{ label:"",value:""}],
            key:"item_name",
        },
        {
            label:"Service Type",
            placeholder:"Search Service",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"service.name",
        },
        // {
        //     label:"Active",
        //     placeholder:"Select",
        //     filterPostfix:"_contains",
        //     type:"select",
        //     value:"",
        //     options:[{ label:"active",value:"active"},{ label:"inactive",value:"inactive"}],
        //     key:"service.name",
        // },
        {
            label:"Price",
            placeholder:"Search Price",
            filterPostfix:"_contains",
            type:"text",
            value:"",
            key:"item_price",
        },
        {
            label:"Commission",
            placeholder:"Search Commission",
            filterPostfix:"_contains",
            type:"text",
            value:"",
            key:"commission",
        }
    ]
}