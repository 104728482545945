import React, { Component } from "react";
import ReactSwitch from "react-switch";
import { Col, Form, FormGroup, Input, Label } from "reactstrap";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import Select from "react-select";
export default class SliderFormComponent extends Component {
  render() {
    const state = this.props.state;
    const handleChange = this.props.handleChange;
    const options = [
      { value: "HOMEPAGETOP", label: "HOMEPAGETOP" },
      { value: "LOCALADS", label: "LOCALADS" },
      { value: "VIDEOS", label: "VIDEOS" },
      { value: "PARTNERSHIPS", label: "PARTNERSHIPS" },
    ];

    return (
      <>
        <div style={{ marginBottom: "20px" }}>
          <Form className="row userForm">
            <Col md={6}>
              <FormGroup>
                <Label>Title</Label>

                <Input
                  //   onChange={this.onHandleChange}
                  name="title"
                  type="text"
                  className="form-control"
                  value={state.title}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Type</Label>
                {/* <Input
                  //   onChange={this.onHandleChange}
                  name="type"
                  className="form-control"
                  value={state.type}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  placeholder=""
                  required
                /> */}

                <Select
                  options={options}
                  value={{
                    label: state.type,
                    value: state.type,
                  }}
                  label={state.type}
                  onChange={(e) => {
                    handleChange("type", e.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Order</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="order"
                  type="number"
                  className="form-control"
                  value={state.order}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Image</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="image"
                  type="file"
                  className="form-control"
                  //   value={state.image}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.files[0]);
                  }}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>iframeurl</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="iframeurl"
                  type="textarea"
                  className="form-control"
                  value={state.iframeurl}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  //   onChange={this.onHandleChange}
                  name="description"
                  type="textarea"
                  className="form-control"
                  value={state.description}
                  onChange={(e) => {
                    handleChange(e.target.name, e.target.value);
                  }}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <Label>Active</Label>

              <FormGroup check style={{ marginLeft: "-20px" }}>
                <ReactSwitch
                  name="active"
                  uncheckedIcon={<Offsymbol />}
                  className="mr-1"
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => handleChange("active", !state.active)}
                  checked={state.active}
                />
              </FormGroup>
            </Col>
          </Form>
        </div>
      </>
    );
  }
}
