import React, { Component } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { ImageModal, SiteSettingsModal } from "../../../classes/classes";
import {
  convertToFormData,
  getExt,
  getImageUrl,
  getValidatedData,
} from "../../../constants/constants";
import { changePreloaderStatus } from "../../../store/actions";
import { getConvertedSiteSettings } from "../../../constants/conversions/siteSettings";
import {
  doGetSettingsDetails,
  doPostUploadFiles,
  doPutSettingsDetails,
} from "../../../services/apiServices";
import swal from "sweetalert";
const restrictedext = ["png", "jpg", "jpeg", "webp"];

class SiteSettings extends Component {
  state = {
    settings: new SiteSettingsModal(),
    seoImageSelected: {
      name: "files",
      value: "",
      filename: "",
    },
    seoFileCount: 0,
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Site Settings",
      },
    ],
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const request = JSON.parse(JSON.stringify(this.state.settings));
    request.gstpercentage =
      request.gstpercentage !== "" || request.gstpercentage !== null
        ? parseFloat("" + request.gstpercentage)
        : 0;
    this.props.dispatch(changePreloaderStatus(true));
    let seoreq = this.state.settings.seo.getAddRequest();
    if (this.state.seoImageSelected.value !== "") {
      const callback1 = (images) => {
        const images1 = images.map((image) => ({ id: image.id }));
        seoreq = this.state.settings.seo.getAddRequest(images1[0].id);
        let req1 = (request);
        req1.seo = seoreq;
        doPutSettingsDetails(this.state.settings.id, req1).then(
          () => {
            swal("Success", "Successfully Updated", "success");
            this.getSiteService();
          },
          () => {
            swal("Error", "SomeThing Unexpected Happened", "error");
          }
        );
      };
      const seoImageData = [this.state.seoImageSelected];
      this.onUploadFiles(seoImageData, callback1);
    } else {
      let req1 = request;
      req1.seo = seoreq;
      doPutSettingsDetails(this.state.settings.id, req1).then(
        () => {
          swal("Success", "Successfully Updated", "success");
          this.getSiteService();
        },
        () => {
          swal("Error", "SomeThing Unexpected Happened", "error");
        }
      );
    }
  };

  onUploadFiles = (files, callback = () => {}) => {
    const formDatas = files.map((file) => convertToFormData(file));
    doPostUploadFiles(formDatas).then(
      (res) => {
        //console.log(res);
        callback(
          res
            .map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 }))
            .filter((r) => r.id !== -1)
        );
      },
      (err) => {
        callback([]);
      }
    );
  };

  componentDidMount() {
    this.getSiteService();
  }

  getSiteService = () => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetSettingsDetails().then(
      (res) => {
        if (getValidatedData(res.data, "array", true) && res.data.length > 0) {
          this.setState({
            settings: getConvertedSiteSettings(res.data)[0],
          });
        }
        this.props.dispatch(changePreloaderStatus(false));
      },
      () => {
        this.props.dispatch(changePreloaderStatus(false));
      }
    );
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "seo":
        break;
      default:
        this.setState((prev) => {
          let st = prev;
          st.settings["" + name] = value;
          return st;
        });
    }
  };

  onHandleChangeSEO = (e) => {
    const { name, value, files } = e.target;

    switch (name) {
      case "seo.thumbnail":
        let imagesSelected = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
            imagesSelected.push({
              name: "files",
              value: file,
              filename: file.name,
            });
          }
        }
        this.setState((prev) => {
          let st = prev;
          st.seoImageSelected = imagesSelected[0];
          return st;
        });
        break;
      default:
        this.setState((prev) => {
          let st = prev;
          st.settings.seo["" + name] = value;
          return st;
        });
    }
  };

  onDeleteSeo = () => {
    this.setState((prev) => {
      let st = prev;
      st.settings.seo.image = new ImageModal();
      return st;
    });
  };

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Site Settings"}
            breadcrumbItems={this.state.breadcrumbItems}
          />
          <Form className="row userForm" onSubmit={this.onFormSubmit}>
            <Col md={6}>
              <FormGroup>
                <Label>GST ID</Label>
                <Input
                  name="gstId"
                  value={this.state.settings.gstId}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>GST PERCENTAGE</Label>
                <Input
                  type="number"
                  name="gstpercentage"
                  value={this.state.settings.gstpercentage}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>GOOGLE ANALYTICS ID</Label>
                <Input
                  name="googleanalyticsid"
                  value={this.state.settings.googleanalyticsid}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>FB URL</Label>
                <Input
                  name="fburl"
                  value={this.state.settings.fburl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>LINKEDIN URL</Label>
                <Input
                  name="linkedinurl"
                  value={this.state.settings.linkedinurl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>TWITTER URL</Label>
                <Input
                  name="twitterurl"
                  value={this.state.settings.twitterurl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>INSTAGRAM URL</Label>
                <Input
                  name="instaurl"
                  value={this.state.settings.instaurl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>CONTACTUS URL</Label>
                <Input
                  name="contactusUrl"
                  value={this.state.settings.contactusUrl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>GUIDELINES URL</Label>
                <Input
                  name="guidelinesUrl"
                  value={this.state.settings.guidelinesUrl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>TERMS URL</Label>
                <Input
                  name="termsUrl"
                  value={this.state.settings.termsUrl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>REFER URL</Label>
                <Input
                  name="referUrl"
                  value={this.state.settings.referUrl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>REGISTER PARTNER URL</Label>
                <Input
                  name="registerPartnerUrl"
                  value={this.state.settings.registerPartnerUrl}
                  onChange={this.onHandleChange}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <div className="alert alert-success">
                <p className="mb-0">SEO SECTION</p>
              </div>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>TITLE</Label>
                <Input
                  name="title"
                  value={this.state.settings.seo.title}
                  onChange={this.onHandleChangeSEO}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>DESCRIPTION</Label>
                <Input
                  name="description"
                  type="textarea"
                  value={this.state.settings.seo.description}
                  onChange={this.onHandleChangeSEO}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>KEYWORDS</Label>
                <Input
                  name="keywords"
                  value={this.state.settings.seo.keywords}
                  onChange={this.onHandleChangeSEO}
                  placeholder=""
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>THUMBNAIL</Label>
                {this.state.settings.seo.image.id !== 0 ? (
                  <>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          "" + getImageUrl(this.state.settings.seo.image.url)
                        }
                        width="100px"
                        height="100px"
                      />
                      <i
                        className="fas fa-trash text-danger cursor-pointer"
                        onClick={this.onDeleteSeo}
                      ></i>
                    </div>
                  </>
                ) : (
                  <>
                    <Input
                      type={"file"}
                      name="seo.thumbnail"
                      value={this.state.settings.seo.thumbnail}
                      onChange={this.onHandleChangeSEO}
                      placeholder=""
                      key={"seothumbnail" + this.state.seoFileCount}
                    />
                  </>
                )}
              </FormGroup>
            </Col>
            <Col md={12} className="mt-5">
              <FormGroup>
                <Button color="primary">Submit</Button>
              </FormGroup>
            </Col>
          </Form>
        </Container>
      </div>
    );
  }
}

export default connect()(SiteSettings);
