import moment from "moment";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Col, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { connect } from "react-redux";
// import AddCoinsModal from "./AddCoinModal";

import CustomPagination from "../../../components/Common/CustomPagination";
import { doGetAllWalletByIdCount, doGetWalletById, doAddCoins } from "../../../services/apiServices";
import swal from "sweetalert";

class WalletId extends Component {
  state = {
    coinsToAdd: 0,
    transactionId: "",
    isModalOpen: false,
    transactions: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
  };
  componentDidMount() {
    this.getWallet(0, this.state.pagination.limit);
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
    }));
  };

  addcoins = () => {
    // console.log("----------------------");
    console.log("value----------", this.props?.match?.params.id);
    console.log("coins--------", this.state.coinsToAdd);
    // console.log("userID", this.state.transactions[0].wallet.user.id);
    const params = this.props.location.pathname;
    const parts = params.split("/");
    const numberAfterLastSlash = parts[parts.length - 1];

    console.log("userID", numberAfterLastSlash);

    const body = {
      coinsToAdd: this.state.coinsToAdd,
      transactionId: this.state.transactionId,
      walletId: this.props.match.params.id,
      // userID: this.state.transactions[0].wallet.user.id
      userID: numberAfterLastSlash,
    };
    this.props.dispatch(changePreloaderStatus(true));

    doAddCoins(body).then(
      (res) => {
        console.log("res", res);
        this.props.dispatch(changePreloaderStatus(false));

        // alert('Coins added successfully')
        swal("Success", " Coins added successfully", "success");
        this.getWallet(0, this.state.pagination.limit);

        this.toggleModal();
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        console.log("err", err);
        swal("Error", "", "error");
        this.toggleModal();
      }
    );
  };

  getWallet = (currentPageNo, limit) => {
    let query = `_limit=${limit}&_start=${currentPageNo}&_sort=created_at:DESC`;
    doGetAllWalletByIdCount(this.props.match.params.id).then((res1) => {
      this.setState((prev) => {
        let st = prev;
        st.pagination.totalPages = Math.ceil(res1.data / st.pagination.limit);
        st.pagination.currentPageNo = currentPageNo;
        return st;
      });
      doGetWalletById(this.props.match.params.id, query).then(
        (res) => {
          // console.log(res.data);
          this.setState((prev) => {
            let st = prev;
            st.transactions = res.data;
            return st;
          });
        },
        (err) => {}
      );
    });
  };
  doPagination = (currentPageNo) => {
    this.getWallet(currentPageNo, this.state.pagination.limit);
  };

  render() {
    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Wallet Transactions</h4>
          <div style={{ display: "flex" }}>
            {/* <input
              type="number"
              // value={this.state.coinsToAdd}
              onChange={(e) => this.setState({ coinsToAdd: e.target.value })}
              placeholder="Enter coins"
            /> */}
            {/* <Button color="primary" onClick={() => {
              if (this.state.coinsToAdd > 0) {
                this.addcoins()
              }
              else {
                alert("Please enter a non zero value")
              }
            }

            }>Add coins
            </Button> */}

            <Button color="primary" onClick={this.toggleModal}>
              Add coins
            </Button>
            {/* <AddCoinsModal
              isOpen={this.state.isModalOpen}
              toggle={this.toggleModal}
              addcoins={this.addcoins}
            /> */}
          </div>

          <Modal isOpen={this.state.isModalOpen} toggle={this.toggleModal}>
            <ModalHeader toggle={this.toggleModal}>Add Coins</ModalHeader>
            <ModalBody>
              <div className="form-group">
                <label htmlFor="coinsInput">Coins to Add:</label>
                <input
                  type="number"
                  className="form-control"
                  id="coinsInput"
                  value={this.state.coinsToAdd}
                  onChange={(e) => this.setState({ coinsToAdd: e.target.value })}
                  placeholder="Enter coins"
                />
              </div>
              <div className="form-group">
                <label htmlFor="transactionIdInput">Transaction ID:</label>
                <input
                  type="text"
                  className="form-control"
                  id="transactionIdInput"
                  value={this.state.transactionId}
                  onChange={(e) => this.setState({ transactionId: e.target.value })}
                  placeholder="Enter transaction id"
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.addcoins}>
                Submit
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          <h5>
            {this.state.transactions.length > 0 &&
              this.state.transactions[0].wallet.user.firstname + " " + this.state.transactions[0].wallet.user.lastname}
          </h5>
          {/* <span>
    <Link
      to="/offers/add"
      className="btn btn-primary waves-effect waves-light btn-sm"
    >
      Add New Offer <i className="mdi mdi-arrow-right ml-1"></i>
    </Link>
  </span> */}
        </div>
        <Row>
          <Col md={12}>
            <Table striped bordered hover className="table mb-3 pr-0" style={{ verticalAlign: "middle" }}>
              <tr>
                <th>Transaction Type</th>

                <th>Amount</th>
                <th>Details</th>
                <th>Transaction Date</th>

                {/* <th>Published Date</th> */}
                {/* <th>State</th>
        <th>Published Date</th> */}
              </tr>
              {this.state.transactions.length > 0 &&
                this.state.transactions.map((tr, i) => {
                  // console.log(offer.newuser);
                  return (
                    <tr key={i}>
                      {/* <td>
                  {wallet.user.firstname + " " + wallet.user.lastname}
                </td> */}

                      <td>{tr.type}</td>

                      <td>{tr.amount}</td>
                      <td>{tr.details}</td>
                      <td>{moment(tr.created_at).format("DD MMM YYYY hh:mm:ss a")}</td>

                      {/* 
                    <td>
                      <Link to={`wallet/${wallet.id}`}>
                        {" "}
                        <Button color="danger">
                          <i className="fas fa-exchange-alt"></i>
                        </Button>
                      </Link>
                      {"    "}
                    </td> */}
                    </tr>
                  );
                })}
            </Table>
          </Col>
          <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(connect()(WalletId));
