import React, { useEffect } from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import { Card, CardBody, Col, FormGroup, Input, Label } from "reactstrap";

import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";

import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const TermsCard = (props) => {
  const onHandleChange = props.onHandleChange;
  const deleteTerms = props.deleteTerms;
  const address = props.address;
  const handleTermsChange = props.handleTermsChange;
  const term = props.term;
  const [state, setState] = React.useState({
    title: term.title || "",
    description: term.description || "",
    active: term.active || false,
    type: "OFFER",
  });

  useEffect(() => {
    handleTermsChange(props.i1, state);
  }, [state]);
  return (
    <Card>
      <CardBody className="row">
        <Col md={12}>
          <h5>
            {/* {address.name !== "" ? address.name : "New Address"}{" "} */}
            <i
              className="fa cursor-pointer 
              fa-trash text-danger float-right mr-3"
              onClick={() => deleteTerms()}
            ></i>
          </h5>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Title</Label>
            <Input
              onChange={onHandleChange}
              name="flat"
              type="text"
              className="form-control"
              value={state.title}
              onChange={(e) => setState({ ...state, title: e.target.value })}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Description</Label>
            <Input
              onChange={onHandleChange}
              name="street"
              type="text"
              className="form-control"
              value={state.description}
              onChange={(e) =>
                setState({ ...state, description: e.target.value })
              }
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <Label>Active</Label>

          <FormGroup check>
            <ReactSwitch
              name="active"
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={() => setState({ ...state, active: !state.active })}
              //   checked={this.state.new_user}
              checked={state.active}
            />
          </FormGroup>
        </Col>
      </CardBody>
    </Card>
  );
};

export default TermsCard;
