// prod
export const BASE_URL = "https://backend.homvery.com";
// export const BASE_URL="https://homverybackend-env.eba-zkpapz9f.us-west-2.elasticbeanstalk.com:1337";
// demo
// export const BASE_URL="https://homvery-backend-strapi.credot.dev";
// local
// export const BASE_URL="http://localhost:1337";
export const IMAGES_BASE_URL = "http://homverybackend-env.eba-zkpapz9f.us-west-2.elasticbeanstalk.com:1337";
export const IMAGES_BASE_URL1 = "https://homvery-dev.s3.us-west-2.amazonaws.com";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const shortmonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const convertTimeStamptoDate = (time) => {
  if (time !== "") {
    let d = new Date(time * 1000);
    return (
      d.getDate() +
      " " +
      shortmonths[d.getMonth()] +
      " " +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes() +
      ":" +
      d.getSeconds()
    );
  }
  return "";
};

/*  Regular Expression  */
export const numbersregexp = /^[0-9]*$/;
export const mobilenumberregexp = /^[6-9]\d{9}$/;
export const decimalregexp = /([0-9]*[.])?[0-9]+$/;
export const emailregexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateDateTime(datetime) {
  return !isNaN(Date.parse(datetime));
}

export function getAuthToken() {
  const auth = sessionStorage.getItem("authToken");
  if (auth !== null && auth !== "") {
    return { Authorization: "Bearer " + auth };
  }
  return { Authorization: "" };
}

/*  Get Data Type  */
const getDataType = (e) => typeof e;

/*  Validate Data Type  */
export function getValidatedData(data, type = "string", onlyValidate = false) {
  const dataTypes = {
    string: "",
    array: [],
    bigint: 0,
    number: 0,
    null: null,
    undefined: undefined,
    boolean: false,
    object: {},
    function: () => {},
  };
  if (onlyValidate) {
    if (type.toLowerCase() === "array" && Array.isArray(data)) {
      return true;
    }
    return data && getDataType(data).toLowerCase() === type.toLowerCase();
  } else {
    if (getDataType(data) === type.toLowerCase()) {
      if (type.toLowerCase() === "object" && data === null) {
        return {};
      }
      return data;
    } else {
      if (type.toLowerCase() === "array" && Array.isArray(data)) {
        return data;
      } else {
        try {
          return dataTypes[type];
        } catch (e) {
          return false;
        }
      }
    }
  }
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getLimitedWords(str = "", limitlength = 0) {
  let length = 0;
  return (
    "" +
    str
      .split(" ")
      .map((subStr = "") => {
        length += subStr.length;
        if (length <= limitlength) {
          return subStr;
        }
        return "";
      })
      .filter((subStr) => subStr !== "")
      .join(" ")
  );
}

export const getExt = (filename) => filename.split(".").pop();

export const convertToFormData = (files) => {
  let formData = new FormData();
  if (Array.isArray(files)) {
    files.forEach((file) => {
      formData.append(file.name, file.value, file.filename);
    });
  } else {
    formData.append(files.name, files.value, files.filename);
  }
  return formData;
};

export const getImageUrl = (url) => {
  if (Array.isArray(url)) {
    return url.map((u) => {
      if (u.indexOf(IMAGES_BASE_URL1) > -1) {
        return url;
      } else if (u.indexOf(IMAGES_BASE_URL) === -1) {
        return IMAGES_BASE_URL + u;
      }
      return u;
    });
  } else {
    if (url.indexOf(IMAGES_BASE_URL1) > -1) {
      return url;
    } else if (typeof url === "string" && url.indexOf(IMAGES_BASE_URL) === -1) {
      return IMAGES_BASE_URL + url;
    }
  }
  return "" + url;
};

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export const bookingSlots = ["9am-12pm", "12pm-3pm", "3pm-6pm", "6pm-9pm"];
