import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import swal from "sweetalert";
import CustomPagination from "../../../components/Common/CustomPagination";
import {
  doGetIncompleteTransaction,
  doGetIncompleteTransactionCount,
  doGetTechnicianDetailsForExcel,
  doPutCompletePayout,
} from "../../../services/apiServices";
import XLSX from "xlsx";
let data = [];
export default class Payout extends Component {
  state = {
    wallet: {},
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
  };

  componentDidMount() {
    // this.getTransactions(0, this.state.pagination.limit);
    this.getTransactions();
  }
  // getTransactions = (currentPageNo, limit) => {
  //   let query = `_limit=${limit}&_start=${(currentPageNo*limit)}`;
  //   doGetIncompleteTransactionCount().then((res1) => {
  //     this.setState((prev) => {
  //       let st = prev;
  //       st.pagination.totalPages = res1.data / st.pagination.limit;
  //       st.pagination.currentPageNo = currentPageNo;
  //       return st;
  //     });
  //     doGetIncompleteTransaction(query).then(
  //       (res) => {
  //         this.setState({ wallet: res });
  //         //console.log(res);
  //       },
  //       (err) => {}
  //     );
  //   });
  // };
  getTransactions = () => {
    doGetIncompleteTransaction().then(
      (res) => {
        this.setState({ wallet: res });
        console.log("res", res);
      },
      (err) => { }
    );
  };
  // doPagination = (currentPageNo) => {
  //   this.getTransactions(currentPageNo, this.state.pagination.limit);
  // };

  handlePayNow = (arr) => {
    //console.log(arr);
    doPutCompletePayout(arr).then(
      (res) => {
        swal("Success", "Successfully Paid", "success");
        this.componentDidMount();
      },
      (err) => {
        swal("Error", "", "error");
      }
    );
  };
  handleExportExcel = (step) => {
    const promises = [];
    let newArr = [...new Map(data.map((v) => [v.id, v])).values()],
      i = 0;
    newArr.forEach((item, key) => {
      i++;
      const promise = doGetTechnicianDetailsForExcel(
        "technician.id=" + item.id
      ).then((res) => {
        data[key].technician = res.data[0];
        //console.log(key, newArr.length);
        if (key === newArr.length - 1) {
          generateExcel(data);
        }
      });
    });

    // //console.log(newArr);

    function generateExcel(newArr) {
      let finalArr = new Array();
      //console.log(newArr);
      newArr.map((el, i) => {
        if (step === 0) {
          finalArr.push({
            transaction_type: "",
            debit_account: "",
            amount: "",
            currency: "",
            bank_account: "",
            ifsc: "",
            particulars: "",
          });
          finalArr[i].transaction_type = "NFT";
          finalArr[i].debit_account = "153001111111111";
          finalArr[i].amount = el.amount;
          finalArr[i].currency = "INR";
          finalArr[i].bank_account = el.technician
            ? el.technician.bank_account_number
            : "";
          finalArr[i].ifsc = el.technician ? el.technician.ifsc : "";

          finalArr[i].particulars =
            "The user has to pay the amount to the technician";
        } else {
          finalArr.push({
            ifsc: "",
            bank_account: "",
            dr_cr: "",
            currency: "",
            amount: "",
            particulars: "",
          });

          finalArr[i].ifsc = el.technician
            ? el.technician.ifsc.substr(el.technician.ifsc.length - 6)
            : "";
          finalArr[i].bank_account = el.technician
            ? el.technician.bank_account_number
            : "";
          finalArr[i].dr_cr = "DR";
          finalArr[i].currency = "INR";
          finalArr[i].amount = el.amount;
          finalArr[i].particulars =
            "The user has to pay the amount to the technician";
        }

        // finalArr[i].bankname = el.technician.bankname;
        // finalArr[i].ifsc = el.technician.ifsc;
        // delete el.technician;
      });
      //console.log(finalArr);
      let binaryWS = XLSX.utils.json_to_sheet(finalArr, { skipHeader: 1 });

      // Create a new Workbook
      var wb = XLSX.utils.book_new();

      // Name your sheet
      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      // export your excel
      if (step == 0) XLSX.writeFile(wb, "homvery_nft_excel.xlsx");
      else XLSX.writeFile(wb, "homvery_vendor_excel.xlsx");
    }
  };

  render() {
    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Payout</h4>
          <div>
            <span>
              <div
                className="btn btn-primary waves-effect waves-light btn-sm mr-3"
                onClick={() => this.handleExportExcel(0)}
              >
                RTG/NEFT <i className="mdi mdi-download ml-1"></i>
              </div>
            </span>
            <span>
              <div
                className="btn btn-primary waves-effect waves-light btn-sm"
                onClick={() => this.handleExportExcel(1)}
              >
                Vendor excel <i className="mdi mdi-download ml-1"></i>
              </div>
            </span>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <Table
              striped
              bordered
              hover
              className="table mb-3 pr-0"
              id="my-table-id"
              style={{ verticalAlign: "middle" }}
            >
              <tr>
                <th>Name</th>
                <th>Wallet</th>
                <th>Total Amount</th>
                <th>Pay Now</th>
              </tr>
              {console.log('wallet', this.state.wallet)}
              {this.state.wallet &&
                Object.entries(this.state.wallet).map(([key, value], j) => {
                  let amount = 0,
                    id,
                    username,
                    transactionsArr = [],
                    dataArr = [];
                  value.map((el) => {
                    amount += el.amount;
                    transactionsArr.push(el.id);

                    id = el.wallet.user.id;
                    username = el.wallet.user.firstname+' '+el.wallet.user.lastname
                    
                  });
                  data.push({ walletid: key, id, amount });
                  return (
                    <tr key={j}>
                      <td>{username}</td>
                      <td>{key}</td>
                      <td>{amount}</td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => this.handlePayNow(transactionsArr)}
                        >
                          Pay Now
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              {/* {this.state.wallet.map((wallet) => {
            // //console.log(offer.newuser);

            return (
              <tr>
                <td>{offer.id}</td>
                <td>{offer.newuser ? "true" : "false"}</td>
                <td>{offer.city.name}</td>
                <td>{offer.service.name}</td>
                <td>
                  {moment(offer.service.published_at)
                    .utc()
                    .format("DD MMM YYYY HH:mm:ss")}
                </td>
              </tr>
            );
          })} */}
            </Table>
          </Col>
          {/* <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col> */}
        </Row>
      </div>
    );
  }
}
