import React from 'react';

export const Offsymbol = props => {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						fontSize: 12,
						color: "#fff",
						paddingRight: 2
					}}
				>
					{" "}
          No
				</div>
			);
};

export const OnSymbol = props => {
			return (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						height: "100%",
						fontSize: 12,
						color: "#fff",
						paddingRight: 2
					}}
				>
					{" "}
          Yes
				</div>
			);
		};