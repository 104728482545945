import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, Col, Row, Table } from "reactstrap";
import {
  doGetBookings,
  doGetCustomerBooking,
} from "../../../../services/apiServices";

export default function ViewCustomer() {
  const { id } = useParams();
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    doGetCustomerBooking(id).then(
      (res) => {
        setBookings(res.data);
        console.log(res.data);
      },
      (err) => {}
    );
  }, []);

  return (
    <div className="page-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <h4 className="mb-0 font-size-18">Customer Bookings</h4>
        {/* <span>
  <Link
    to="/offers/add"
    className="btn btn-primary waves-effect waves-light btn-sm"
  >
    Add New Offer <i className="mdi mdi-arrow-right ml-1"></i>
  </Link>
</span> */}
      </div>
      <Row>
        <Col md={12}>
          <Table
            striped
            bordered
            hover
            className="table mb-3 pr-0"
            style={{ verticalAlign: "middle" }}
          >
            <tr>
              <th>Booking ID</th>
              <th>Phone no</th>
              <th>City</th>
              <th>Service</th>
              <th>From Date</th>

              <th>View Details</th>
            </tr>

            <tbody>
              {bookings.length > 0 &&
                bookings.map((transaction, key) => (
                  <tr key={"_tr_" + key}>
                    {/* <td>
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={transaction.id}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={transaction.id}
                            >
                              &nbsp;
                            </label>
                          </div>
                        </td> */}
                    <td>
                      <Link to="#" className="text-body font-weight-bold">
                        {" "}
                        {transaction.bookingId}{" "}
                      </Link>{" "}
                    </td>

                    <td>{transaction.address.phoneNumber}</td>
                    <td>{transaction.address.city}</td>
                    <td>
                      {transaction.serviceid ? transaction.serviceid.name : ""}
                    </td>

                    <td>
                      {moment(transaction.fromtime).format(
                        "DD MMM YYYY hh:mm:ss a"
                      )}
                    </td>

                    <td>
                      <Link to={`/booking/${transaction.id}`}>
                        <Button
                          type="button"
                          color="primary"
                          size="sm"
                          className="btn-rounded waves-effect waves-light"
                        >
                          View Details
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
}
