import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import swal from "sweetalert";
import { doUploadFiles } from "../../../constants/functions";
import { doGetSliderById, doPutSlider } from "../../../services/apiServices";
import SliderFormComponent from "./SliderFormComponent";

class EditSlider extends Component {
  state = {
    title: "",
    type: "",
    order: "",
    image: "",
    iframeurl: "",
    description: "",
    active: true,
    backendImage: "",
  };

  handleChange = (key, value) => {
    this.setState((st) => {
      let temp = st;
      temp[key] = value;
      return temp;
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const callback = (images = [], replace = true) => {
      let request = {
        title: this.state.title,
        type: this.state.type,
        order: this.state.order,
        image: images.length > 0 ? images[0].id : this.state.backendImage.id,
        iframeUrl: this.state.iframeurl,
        description: this.state.description,
        active: this.state.active,
      };
      doPutSlider(this.props.match.params.id, request).then(
        (res) => {
          //console.log(res.data);
          swal("Success", "Slider updated successfully", "success");
          const { location, history } = this.props;
          history.push("/slider/all");
        },
        (err) => {
          //console.log(err);
        }
      );

      //console.log(request);
    };
    if (this.state.image !== "") {
      let image = {
        name: "files",
        value: this.state.image,
        filename: this.state.image.name,
      };
      doUploadFiles([image], callback);
    } else {
      callback([], false);
    }
  };
  componentDidMount() {
    doGetSliderById(this.props.match.params.id).then(
      (res) => {
        //console.log(res.data);
        this.setState((prev) => {
          let st = prev;
          st.title = res.data.title;
          st.type = res.data.type;
          st.order = res.data.order;

          st.iframeurl = res.data.iframeUrl;
          st.description = res.data.description;
          st.active = res.data.active;
          st.backendImage = res.data.image;
          return st;
        });
      },
      (err) => {}
    );
  }
  render() {
    let id = this.props.match.params.id;

    return (
      <div className="page-content">
        <div>
          <h4 className="mb-0 font-size-18">Edit Slider</h4>
        </div>
        <SliderFormComponent
          state={this.state}
          handleChange={this.handleChange}
        />

        <Row>
          <Col md={6}>
            <Button
              color="primary"
              type=""
              onClick={(e) => this.handleSubmit(e)}
            >
              Save
            </Button>
          </Col>
          <Col md={6} style={{ marginTop: "-50px" }}>
            <h6>Posted Image</h6>
            <img
              src={this.state.backendImage?.url}
              alt=""
              width="200px"
              height="200px"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(EditSlider);
