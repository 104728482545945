import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class StackedColumnChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {
        chart: {
          height: 359,
          type: "bar",
          stacked: !0,
          toolbar: {
            show: 1,
          },
          zoom: {
            enabled: !0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: "15%",
            // endingShape: "rounded"
          },
        },
        dataLabels: {
          enabled: !1,
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
        },
        colors: ["#556ee6", "#f1b44c", "#34c38f"],
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      series: [
        {
          name: "Series A",
          data: [44, 55, 41, 67, 22, 43, 36, 52, 24, 18, 36, 48],
        },
      ],
    };
  }
  //   getData = () => {
  //     let arr = [],
  //       data = [...this.props.revenueArraybyMonth];
  //     for (let i = 0; i < 12; i++) {
  //       arr.push(
  //         this.props.revenueArraybyMonth.length > i + 1
  //           ? this.props.revenueArraybyMonth[i].revenue
  //           : 0
  //       );
  //     }
  //     let series = [
  //       {
  //         name: "Bookings",
  //         data: arr,
  //       },
  //     ];
  //     console.log(arr);
  //     this.setState({ series: series });
  //   };

  //   componentDidUpdate(prevProps, prevState) {
  //     if (prevProps.revenueArraybyMonth !== this.props.revenueArraybyMonth) {
  //       this.getData();
  //     }
  //   }
  //   componentDidMount() {
  //     this.getData();
  //   }

  render() {
    const { revenueArraybyMonth } = this.props;
    // console.log(revenueArraybyMonth);
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={revenueArraybyMonth}
          type="bar"
          height="359"
        />
      </React.Fragment>
    );
  }
}

export default StackedColumnChart;
