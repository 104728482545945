import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Button,
  TabPane,
  TabContent,
  NavItem,
  NavLink,
  CardBody,
  Card,
  CardHeader,
} from "reactstrap";
import {
  ImageModal,
  LocationModal,
  TechnicianCityPincodeCustomModal,
  TechnicianCityPincodeModal,
  TechnicianDetailsModal,
  TechnicianServiceModal,
  UserAddressModal,
  UserModal,
  UserRoleModal,
} from "../../../../classes/classes";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../../components/homevery/SwitchSymbol/SwitchSymbol";
import {
  convertToFormData,
  getExt,
  getImageUrl,
  getValidatedData,
  numbersregexp,
} from "../../../../constants/constants";
import {
  getConvertedRoles,
  getConvertedUsers,
} from "../../../../constants/conversions/users";
import {
  doGetAllLocations,
  doGetAllServices,
  doGetAllUserRoles,
  doGetAllUsers,
  doGetTechincianService,
  doGetTechnicianDetailByTech,
  doPostCreateTechnician,
  doPostCreateUser,
  doPostTechincalDetails,
  doPostTechincianService,
  doPostUploadFiles,
  doPutTechincianService,
  doPutUpdateUser,
  doUpdateTechincalDetails,
} from "../../../../services/apiServices";
import { changePreloaderStatus } from "../../../../store/layout/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import swal from "sweetalert";
import classnames from "classnames";

//i18n
import { withNamespaces } from "react-i18next";
import { getConvertedTechnicianDetails } from "../../../../constants/conversions/techniciandetails";
import { getConvertedServices } from "../../../../constants/conversions/services";
import { getConvertedLocations } from "../../../../constants/conversions/locations";
import AddressCard from "../Components/AddressCard";
import { doUploadFiles } from "../../../../constants/functions";
const animatedComponents = makeAnimated();
const restrictedext = ["png", "jpg", "jpeg", "webp"];
const relationShips = [
  "Father",
  "Mother",
  "Son",
  "Sister",
  "Brother",
  "Daughter",
  "Wife",
];
const bloodGroups = ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"];
class AddTechnician extends Component {
  state = {
    action: "add",
    user: new UserModal(),
    userProfileUpload: {
      file: {
        name: "files",
        value: "",
        filename: "",
      },
      count: 0,
    },
    userSignatureUpload: {
      file: {
        name: "files",
        value: "",
        filename: "",
      },
      count: 0,
    },
    covidUpload: {
      file: {
        name: "files",
        value: "",
        filename: "",
      },
      count: 0,
    },
    techicianDetails: new TechnicianDetailsModal(),
    services: [],
    locations: [],
    imagesFileCount: 0,
    imageSelected: [],
    roles: [],
    breadcrumbItems: [],
    activeTab: 1,
    detailsResponse: false,
  };

  componentDidMount() {
    const queryPath = this.props.match.params;
    this.initData(queryPath.id);
    this.getAllRoles();
  }

  getTechicianDetailsClubbedData = async (id, user) => {
    const locations = await this.getAllLocations();
    const services = await this.getAllServices();
    const details = await this.getTechnicianDetails("", id, user);
    this.setState((prev) => {
      let st = prev;
      st.techicianDetails = details;
      st.userSignatureUpload = {
        file: {
          name: "files",
          value: "",
          filename: "",
        },
        count: 0,
      };
      st.techicianDetails.services = details.services.map((service) => {
        //console.log(service.cityPincodes);
        service.cityPincodes = service.cityPincodes.map((cp) => {
          const filterCities = locations.filter((l) => l.id === cp.city.id);
          if (filterCities.length > 0)
            cp.allpincodes = filterCities[0].pincodes.filter((c) => c.active);
          return cp;
        });
        return service;
      });
      st.detailsResponse = true;
      return st;
    });
  };

  getAllLocations = async () => {
    let locations = [];
    await doGetAllLocations().then(
      (res) => {
        locations = getConvertedLocations(getValidatedData(res.data, "array"));
        this.setState({
          locations: locations,
        });
      },
      (err) => {
        this.setState({
          locations: [],
        });
      }
    );
    return locations;
  };

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        user: new UserModal(),
        userProfileUpload: {
          file: {
            name: "files",
            value: "",
            filename: "",
          },
          count: 0,
        },
        breadcrumbItems: [
          {
            active: true,
            route: "/users/technicians/all",
            title: "Technicians",
          },
          {
            active: true,
            route: "#",
            title: "New Technician",
          },
        ],
        techicianDetails: new TechnicianDetailsModal(),
        imagesFileCount: 0,
        imageSelected: [],
        activeTab: 1,
      });
    } else {
      const callback = async (user) => {
        await this.getTechicianDetailsClubbedData(id, user);
      };
      this.getUserData(id, callback);
    }
  };

  getAllServices = async () => {
    let services = [];
    await doGetAllServices().then(
      (res) => {
        services = getConvertedServices(getValidatedData(res.data, "array"));
        this.setState({
          services: services,
        });
      },
      (err) => {
        this.setState({
          services: [],
        });
      }
    );
    return services;
  };

  getTechnicianDetails = async (
    id = "",
    technicianId = "",
    user = new UserModal()
  ) => {
    let details = new TechnicianDetailsModal();
    await doGetTechnicianDetailByTech(technicianId).then(
      (res) => {
        if (getValidatedData(res.data, "object", true)) {
          const result = getValidatedData(
            res.data.technician_details,
            "object"
          );
          result.services = getValidatedData(
            res.data.technician_services_map,
            "array"
          );
          const alldata = getConvertedTechnicianDetails([result]);
          //console.log(alldata);
          if (alldata.length > 0) {
            details = alldata[0];
          }
        }
      },
      (err) => {}
    );
    // await doGetTechincianService(id, technicianId).then(
    //   (res) => {
    //     if (getValidatedData(res.data, "array") && res.data.length > 0) {
    //       const alldata = getConvertedTechnicianDetails(res.data);
    //       if (alldata.length > 0) {
    //         this.setState((prev) => {
    //           let st = prev;
    //           st.techicianDetails = alldata[0];
    //           details = alldata[0];
    //           return st;
    //         });
    //       } else {
    //         this.setState((prev) => {
    //           let st = prev;
    //           st.techicianDetails = new TechnicianDetailsModal(0, user);
    //           return st;
    //         });
    //       }
    //       //console.log(alldata);
    //     } else {
    //       this.setState((prev) => {
    //         let st = prev;
    //         st.techicianDetails = new TechnicianDetailsModal(0, user);
    //         return st;
    //       });
    //     }
    //   },
    //   (err) => {}
    // );
    return details;
  };

  getUserData = (id = "", callback = (...args) => {}) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers(id).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        callback(users[0]);
        this.setState({
          action: "edit",
          user: users[0],
          userProfileUpload: {
            file: {
              name: "files",
              value: "",
              filename: "",
            },
            count: 0,
          },
          breadcrumbItems: [
            {
              active: true,
              route: "/users/technicians/all",
              title: "Technicians",
            },
            {
              active: true,
              route: "/users/technicians/" + id,
              title: users[0].getFullName(),
            },
          ],
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        callback(new UserModal());
        this.setState({
          action: "add",
          user: new UserModal(),
          userProfileUpload: {
            file: {
              name: "files",
              value: "",
              filename: "",
            },
            count: 0,
          },
          breadcrumbItems: [
            {
              active: true,
              route: "/users/technicians/all",
              title: "Technicians",
            },
            {
              active: true,
              route: "#",
              title: "New Technician",
            },
          ],
        });
      }
    );
  };

  getAllRoles = () => {
    doGetAllUserRoles().then(
      (res) => {
        let roles = [];
        if (res.data && res.data.roles) {
          roles = getValidatedData(res.data.roles, "array");
        }
        this.setState({
          roles: getConvertedRoles(roles).filter(
            (role) => role.type.toLowerCase() === "technician"
          ),
        });
      },
      (err) => {
        this.setState({
          roles: [],
        });
      }
    );
  };

  onHandleServiceChange = (e, index, index1) => {
    const { name, value, files } = e.target;

    switch (name) {
      case "covidUpload":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (["png", "jpg", "jpeg", "webp", "pdf"].indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.covidUpload.file = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.covidUpload.file = {
              name: "files",
              value: "",
              filename: "",
            };
            st.covidUpload.count++;
            return st;
          });
        }
        break;
      case "signature":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.userSignatureUpload.file = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.userSignatureUpload.file = {
              name: "files",
              value: "",
              filename: "",
            };
            st.userSignatureUpload.count++;
            return st;
          });
        }
        break;
      case "deleteService":
        this.setState((prev) => {
          let st = prev;
          const cps = st.techicianDetails.services[index].cityPincodes.filter(
            (s) => s.id !== 0
          );
          if (cps.length === 0) {
            st.techicianDetails.services.splice(index, 1);
          } else {
            st.techicianDetails.services[index].active = false;
            st.techicianDetails.services[index].cityPincodes
              .map((s) => {
                s.active = false;
                return s;
              })
              .filter((s) => s.id !== 0);
          }
          return st;
        });
        break;
      case "deleteCityPincode":
        this.setState((prev) => {
          let st = prev;
          if (
            st.techicianDetails.services[index].cityPincodes[index1].id === 0
          ) {
            st.techicianDetails.services[index].cityPincodes.splice(index1, 1);
          } else {
            st.techicianDetails.services[index].cityPincodes[
              index1
            ].active = false;
          }
          return st;
        });
        break;
      case "pincode":
        this.setState((prev) => {
          let st = prev;
          const selectedLocations = st.locations.filter(
            (s) =>
              s.id ===
              st.techicianDetails.services[index].cityPincodes[index1].city.id
          );
          if (selectedLocations.length > 0 && value !== null) {
            const pincodeData = value
              .map((v) => {
                const k = selectedLocations[0].pincodes.filter((s) => {
                  return "" + s.code === "" + v.value;
                });
                if (k.length > 0) {
                  return k[0];
                }
                return false;
              })
              .filter((k) => k);
            //console.log(pincodeData);
            st.techicianDetails.services[index].cityPincodes[index1].pincodes =
              pincodeData.map((p) => "" + p.code);
          } else {
            st.techicianDetails.services[index].cityPincodes[index1].pincodes =
              [];
          }
          return st;
        });
        break;
      case "city":
        this.setState((prev) => {
          let st = prev;
          const selectedLocations = st.locations.filter((s) => s.id === value);
          //console.log(value);
          //console.log(selectedLocations);
          if (selectedLocations.length > 0) {
            if (
              st.techicianDetails.services[index].cityPincodes[index1].city
                .id !== selectedLocations[0].id
            ) {
              st.techicianDetails.services[index].cityPincodes[
                index1
              ].pincodes = [];
            }
            st.techicianDetails.services[index].cityPincodes[index1].city =
              selectedLocations[0];
            st.techicianDetails.services[index].cityPincodes[
              index1
            ].allpincodes = selectedLocations[0].pincodes.filter(
              (p) => p.active
            );

            //console.log("124");
            //console.log(selectedLocations[0].id);
            //console.log(
            //   st.techicianDetails.services[index].cityPincodes[index1].city.id
            // );
          }
          return st;
        });
        break;
      case "service":
        this.setState((prev) => {
          let st = prev;
          const selectedServices = st.services.filter((s) => s.id === value);
          if (selectedServices.length > 0) {
            if (
              st.techicianDetails.services[index].service.id !==
              selectedServices[0].id
            ) {
              st.techicianDetails.services[index].cityPincodes = [];
            }
            st.techicianDetails.services[index].service = selectedServices[0];
          }

          return st;
        });
        break;
      case "bankName":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.bankName = value;
          return st;
        });
        break;
      case "ifsc":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.ifsc = value;
          return st;
        });
        break;
      case "accountHolderName":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.accountHolderName = value;
          return st;
        });
        break;
      case "bankAccountNumber":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value))
          st.techicianDetails.bankAccountNumber = value;
          return st;
        });
        break;
      case "familyContactName":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.familyContactName = value;
          return st;
        });
        break;
      case "familyContactNumber":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value))
            st.techicianDetails.familyContactNumber = value;
          return st;
        });
        break;
      case "familyMemberRelationship":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.familyMemberRelationship = value;
          return st;
        });
        break;
      case "bloodGroup":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.bloodGroup = value;
          return st;
        });
        break;
      case "aadhaar":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value) && value.length <= 16)
            st.techicianDetails.aadhaar = value;
          return st;
        });
        break;
      case "experience":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.experience = value;
          return st;
        });
        break;
      case "deposit":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.deposit = value;
          return st;
        });
        break;
      case "training":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.training = value;
          return st;
        });
        break;
      case "tshirt":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.tshirt = value;
          return st;
        });
        break;
      case "available":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.available = value;
          return st;
        });
        break;
      case "approved":
        this.setState((prev) => {
          let st = prev;
          st.techicianDetails.approved = value;
          return st;
        });
        break;
      case "aadhaarfiles":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.imageSelected = imagesSelected;
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.imageSelected = [];
            st.imagesFileCount++;
            return st;
          });
        }

        break;
      default:
    }
  };

  onHandleChange = (e) => {
    const { name, files, value } = e.target;
    switch (name) {
      case "profilePic":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.userProfileUpload.file = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.userProfileUpload.file = {
              name: "files",
              value: "",
              filename: "",
            };
            st.userProfileUpload.count++;
            return st;
          });
        }
        break;
      case "firstName":
        this.setState((prev) => {
          let st = prev;
          st.user.firstName = value;
          return st;
        });
        break;
      case "lastName":
        this.setState((prev) => {
          let st = prev;
          st.user.lastName = value;
          return st;
        });
        break;
      case "userName":
        this.setState((prev) => {
          let st = prev;
          st.user.userName = value;
          return st;
        });
        break;
      // case "email":
      //   this.setState((prev) => {
      //     let st = prev;
      //     st.user.email = value;
      //     return st;
      //   });
      //   break;
      case "password":
        this.setState((prev) => {
          let st = prev;
          st.user.password = value;
          return st;
        });
        break;
      case "dob":
        this.setState((prev) => {
          let st = prev;
          st.user.dob = value;
          return st;
        });
        break;
      case "loginType":
        this.setState((prev) => {
          let st = prev;
          st.user.loginType = value;
          return st;
        });
        break;
      case "mobile":
        this.setState((prev) => {
          let st = prev;
          st.user.mobile = value;
          st.user.userName = "HOMTECH-" + st.user.mobile;
          st.user.email="techhomvery"+this.state.user.mobile+"@gmail.com";
          return st;
        });
        break;
      case "alternateMobile":
        this.setState((prev) => {
          let st = prev;
          st.user.alternateMobile = value;
          return st;
        });
        break;
      case "role":
        this.setState((prev) => {
          let st = prev;
          const sRoles = st.roles.filter((role) => "" + role.id === "" + value);
          st.user.role = sRoles.length > 0 ? sRoles[0] : new UserRoleModal();
          return st;
        });
        break;
      case "confirmed":
        this.setState((prev) => {
          let st = prev;
          st.user.confirmed = value;
          return st;
        });
        break;
      case "blocked":
        this.setState((prev) => {
          let st = prev;
          st.user.blocked = value;
          return st;
        });
        break;
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  doFormSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPostCreateTechnician(JSON.stringify(request)).then(
      (res) => {
        const users = getConvertedUsers([
          getValidatedData(res.data.user, "object"),
        ]);
        this.setState(
          {
            action: "edit",
            user: users[0],
            activeTab: 2,
          },
          () => {
            const selectedRole = this.state.roles.filter(
              (role) => role.type.toLowerCase() === "technician"
            );
            const request1 = {
              role: selectedRole.length > 0 ? selectedRole[0].id : undefined,
            };
            this.doFormUpdateSubmit(request1, users[0].id);
            // swal("Success", "Succesfully Created", "success");
            this.props.history.push("/users/technicians/" + users[0].id);
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  serviceTecSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(changePreloaderStatus(true));
    const callback = (images1 = [], replace = true) => {
      let request = this.state.techicianDetails.getAddRequest(this.state.user);
      if (replace) {
        request.technician_details.signature =
          images1.length > 0 ? images1[0].id : undefined;
      }

      const callback1 = (images) => {
        const images1 = images.map((image) => ({ id: image.id }));
        request.technician_details.aadharcard_photo =
          request.technician_details.aadharcard_photo.concat([], images1);
        const callbackForm = (request1) => {
          this.doFormServiceSubmit(request1);
        };

        if (this.state.covidUpload.file.value !== "") {
          const covidFiles = (covidC = []) => {
            const covidCs = covidC.map((image) => ({ id: image.id }));
            if (covidCs.length > 0) {
              request.technician_details.covidcertificate = covidCs[0];
            }
            callbackForm(request);
          };
          doUploadFiles([this.state.covidUpload.file], covidFiles);
        } else {
          callbackForm(request);
        }
      };
      if (this.state.imageSelected.length > 0) {
        const imageData = this.state.imageSelected;
        this.onUploadFiles(imageData, callback1);
      } else {
        callback1([]);
      }
    };
    if (this.state.userSignatureUpload.file.value !== "") {
      doUploadFiles([this.state.userSignatureUpload.file], callback);
    } else {
      callback([], false);
    }
  };

  onUploadFiles = (files, callback = () => {}) => {
    const formDatas = files.map((file) => convertToFormData(file));
    doPostUploadFiles(formDatas).then(
      (res) => {
        //console.log(res);
        callback(
          res
            .map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 }))
            .filter((r) => r.id !== -1)
        );
      },
      (err) => {
        callback([]);
      }
    );
  };

  doFormServiceSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    if (this.state.techicianDetails.id === 0) {
      // request.technician_details.approved = true;
      request.technician_details.technician = this.state.user.id;
      request.technician_details.available = true;
    }
    //console.log(request);
    (this.state.techicianDetails.id === 0
      ? doPostTechincalDetails(request)
      : doUpdateTechincalDetails(request)
    ).then(
      async (res) => {
        if (res.data) {
          await this.getTechicianDetailsClubbedData(this.state.user.id);
          this.props.dispatch(changePreloaderStatus(false));
        }
        swal("Success", "Succesfully Submitted", "success");
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  doFormUpdateSubmit = (request, id = -1) => {
    const userId = id !== -1 ? id : this.state.user.id;
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateUser(userId, JSON.stringify(request)).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        this.setState(
          {
            activeTab: 2,
            action: "edit",
            user: users[0],
          },
          () => {
            // if (id === -1) swal("Success", "Succesfully Updated", "success");
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  getUnSelectedServices = (all, selected) => {
    return all.filter((a) => {
      const k = selected.filter((s) => {
        const g = s.service.id === a.id;
        const cps = s.cityPincodes.filter((cp) => cp.active);
        return g && cps.length > 0;
      });
      return !(k.length > 0);
    });
  };

  getUnSelectedServiceCities = (all, selected) => {
    const selected1 = selected.filter((s) => s.active);
    return all.filter((a) => {
      const k = selected1.filter((s) => {
        return s.city.id === a.city.id;
      });
      return !(k.length > 0);
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const user = this.state.user;
    if (
      (this.state.action === "edit" &&
        user.role.id !== 0 &&
        user.loginType !== "") ||
      (this.state.action === "add" && user.loginType !== "")
    ) {
      this.props.dispatch(changePreloaderStatus(true));
      const callback = (images = [], replace = true) => {
        let request = null;
        if (this.state.action === "add") {
          request = user.getAddRequest(this.props.loginUser);
          request.email="techhomvery"+request.phonenumber+"@gmail.com";
          if (replace) {
            request.profilepic = images.length > 0 ? images[0].id : undefined;
          }
          this.doFormSubmit(request);
        } else {
          request = user.getUpdateRequest(this.props.loginUser);
          request.email="techhomvery"+request.phonenumber+"@gmail.com";
          if (replace) {
            request.profilepic = images.length > 0 ? images[0].id : undefined;
          }
          this.doFormUpdateSubmit(request);
        }
      };
      if (this.state.userProfileUpload.file.value !== "") {
        doUploadFiles([this.state.userProfileUpload.file], callback);
      } else {
        callback([], false);
      }
    }
  };

  setActiveTab = (i) => {
    if (i > 0)
      this.setState({
        activeTab: i,
      });
  };

  onAddTecService = () => {
    this.setState((prev) => {
      let st = prev;
      st.techicianDetails.services.push(new TechnicianServiceModal());
      return st;
    });
  };

  addCityPincode = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.techicianDetails.services[index].cityPincodes.push(
        new TechnicianCityPincodeCustomModal()
      );
      return st;
    });
  };

  onHandleAddress = (e, index) => {
    const { name, value } = e.target;
    switch (name) {
      case "flat":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].flat = value;
          return st;
        });
        break;
      case "street":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].street = value;
          return st;
        });
        break;
      case "addressline1":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].addressline1 = value;
          return st;
        });
        break;
      case "landmark":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].landmark = value;
          return st;
        });
        break;
      case "city":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].city = value;
          return st;
        });
        break;
      case "pincode":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value) && value.length <= 6)
            st.user.addresses[index].pincode = value;
          return st;
        });
        break;
      case "active":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].active = value;
          return st;
        });
        break;
      case "alias":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].name = value;
          return st;
        });
        break;

      default:
    }
  };

  addAddress = () => {
    this.setState((prev) => {
      let st = prev;
      st.user.addresses.push(new UserAddressModal());
      return st;
    });
  };

  onDeleteAddress = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.user.addresses.splice(index, 1);
      return st;
    });
  };

  onDeleteProfilePic = () => {
    this.setState((prev) => {
      let st = prev;
      st.user.profilePic = new ImageModal();
      return st;
    });
  };

  onDeleteSignaturePic = () => {
    this.setState((prev) => {
      let st = prev;
      st.techicianDetails.signature = new ImageModal();
      return st;
    });
  };

  onDeleteCovid = () => {
    this.setState((prev) => {
      let st = prev;
      st.techicianDetails.covidcertificate = new ImageModal();
      return st;
    });
  };

  render() {
    const loginTypes = ["mobile", "web"];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " Technician Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTab === 1,
                            })}
                            onClick={() => this.setActiveTab(1)}
                          >
                            <span className="step-number mr-2">01</span>
                            Personal Details
                          </NavLink>
                        </NavItem>
                        {this.state.user.id !== 0 ? (
                          <>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === 2,
                                })}
                                onClick={() => this.setActiveTab(2)}
                              >
                                <span className="step-number mr-2">02</span>
                                <span>Other Details</span>
                              </NavLink>
                            </NavItem>
                          </>
                        ) : (
                          <>
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === 2,
                                })}
                              >
                                <span className="step-number mr-2">02</span>
                                <span>Other Details</span>
                              </NavLink>
                            </NavItem>
                          </>
                        )}
                      </ul>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form
                            className="row userForm"
                            onSubmit={this.onFormSubmit}
                          >
                            <Col md={6}>
                              <FormGroup>
                                <Label>First Name</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="firstName"
                                  type="text"
                                  className="form-control"
                                  value={this.state.user.firstName}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Surname</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="lastName"
                                  type="text"
                                  className="form-control"
                                  value={this.state.user.lastName}
                                  required
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label>Mobile Number</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="mobile"
                                  type="text"
                                  className="form-control"
                                  value={this.state.user.mobile}
                                  disabled={
                                    this.state.action === "edit" ? true : false
                                  }
                                  pattern="[6789]{1,1}[0-9]{9}"
                                  required
                                />
                              </FormGroup>
                            </Col>

                            {/* <Col md={6}>
                              <FormGroup>
                                <Label>Email</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="email"
                                  type="email"
                                  className="form-control"
                                  value={this.state.user.email}
                                  required
                                />
                              </FormGroup>
                            </Col> */}
                            <Col md={6}>
                              <FormGroup>
                                <Label>Username</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="userName"
                                  type="text"
                                  className="form-control"
                                  disabled={true}
                                  value={"HOMTECH-" + this.state.user.mobile}
                                  required
                                />
                              </FormGroup>
                            </Col>

                            <Col md={6}>
                              <FormGroup>
                                <Label>Alternate Mobile Number</Label>
                                <Input
                                  onChange={this.onHandleChange}
                                  name="alternateMobile"
                                  type="text"
                                  className="form-control"
                                  value={this.state.user.alternateMobile}
                                  pattern="[6789]{1,1}[0-9]{9}"
                                  required={
                                    this.state.user.alternateMobile.trim() !==
                                    ""
                                  }
                                />
                              </FormGroup>
                            </Col>
                            {/* {this.state.action === "add" ? (
                              <Col md={6}>
                                <FormGroup>
                                  <Label>Password</Label>
                                  <Input
                                    onChange={this.onHandleChange}
                                    name="password"
                                    type="text"
                                    className="form-control"
                                    value={this.state.user.password}
                                    required={
                                      this.state.action === "add" ||
                                      this.state.user.password !== ""
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            ) : (
                              ""
                            )} */}
                            {this.state.user.profilePic.id !== 0 ? (
                              <>
                                <Col md={6}>
                                  <FormGroup>
                                    <img
                                      alt="."
                                      src={getImageUrl(
                                        this.state.user.profilePic.url
                                      )}
                                      width="100"
                                      className="img-fluid d-inline-block"
                                    />
                                    <i
                                      className="fa fa-trash text-danger ml-2 cursor-pointer"
                                      onClick={this.onDeleteProfilePic}
                                    ></i>
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Profile Pic</Label>
                                    <Input
                                     
                                      onChange={this.onHandleChange}
                                      name="profilePic"
                                      type="file"
                                      key={
                                        "userpic" +
                                        this.state.userProfileUpload.count
                                      }
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            )}
                            <Col md={6}>
                              <FormGroup>
                                <Label>Dob</Label>
                                <Input
                                  required
                                  onChange={this.onHandleChange}
                                  name="dob"
                                  type="date"
                                  className="form-control"
                                  value={this.state.user.dob}
                                />
                              </FormGroup>
                            </Col>
                            {/* <Col md={6}>
                              <FormGroup>
                                <Label>Login Type</Label>
                                <Select
                                  name="loginType"
                                  value={{
                                    label: this.state.user.loginType,
                                    value: this.state.user.loginType,
                                  }}
                                  isMulti={false}
                                  onChange={(e) =>
                                    this.onHandleChange({
                                      target: {
                                        name: "loginType",
                                        value: e.value,
                                      },
                                    })
                                  }
                                  options={loginTypes.map((type) => ({
                                    label: type,
                                    value: type,
                                  }))}
                                  classNamePrefix="select2-selection"
                                  closeMenuOnSelect={true}
                                  components={animatedComponents}
                                  required
                                />
                              </FormGroup>
                            </Col> */}
                            {this.state.action === "add" ? (
                              <></>
                            ) : (
                              <>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Role</Label>
                                    <Select
                                      name="role"
                                      value={{
                                        label: this.state.user.role.name,
                                        value: "" + this.state.user.role.id,
                                      }}
                                      isMulti={false}
                                      onChange={(e) =>
                                        this.onHandleChange({
                                          target: {
                                            name: "role",
                                            value: "" + e.value,
                                          },
                                        })
                                      }
                                      options={this.state.roles.map((role) => ({
                                        label: role.name,
                                        value: "" + role.id,
                                      }))}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            )}
                            {/* <Col md={6}>
                              <FormGroup>
                                <Label className="d-block">Blocked</Label>
                                <ReactSwitch
                                  name="blocked"
                                  uncheckedIcon={<Offsymbol />}
                                  className="mr-1"
                                  checkedIcon={<OnSymbol />}
                                  onColor="#626ed4"
                                  onChange={(e) =>
                                    this.onHandleChange({
                                      target: {
                                        name: "blocked",
                                        value: !this.state.user.blocked,
                                      },
                                    })
                                  }
                                  checked={this.state.user.blocked}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label className="d-block">Confirmed</Label>
                                <ReactSwitch
                                  name="confirmed"
                                  uncheckedIcon={<Offsymbol />}
                                  className="mr-1"
                                  checkedIcon={<OnSymbol />}
                                  onColor="#626ed4"
                                  onChange={(e) =>
                                    this.onHandleChange({
                                      target: {
                                        name: "confirmed",
                                        value: !this.state.user.confirmed,
                                      },
                                    })
                                  }
                                  checked={this.state.user.confirmed}
                                />
                              </FormGroup>
                            </Col> */}
                            <Col md={12}>
                              <div className="bg-light px-2 py-3">
                                <h5>
                                  Address Section{" "}
                                  <i
                                    className="fa fa-plus cursor-pointer text-success float-right mr-3"
                                    onClick={() => this.addAddress()}
                                  ></i>
                                </h5>
                                {this.state.user.addresses.map(
                                  (address, i1) => {
                                    return (
                                      <AddressCard
                                        address={address}
                                        onHandleChange={(e) =>
                                          this.onHandleAddress(e, i1)
                                        }
                                        deleteAddress={() =>
                                          this.onDeleteAddress(i1)
                                        }
                                      />
                                    );
                                  }
                                )}
                              </div>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mt-3">
                                <Button color="primary" type="submit">
                                  Next
                                </Button>
                              </FormGroup>
                            </Col>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          {this.state.detailsResponse ? (
                            <>
                              <Form
                                className="row"
                                onSubmit={this.serviceTecSubmit}
                              >
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Bank Name</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="bankName"
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails.bankName
                                      }
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>IFSC</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="ifsc"
                                      type="text"
                                      className="form-control"
                                      value={this.state.techicianDetails.ifsc}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Account Holder Name</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="accountHolderName"
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails
                                          .accountHolderName
                                      }
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Account Number</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="bankAccountNumber"
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails
                                          .bankAccountNumber
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Family Contact Name</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="familyContactName"
                                      type="text"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails
                                          .familyContactName
                                      }
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Family Contact Number</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="familyContactNumber"
                                      type="text"
                                      pattern="[6-9]{1,1}[0-9]{9,9}"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails
                                          .familyContactNumber
                                      }
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Family Member Relationship</Label>
                                    <Select
                                      name="fmilymemberrelationship"
                                      value={{
                                        label:
                                          this.state.techicianDetails
                                            .familyMemberRelationship,
                                        value:
                                          this.state.techicianDetails
                                            .familyMemberRelationship,
                                      }}
                                      isMulti={false}
                                      onChange={(e) =>
                                        this.onHandleServiceChange({
                                          target: {
                                            name: "familyMemberRelationship",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      options={relationShips.map((r) => ({
                                        label: r,
                                        value: r,
                                      }))}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}></Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Aadhaar Card Number</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="aadhaar"
                                      type="text"
                                      pattern="^[1-9]{1,1}[0-9]{11,11}"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails.aadhaar
                                      }
                                      required
                                      maxLength={12}
                                    />
                                  </FormGroup>
                                </Col>
                                {this.state.techicianDetails.signature.id !==
                                0 ? (
                                  <>
                                    <Col md={6}>
                                      <FormGroup>
                                        <img
                                          alt="."
                                          src={getImageUrl(
                                            this.state.techicianDetails
                                              .signature.url
                                          )}
                                          width="100"
                                          className="img-fluid d-inline-block"
                                        />
                                        <i
                                          className="fa fa-trash text-danger ml-2 cursor-pointer"
                                          onClick={this.onDeleteSignaturePic}
                                        ></i>
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Label>Signature</Label>
                                        <Input
                                          onChange={this.onHandleServiceChange}
                                          name="signature"
                                          type="file"
                                          key={
                                            "signature" +
                                            this.state.userSignatureUpload.count
                                          }
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </>
                                )}
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Aadhar Card Photo</Label>
                                    <Input
                                      key={"files" + this.state.imagesFileCount}
                                      onChange={this.onHandleServiceChange}
                                      name="aadhaarfiles"
                                      type="file"
                                      multiple={true}
                                      className="form-control"
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  {this.state.techicianDetails.aadharCardPhoto
                                    .length > 0 ? (
                                    <>
                                      {this.state.techicianDetails.aadharCardPhoto.map(
                                        (a) => {
                                          return (
                                            <>
                                              <img
                                                src={getImageUrl(a.url)}
                                                height="100"
                                                width="100"
                                                alt="."
                                                className="mr-2"
                                              />
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                                {this.state.techicianDetails.covidcertificate
                                  .id !== 0 ? (
                                  <>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Label>Covid Certificate</Label>
                                        <div className="d-flex">
                                          <a
                                            href={getImageUrl(
                                              this.state.techicianDetails
                                                .covidcertificate.url
                                            )}
                                            className="mr-2"
                                            target="_blank"
                                          >
                                            Certificate Link
                                          </a>
                                          <i
                                            className="fa fa-trash text-danger ml-2 cursor-pointer"
                                            onClick={this.onDeleteCovid}
                                          ></i>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col md={6}>
                                      <FormGroup>
                                        <Label>Covid Certificate</Label>
                                        <Input
                                          onChange={this.onHandleServiceChange}
                                          name="covidUpload"
                                          type="file"
                                          accept="image/*,application/pdf"
                                          key={"covidUpload" +this.state.covidUpload.count}
                                          className="form-control"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </>
                                )}
                                <Col md={12} className="mt-3 mb-2">
                                  <Card>
                                    <CardHeader className="">
                                      <div className="d-flex">
                                        <h5 className="mr-auto mb-0">
                                          Services
                                        </h5>
                                        <i
                                          className="fa fa-plus text-success ml-auto cursor-pointer"
                                          onClick={this.onAddTecService}
                                        ></i>
                                      </div>
                                    </CardHeader>
                                    <CardBody className="">
                                      <div className="row">
                                        {this.state.techicianDetails.services.map(
                                          (service, index) => {
                                            if (!service.active) {
                                              return <></>;
                                            }
                                            return (
                                              <>
                                                <Col md={6}>
                                                  <div className="bg-light p-3 mb-3">
                                                    <Label className="h5 d-block">
                                                      {service.service.name ===
                                                      "" ? (
                                                        <>New Service</>
                                                      ) : (
                                                        <>
                                                          {service.service.name}
                                                        </>
                                                      )}
                                                      <i
                                                        className="fa fa-trash text-danger float-right cursor-pointer"
                                                        onClick={(e) =>
                                                          this.onHandleServiceChange(
                                                            {
                                                              target: {
                                                                name: "deleteService",
                                                                value: "",
                                                              },
                                                            },
                                                            index
                                                          )
                                                        }
                                                      ></i>
                                                    </Label>
                                                    <FormGroup>
                                                      <Label>Service</Label>
                                                      <Select
                                                        name="service"
                                                        isDisabled={
                                                          service.id !== 0
                                                        }
                                                        value={{
                                                          label:
                                                            service.service
                                                              .name,
                                                          value:
                                                            service.service.id,
                                                        }}
                                                        isMulti={false}
                                                        onChange={(e) =>
                                                          this.onHandleServiceChange(
                                                            {
                                                              target: {
                                                                name: "service",
                                                                value: e.value,
                                                              },
                                                            },
                                                            index
                                                          )
                                                        }
                                                        options={this.getUnSelectedServices(
                                                          this.state.services,
                                                          this.state
                                                            .techicianDetails
                                                            .services
                                                        ).map((s) => ({
                                                          label: s.name,
                                                          value: s.id,
                                                        }))}
                                                        classNamePrefix="select2-selection"
                                                        closeMenuOnSelect={true}
                                                        components={
                                                          animatedComponents
                                                        }
                                                        required
                                                      />
                                                    </FormGroup>
                                                    {service.service.id !==
                                                    0 ? (
                                                      <>
                                                        <div>
                                                          <div className="d-flex">
                                                            <Label>
                                                              City Pincodes
                                                            </Label>
                                                            <i
                                                              className="ml-auto fa fa-plus text-success cursor-pointer"
                                                              onClick={() =>
                                                                this.addCityPincode(
                                                                  index
                                                                )
                                                              }
                                                            ></i>
                                                          </div>

                                                          {service.cityPincodes.map(
                                                            (cp, index1) => {
                                                              if (!cp.active) {
                                                                return <></>;
                                                              }
                                                              return (
                                                                <div className="p-2">
                                                                  <div
                                                                    className="form-row border-primary py-1"
                                                                    style={{
                                                                      borderStyle:
                                                                        "dotted",
                                                                    }}
                                                                  >
                                                                    <Col md={6}>
                                                                      <FormGroup>
                                                                        <Label>
                                                                          City
                                                                        </Label>
                                                                        <Select
                                                                          name="city"
                                                                          value={{
                                                                            label:
                                                                              cp
                                                                                .city
                                                                                .name,
                                                                            value:
                                                                              cp
                                                                                .city
                                                                                .id,
                                                                          }}
                                                                          isMulti={
                                                                            false
                                                                          }
                                                                          onChange={(
                                                                            e
                                                                          ) =>
                                                                            this.onHandleServiceChange(
                                                                              {
                                                                                target:
                                                                                  {
                                                                                    name: "city",
                                                                                    value:
                                                                                      e.value,
                                                                                  },
                                                                              },
                                                                              index,
                                                                              index1
                                                                            )
                                                                          }
                                                                          options={this.getUnSelectedServiceCities(
                                                                            service
                                                                              .service
                                                                              .serviceLocations,
                                                                            service.cityPincodes
                                                                          ).map(
                                                                            (
                                                                              s
                                                                            ) => ({
                                                                              label:
                                                                                s
                                                                                  .city
                                                                                  .name,
                                                                              value:
                                                                                s
                                                                                  .city
                                                                                  .id,
                                                                            })
                                                                          )}
                                                                          classNamePrefix="select2-selection"
                                                                          closeMenuOnSelect={
                                                                            true
                                                                          }
                                                                          components={
                                                                            animatedComponents
                                                                          }
                                                                          required
                                                                        />
                                                                      </FormGroup>
                                                                    </Col>
                                                                    {cp.cityId !==
                                                                    0 ? (
                                                                      <>
                                                                        <Col
                                                                          md={6}
                                                                        >
                                                                          <FormGroup>
                                                                            <Label>
                                                                              Pincode
                                                                            </Label>
                                                                            <Select
                                                                              name="pincode"
                                                                              defaultValue={cp.pincodes.map(
                                                                                (
                                                                                  c
                                                                                ) => ({
                                                                                  label:
                                                                                    c,
                                                                                  value:
                                                                                    c,
                                                                                })
                                                                              )}
                                                                              value={cp.pincodes.map(
                                                                                (
                                                                                  c
                                                                                ) => ({
                                                                                  label:
                                                                                    c,
                                                                                  value:
                                                                                    c,
                                                                                })
                                                                              )}
                                                                              isMulti={
                                                                                true
                                                                              }
                                                                              onChange={(
                                                                                e
                                                                              ) =>
                                                                                this.onHandleServiceChange(
                                                                                  {
                                                                                    target:
                                                                                      {
                                                                                        name: "pincode",
                                                                                        value:
                                                                                          e,
                                                                                      },
                                                                                  },
                                                                                  index,
                                                                                  index1
                                                                                )
                                                                              }
                                                                              options={cp.allpincodes.map(
                                                                                (
                                                                                  s
                                                                                ) => ({
                                                                                  label:
                                                                                    "" +
                                                                                    s.code,
                                                                                  value:
                                                                                    "" +
                                                                                    s.code,
                                                                                })
                                                                              )}
                                                                              classNamePrefix="select2-selection"
                                                                              closeMenuOnSelect={
                                                                                true
                                                                              }
                                                                              components={
                                                                                animatedComponents
                                                                              }
                                                                              required
                                                                            />
                                                                          </FormGroup>
                                                                        </Col>
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    )}
                                                                    <Col
                                                                      md={{
                                                                        size: 6,
                                                                        offset: 6,
                                                                      }}
                                                                    >
                                                                      <i
                                                                        className="fa fa-trash text-danger cursor-pointer"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          this.onHandleServiceChange(
                                                                            {
                                                                              target:
                                                                                {
                                                                                  name: "deleteCityPincode",
                                                                                  value:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            index,
                                                                            index1
                                                                          )
                                                                        }
                                                                      ></i>
                                                                    </Col>
                                                                  </div>
                                                                </div>
                                                              );
                                                            }
                                                          )}
                                                        </div>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                </Col>
                                              </>
                                            );
                                          }
                                        )}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Blood Group</Label>
                                    <Select
                                      name="bloodGroup"
                                      value={{
                                        label:
                                          this.state.techicianDetails
                                            .bloodGroup,
                                        value:
                                          this.state.techicianDetails
                                            .bloodGroup,
                                      }}
                                      isMulti={false}
                                      onChange={(e) =>
                                        this.onHandleServiceChange({
                                          target: {
                                            name: "bloodGroup",
                                            value: e.value,
                                          },
                                        })
                                      }
                                      options={bloodGroups.map((r) => ({
                                        label: r,
                                        value: r,
                                      }))}
                                      classNamePrefix="select2-selection"
                                      closeMenuOnSelect={true}
                                      components={animatedComponents}
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label>Experience</Label>
                                    <Input
                                      onChange={this.onHandleServiceChange}
                                      name="experience"
                                      type="number"
                                      className="form-control"
                                      value={
                                        this.state.techicianDetails.experience
                                      }
                                      required
                                    />
                                  </FormGroup>
                                </Col>
                                {/* <Col md={6}>
                              <FormGroup>
                                <Label className="d-block">Available</Label>
                                <ReactSwitch
                                  name="available"
                                  uncheckedIcon={<Offsymbol />}
                                  className="mr-1"
                                  checkedIcon={<OnSymbol />}
                                  onColor="#626ed4"
                                  onChange={(e) =>
                                    this.onHandleServiceChange({
                                      target: {
                                        name: "available",
                                        value:
                                          !this.state.techicianDetails
                                            .available,
                                      },
                                    })
                                  }
                                  checked={
                                    this.state.techicianDetails.available
                                  }
                                />
                              </FormGroup>
                            </Col> */}
                                <Col md={6}>
                              <FormGroup>
                                <Label className="d-block">Approved</Label>
                                <ReactSwitch
                                  name="approved"
                                  uncheckedIcon={<Offsymbol />}
                                  className="mr-1"
                                  checkedIcon={<OnSymbol />}
                                  onColor="#626ed4"
                                  onChange={(e) =>
                                    this.onHandleServiceChange({
                                      target: {
                                        name: "approved",
                                        value:
                                          !this.state.techicianDetails.approved,
                                      },
                                    })
                                  }
                                  checked={this.state.techicianDetails.approved}
                                />
                              </FormGroup>
                            </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label className="d-block">
                                      Is Deposit submitted?
                                    </Label>
                                    <ReactSwitch
                                      name="deposit"
                                      uncheckedIcon={<Offsymbol />}
                                      className="mr-1"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={(e) =>
                                        this.onHandleServiceChange({
                                          target: {
                                            name: "deposit",
                                            value:
                                              !this.state.techicianDetails
                                                .deposit,
                                          },
                                        })
                                      }
                                      checked={
                                        this.state.techicianDetails.deposit
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label className="d-block">
                                      Is Training provided?
                                    </Label>
                                    <ReactSwitch
                                      name="training"
                                      uncheckedIcon={<Offsymbol />}
                                      className="mr-1"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={(e) =>
                                        this.onHandleServiceChange({
                                          target: {
                                            name: "training",
                                            value:
                                              !this.state.techicianDetails
                                                .training,
                                          },
                                        })
                                      }
                                      checked={
                                        this.state.techicianDetails.training
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={6}>
                                  <FormGroup>
                                    <Label className="d-block">
                                      Is TShirt provided?
                                    </Label>
                                    <ReactSwitch
                                      name="tshirt"
                                      uncheckedIcon={<Offsymbol />}
                                      className="mr-1"
                                      checkedIcon={<OnSymbol />}
                                      onColor="#626ed4"
                                      onChange={(e) =>
                                        this.onHandleServiceChange({
                                          target: {
                                            name: "tshirt",
                                            value:
                                              !this.state.techicianDetails
                                                .tshirt,
                                          },
                                        })
                                      }
                                      checked={
                                        this.state.techicianDetails.tshirt
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col md={12}></Col>
                                <Col md={6}>
                                  <Button color="primary" type="submit">
                                    Submit
                                  </Button>
                                </Col>
                              </Form>
                            </>
                          ) : (
                            <></>
                          )}
                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withNamespaces()(connect(mapStateToProps)(AddTechnician));
