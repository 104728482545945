export const technicianFilterConfig={
    filters:[
        {
            label:"First Name",
            placeholder:"Search First Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"technician.firstname",
        },
        {
            label:"Last Name",
            placeholder:"Search Last Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"technician.lastname",
        },
        {
            label:"Username",
            placeholder:"Search Username",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"technician.username",
        },
        {
            label:"Email",
            placeholder:"Search Email",
            filterPostfix:"_contains",
            value:"",
            type:"email",
            key:"technician.email",
        },
        {
            label:"Mobile",
            placeholder:"Search Mobile",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"technician.phonenumber",
        },{
            label:"Approved",
            placeholder:"Search Approved",
            type:"select",
            value:"",
            filterPostfix:"_eq",
            key:"approved",
            options:[{label:"True",value:"true"},{label:"False",value:"false"}]
        }
    ]
}