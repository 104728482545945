import axios from "axios";
import { getValidatedData } from "../constants/constants";

const removecacheurl = (url = "") => {
  let data = url;
  if (data.indexOf("?") !== -1) {
    data += "&";
  } else {
    data += "?";
  }
  data += "dt=" + new Date().getTime();
  return data;
};

export function RestService(url, method, body = null, headers = {}) {
  //console.log(headers);
  let options = {};
  if (body !== null) {
    options = {
      mode: "cors",
      method: method,
      headers: headers,
      body: body,
    };
  } else {
    options = {
      mode: "cors",
      method: method,
      headers: headers,
    };
  }
  return fetch(removecacheurl(url), options)
    .then((res) => {
      return res;
    })
    .catch((e) => {
      // console.log(e);
      return e;
    });
}

export function RestAxiosService(url, method, body, headers, config) {
  let extraConfig = typeof config === "object" ? config : {};
  let options = {
    url: url,
    method: method,
    data: body,
    headers: { ...headers },
  };
  if (method.toLowerCase() === "get") {
    delete options.data;
  }
  return new Promise((resolve, reject) => {
    return axios(Object.assign({}, options, extraConfig))
      .then(function (res) {
        if (res.status >= 200 && res.status < 300) {
          resolve({ status: res.status, data: res.data });
        } else {
          reject({ status: res.status, data: res.data });
        }
      })
      .catch(function (e) {
        //console.log(e);
        if (typeof e === "object" && typeof e.response === "object") {
          if (e.response.status === 401) {
            // if (getValidatedData(window.logout, 'function', true)){
            //     window.logout();
            // }else{
            reject({ status: e.response.status, data: e.response.data });
            // }
          } else {
            reject({ status: e.response.status, data: e.response.data });
          }
        } else {
          reject({ status: 532, data: "Internet Connection Failure" });
        }
      });
  });
}
