import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  InputGroup,
  Form,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import {
  BookingModal,
  BookingStatusMapModal,
  BookingStatusModal,
  LocationModal,
  LocationPincodeModal,
  ServiceModal,
} from "../../../classes/classes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import {
  bookingSlots,
  getValidatedData,
  isNumeric,
  numbersregexp,
} from "../../../constants/constants";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { getConvertedServices } from "../../../constants/conversions/services";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//i18n
import { withNamespaces } from "react-i18next";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import {
  doGetAllLocations,
  doGetAllServices,
  doGetAllUsers,
  doGetBookings,
  doGetBookingStatus,
  doGetBookingStatusMaps,
  doPostBookings,
  doUpdateBookings,
  doUpdateBookingStatusMaps,
} from "../../../services/apiServices";
import {
  getConvertedBookings,
  getConvertedBookingStatus,
  getConvertedBookingStatusMaps,
} from "../../../constants/conversions/bookings";
import DateTimeElement from "../../../components/homevery/DateTime/DateTime";
import moment from "moment";
import { getConvertedUsers } from "../../../constants/conversions/users";
const animatedComponents = makeAnimated();

const bookingApprovedStatuses = [
  "pending",
  "approved",
  "rejected",
  "cancelled",
];

class AddBooking extends Component {
  state = {
    action: "",
    booking: new BookingModal(),
    bookingDate: "",
    bookingSlot: "",
    bookingStatusMap: new BookingStatusMapModal(),
    bookingStatus: null,
    breadcrumbItems: [],
    cities: [],
    pincodes: [],
    users: [],
    selectedServices: [],
    services: [],
    statuses: [],
  };

  async componentDidMount() {
    const queryPath = this.props.match.params;
    this.getAllServices();
    this.getAllUsers();
    await this.getAllBookingStatus();
    this.initData(queryPath.id);

    // this.setState({
    //   bookingDate: moment("2021-10-16T14:27:33.331Z").local().toDate()
    // });
  }

  getAllUsers = () => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers("", "blocked_eq=false&role.type_in=customer&_limit=3000").then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev) => {
          let st = prev;
          st.users = getConvertedUsers(getValidatedData(res.data, "array"));
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          users: [],
        });
      }
    );
  };

  initData = (id) => {
    if (id === "add") {
      const booking = new BookingModal();
      booking.code = "HV" + Math.floor(1000000 + Math.random() * 9000000);
      const statuses = this.state.statuses;
      let bookingStatusMap = new BookingStatusMapModal();
      bookingStatusMap.bookingStatus =
        statuses.length > 0 ? statuses[0] : new BookingStatusModal();
      this.setState(
        {
          action: "add",
          booking: booking,
          bookingDate: "",
          bookingSlot: "",
          bookingStatusMap: bookingStatusMap,
          breadcrumbItems: [
            {
              active: true,
              route: "/bookings/all",
              title: "Bookings",
            },
            {
              active: true,
              route: "#",
              title: "New Booking",
            },
          ],
        },
        () => {
          this.getAllCities(booking.address.city);
        }
      );
    } else {
      this.getBooking(id);
    }
  };

  getAllCities = (city = "") => {
    doGetAllLocations().then(
      (res) => {
        const cities = getConvertedLocations(
          getValidatedData(res.data, "array")
        ).filter((c) => c.active);
        let pincodes = [];
        cities.every((c) => {
          if (c.name === city) {
            pincodes = c.pincodes;
            return false;
          }
          return true;
        });
        this.setState({
          cities: cities,
          pincodes: pincodes,
        });
      },
      (err) => {
        this.setState({
          cities: [],
        });
      }
    );
  };

  getBookingStatusMap = (bookingId = 0) => {
    doGetBookingStatusMaps("", bookingId).then(
      (res) => {
        const bookingStatusMaps = getConvertedBookingStatusMaps(
          getValidatedData(res.data, "array")
        );
        const bookingStatusMap =
          bookingStatusMaps.length > 0
            ? bookingStatusMaps[0]
            : new BookingStatusMapModal();
        this.setState({
          bookingStatusMap: bookingStatusMap,
        });
        this.props.dispatch(changePreloaderStatus(false));
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          bookingStatusMap: new BookingStatusMapModal(),
        });
      }
    );
  };

  combineBookingDateSlots = (date, slots = "") => {
    const dateStr = moment(date).format("YYYY-MM-DD");
    const splitslots = slots.split("-");
    const slot = {
      fromTime: "",
      toTime: "",
    };
    const data = splitslots
      .map((s) => {
        let number = parseInt(s.match(/\d+/)[0]);
        let number1 = ":00:00";
        const meridian = s.replace("" + number, "");
        if (meridian === "am") {
          if (number < 10) {
            number1 = "0" + number + "" + number1;
          } else {
            number1 = "" + number + "" + number1;
          }
        } else if (meridian === "pm") {
          number += 12;
          number1 = number + number1;
        }
        // console.log(number1);
        return moment(dateStr + " " + number1, "YYYY-MM-DD HH:mm:ss");
      })
      .forEach((m, i) => {
        if (i === 0) {
          slot.fromTime = m.local().toDate();
        } else {
          slot.toTime = m.local().toDate();
        }
      });
    return slot;
  };

  getAllBookingStatus = async () => {
    await doGetBookingStatus().then(
      (res) => {
        // console.log(res.data);
        this.setState({
          statuses: getConvertedBookingStatus(
            getValidatedData(res.data, "array")
          ).filter((s) => s.name === "BOOKING_CREATED"),
        });
      },
      (err) => {
        this.setState({
          statuses: [],
        });
      }
    );
  };

  getAllServices = () => {
    doGetAllServices().then(
      (res) => {
        // console.log(res.data);
        this.setState({
          services: getConvertedServices(getValidatedData(res.data, "array")),
        });
      },
      (err) => {
        this.setState({
          services: [],
        });
      }
    );
  };

  getBooking = (id = "") => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetBookings(id).then(
      (res) => {
        const bookings = getConvertedBookings([
          getValidatedData(res.data, "object"),
        ]);
        // console.log(bookings);
        const booking = bookings.length > 0 ? bookings[0] : new BookingModal();
        let bookingDate = "",
          bookingSlot = "9am-12pm";
        if (booking.fromTime !== "" && booking.toTime !== "") {
          bookingDate = moment(booking.fromTime).toDate();
          let startTime = moment(booking.fromTime)
            .format("hha")
            .replace(/^0+/, "");
          let toTime = moment(booking.toTime).format("hha").replace(/^0+/, "");
          bookingSlot = startTime + "-" + toTime;
        }
        this.setState(
          {
            action: "edit",
            breadcrumbItems: [
              {
                active: true,
                route: "/bookings/all",
                title: "Bookings",
              },
              {
                active: true,
                route: "#",
                title: booking.code,
              },
            ],
            bookingSlot: bookingSlot,
            bookingDate: bookingDate,
            booking: booking,
            selectedServices: this.getServicesBasedOnCity(
              this.state.services,
              booking.service.name
            ),
          },
          () => {
            this.props.dispatch(changePreloaderStatus(false));
            this.getAllCities(booking.address.city);
            this.getBookingStatusMap(booking.id);
          }
        );
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));

        this.setState({
          action: "add",
          breadcrumbItems: [
            {
              active: true,
              route: "/bookings/all",
              title: "Bookings",
            },
            {
              active: true,
              route: "#",
              title: "New Booking",
            },
          ],
          booking: new BookingModal(),
        });
      }
    );
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    // console.log(value);
    switch (name) {
      case "bookingFor":
        this.setState((prev) => {
          let st = prev;
          const users = st.users.filter((s) => s.id === value);
          if (users.length > 0) {
            st.booking.createdBy = users[0];
          }
          // st.booking.address.phoneNumber = value;
          return st;
        });
        break;
      case "addressPhone":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.phoneNumber = value;
          return st;
        })
        break;
      case "bookingSlot":
        this.setState({
          bookingSlot: value,
        });
        break;
      case "bookingDate":
        this.setState((prev) => {
          let st = prev;
          let dateCurrentStr = moment().format("YYYY-MM-DD");
          let dateValueStr = moment(value).format("YYYY-MM-DD");
          if (moment(dateValueStr).isSameOrAfter(moment(dateCurrentStr))) {
            st.bookingDate = value;
          }
          return st;
        });
        break;
      case "bookingStatus":
        this.setState((prev) => {
          let st = prev;
          let sStatus = st.statuses.filter((ss) => {
            return ss.id === value;
          });
          st.bookingStatusMap.bookingStatus =
            sStatus.length > 0 ? sStatus[0] : new BookingStatusModal();
          return st;
        });
        break;
      case "latitude":
        this.setState((prev) => {
          let st = prev;
          if (value === "") {
            st.bookingStatusMap.latitude = value;
          } else if (isNumeric(value)) {
            st.bookingStatusMap.latitude = parseFloat(value);
          }
          return st;
        });
        break;
      case "longitude":
        this.setState((prev) => {
          let st = prev;
          if (value === "") {
            st.bookingStatusMap.longitude = value;
          } else if (isNumeric(value)) {
            st.bookingStatusMap.longitude = parseFloat(value);
          }
          return st;
        });
        break;
      case "name":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.name = value;
          return st;
        });
        break;
      case "flat":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.flat = value;
          return st;
        });
        break;
      case "street":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.street = value;
          return st;
        });
        break;
      case "addressline1":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.addressline1 = value;
          return st;
        });
        break;
      case "landmark":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.landmark = value;
          return st;
        });
        break;
      case "city":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.city = value;
          st.booking.address.pincode = "";
          st.pincodes = [];
          st.selectedServices = this.getServicesBasedOnCity(st.services, value);
          st.booking.service = new ServiceModal();

          st.cities.every((c) => {
            if (c.name === value) {
              st.pincodes = c.pincodes;
              return false;
            }
            return true;
          });
          return st;
        });
        break;
      case "pincode":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value) && value.length <= 6)
            st.booking.address.pincode = value;
          return st;
        });
        break;
      case "active":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.active = value;
          return st;
        });
        break;
      case "service":
        this.setState((prev) => {
          let st = prev;
          let s = st.selectedServices.filter((s1) => {
            return s1.id === value;
          });
          st.booking.service = s.length > 0 ? s[0] : new ServiceModal();
          return st;
        });
        break;
      case "fromTime":
        this.setState((prev) => {
          let st = prev;
          st.booking.fromTime = value;
          return st;
        });
        break;
      case "toTime":
        this.setState((prev) => {
          let st = prev;
          st.booking.toTime = value;
          return st;
        });
        break;
      case "problem":
        this.setState((prev) => {
          let st = prev;
          st.booking.problem = value;
          return st;
        });
        break;
      case "gstId":
        this.setState((prev) => {
          let st = prev;
          st.booking.gstId = value;
          return st;
        });
        break;
      case "approvedStatus":
        this.setState((prev) => {
          let st = prev;
          st.booking.approvedStatus = value;
          return st;
        });
        break;
      case "bookingMedium":
        this.setState((prev) => {
          // console.log("Booking Medium State :- ", this.state.booking);
          let st = prev;
          st.booking.booking_medium = value;
          return st;
        });
        break;

      case "type":
        this.setState((prev) => {
          let st = prev;
          st.booking.address.type = value;
          return st;
        });
      break;
      default:
        break;
    }
  };

  getServicesBasedOnCity = (services = [], cityName = "") => {
    return services.filter((s) => {
      const selectedCities = s.serviceLocations
        .filter((sl) => {
          return sl.city.name.toLowerCase() === cityName.toLowerCase();
        })
        .filter((s) => s.city.active);
      return selectedCities.length > 0;
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  doUpdateForm = (request, request1) => {
    this.props.dispatch(changePreloaderStatus(true));
    doUpdateBookings(request.id, request).then(
      (res) => {
        doUpdateBookingStatusMaps(request1.id, request1).then(
          (res1) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Success", "Succesfully Updated", "success");
          },
          (err1) => {
            swal("Error", "", "error");
            this.props.dispatch(changePreloaderStatus(false));
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
      }
    );
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.booking.service.id !== 0 &&
      this.state.booking.approvedStatus !== "" &&
      this.state.booking.address.city !== "" &&
      this.state.booking.address.pincode !== "" &&
      this.state.bookingSlot !== "" &&
      this.state.booking.createdBy.id !== 0 &&
      this.state.bookingDate !== ""
    ) {
      const request = this.state.booking.getAddRequest();
      const request1 = this.state.bookingStatusMap.getAddRequest();
      const slotdata = this.combineBookingDateSlots(
        this.state.bookingDate,
        this.state.bookingSlot
      );
      request.fromtime = slotdata.fromTime;
      request.totime = slotdata.toTime;
      if (this.state.action === "add") {
        this.props.dispatch(changePreloaderStatus(true));
        doPostBookings({ ...request }).then(
          (res) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Success", "Succesfully Created", "success");
            if (getValidatedData(res.data, "object", true)) {
              if (res.data.id) {
                this.props.history.push("/bookings/" + res.data.id);
              } else {
                this.props.history.push("/bookings/all");
              }
            } else {
              this.props.history.push("/bookings/all");
            }
          },
          (err) => {
            swal("Error", "", "error");
            this.props.dispatch(changePreloaderStatus(false));
          }
        );
      } else {
        this.doUpdateForm(request, request1);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " Booking Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Form className="row userForm" onSubmit={this.onFormSubmit}>
              <Col md={6}>
                <FormGroup>
                  <Label>Booking Code</Label>
                  <Input
                    name="code"
                    type="text"
                    className="form-control"
                    value={this.state.booking.code}
                    placeholder=""
                  />
                </FormGroup>
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Booking Date</Label>
                  <InputGroup>
                    <DatePicker
                      selected={this.state.bookingDate}
                      className="form-control"
                      onChange={(e) =>
                        this.onHandleChange({
                          target: { name: "bookingDate", value: e },
                        })
                      }
                      showYearDropdown
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Booking Slot</Label>
                  <Select
                    name="bookingStatus"
                    value={{
                      label: this.state.bookingSlot,
                      value: this.state.bookingSlot,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "bookingSlot",
                          value: e.value,
                        },
                      })
                    }
                    options={bookingSlots.map((s) => ({
                      label: s,
                      value: s,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Problem</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="problem"
                    type="text"
                    className="form-control"
                    value={this.state.booking.problem}
                    placeholder=""
                    required
                  />
                </FormGroup>
              </Col>

              {/* <Col md={6}>
                <FormGroup>
                  <Label>GST ID</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="gstId"
                    type="text"
                    className="form-control"
                    value={this.state.booking.gstId}
                    placeholder=""
                  />
                </FormGroup>
              </Col> */}
              {/* <Col md={6}>
                    <FormGroup>
                      <Label>Approval Status</Label>
                      <Select
                        name="approvedStatus"
                        value={{
                          label: this.state.booking.approvedStatus,
                          value: this.state.booking.approvedStatus,
                        }}
                        isMulti={false}
                        onChange={(e) =>
                          this.onHandleChange({
                            target: {
                              name: "approvedStatus",
                              value: e.value,
                            },
                          })
                        }
                        options={bookingApprovedStatuses.map((s) => ({
                          label: s.toUpperCase(),
                          value: s.toUpperCase(),
                        }))}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        required
                      />
                    </FormGroup>
                  </Col>
                   */}
              <Col md={6}>
                <FormGroup>
                  <Label>Booking Status</Label>
                  <Select
                    name="bookingStatus"
                    isDisabled={true}
                    value={{
                      label: this.state.bookingStatusMap.bookingStatus.name,
                      value: this.state.bookingStatusMap.bookingStatus.id,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "bookingStatus",
                          value: e.value,
                        },
                      })
                    }
                    options={this.state.statuses.map((s) => ({
                      label: s.name,
                      value: s.id,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <label>Booking Medium</label>
                <Select
                  name="bookingMedium"
                  isMulti={false}
                  onChange={(e) => {
                    this.onHandleChange({
                      target: {
                        name: "bookingMedium",
                        value: e.value,
                      },
                    });
                    // console.log(
                    //   "Booking Medium Select :- ",
                    //   this.state.booking.booking_medium
                    // );
                  }}
                  value={{
                    label: this.state.booking.booking_medium.toLowerCase(),
                    value: this.state.booking.booking_medium,
                  }}
                  options={[
                    {
                      label: "Social Media",
                      value: "SOCIALMEDIA",
                    },
                    {
                      label: "App",
                      value: "APP",
                    },
                    {
                      label: "Website",
                      value: "WEBSITE",
                    },
                    {
                      label: "Call",
                      value: "CALL",
                    },
                    {
                      label: "Technician",
                      value: "TECHNICIAN",
                    },
                  ]}
                  classNamePrefix="select2-selection"
                  closeMenuOnSelect={true}
                  components={animatedComponents}
                  required
                />
              </Col>
              {/* <Col md={6}>
                    <FormGroup>
                      <Label>Latitude</Label>
                      <Input
                        onChange={this.onHandleChange}
                        name="latitude"
                        type="number"
                        className="form-control"
                        value={this.state.bookingStatusMap.latitude}
                        placeholder=""
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Longitude</Label>
                      <Input
                        onChange={this.onHandleChange}
                        name="longitude"
                        type="number"
                        className="form-control"
                        value={this.state.bookingStatusMap.longitude}
                        placeholder=""
                        required
                      />
                    </FormGroup>
                  </Col>
                   */}
              <Col md={6}>
                <FormGroup>
                  <Label>Booking For</Label>
                  <Select
                    name="bookingFor"
                    value={{
                      label: this.state.booking.createdBy.mobile,
                      value: this.state.booking.createdBy.id,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "bookingFor",
                          value: e.value,
                        },
                      })
                    }
                    options={this.state.users.map((s) => ({
                      label: s.mobile,
                      value: s.id,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <Card>
                  <CardBody className="row">
                    <Col md={12}>
                      <h5>Address</h5>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Name</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="name"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.name}
                          placeholder=""
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Flat</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="flat"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.flat}
                          placeholder=""
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Street</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="street"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.street}
                          placeholder=""
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Address Line</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="addressline1"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.addressline1}
                          placeholder=""
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Landmark</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="landmark"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.landmark}
                          placeholder=""
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Phone Number</Label>
                        <Input
                          onChange={this.onHandleChange}
                          name="addressPhone"
                          type="text"
                          className="form-control"
                          value={this.state.booking.address.phoneNumber}
                          placeholder="Phone Number for Address"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>City</Label>
                        <Select
                          name="city"
                          value={{
                            label: this.state.booking.address.city,
                            value: this.state.booking.address.city,
                          }}
                          isMulti={false}
                          onChange={(e) =>
                            this.onHandleChange({
                              target: {
                                name: "city",
                                value: e.value,
                              },
                            })
                          }
                          options={this.state.cities.map((c) => ({
                            label: c.name,
                            value: c.name,
                          }))}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label>Pincode</Label>
                        <Select
                          name="pincode"
                          value={{
                            label: "" + this.state.booking.address.pincode,
                            value: "" + this.state.booking.address.pincode,
                          }}
                          isMulti={false}
                          onChange={(e) =>
                            this.onHandleChange({
                              target: {
                                name: "pincode",
                                value: e.value,
                              },
                            })
                          }
                          options={this.state.pincodes.map((p) => ({
                            label: "" + p.code,
                            value: "" + p.code,
                          }))}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label>Service</Label>
                        <Select
                          name="approvedStatus"
                          value={{
                            label: this.state.booking.service.name,
                            value: this.state.booking.service.id,
                          }}
                          isMulti={false}
                          onChange={(e) =>
                            this.onHandleChange({
                              target: {
                                name: "service",
                                value: e.value,
                              },
                            })
                          }
                          options={this.state.selectedServices.map((s) => ({
                            label: s.name,
                            value: s.id,
                          }))}
                          classNamePrefix="select2-selection"
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <label>Address Type</label>
                      <Select
                        name="alias"
                        isMulti={false}
                        value={{
                          label: this.state.booking.address.type,
                          value: this.state.booking.address.type,
                        }}
                        options={[
                          {
                            label: "home",
                            value: "home",
                          },
                          {
                            label: "work",
                            value: "work",
                          },
                          {
                            label: "other",
                            value: "other",
                          },
                        ]}
                        onChange={(e) => {
                          this.onHandleChange({
                            target: {
                              name: "type",
                              value: e.value,
                            },
                          });
                          //console.log("Address", address);
                        }}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label className="d-block">Active</Label>
                        <ReactSwitch
                          name="active"
                          uncheckedIcon={<Offsymbol />}
                          className="mr-1"
                          checkedIcon={<OnSymbol />}
                          onColor="#626ed4"
                          onChange={(e) =>
                            this.onHandleChange({
                              target: {
                                name: "active",
                                value: !this.state.booking.address.active,
                              },
                            })
                          }
                          checked={this.state.booking.address.active}
                        />
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </Col>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login };
};
export default withNamespaces()(connect(mapStateToProps)(AddBooking));
