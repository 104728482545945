import {
  CategoryModal,
  ImageModal,
  SEOModal,
  ServiceDetailsModal,
  ServiceModal,
} from "../../classes/classes";
import { getValidatedData } from "../constants";
import { getConvertedCategories } from "./categories";
import { getConvertedFaqs } from "./faqs";
import { getConvertedImages } from "./images";
import { getConvertedSEOS } from "./seo";
import { getConvertedServiceLocations } from "./serviceLocations";

export function getConvertedServices(services = []) {
  return services
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((service) => {
      const id = getValidatedData(service.id, "number");
      const name = getValidatedData(service.name);
      const details = getConvertedServiceDetails(
        getValidatedData(service.details, "array")
      );
      const keywords = getValidatedData(service.keywords);

      const problem = getValidatedData(service.problem);

      const seos = getValidatedData(service.seo, "object", true)
        ? getConvertedSEOS([getValidatedData(service.seo, "object")])
        : [];
      const seo = seos.length > 0 ? seos[0] : new SEOModal();

      const categories = getValidatedData(service.category, "object", true)
        ? getConvertedCategories([getValidatedData(service.category, "object")])
        : [];
      const category =
        categories.length > 0 ? categories[0] : new CategoryModal();

      const faqs = getConvertedFaqs(getValidatedData(service.faqs, "array"));
      const serviceLocations = getConvertedServiceLocations(
        getValidatedData(service.service_locations, "array")
      );
      const images = getConvertedImages(
        getValidatedData(service.images, "array")
      );
      const imagesAll = getConvertedImages([
        getValidatedData(service.displayPic, "object"),
      ]);
      // console.log(service);
      const websiteImages = getConvertedImages(
        getValidatedData(service.websiteImages, "array")
      );

      return new ServiceModal(
        id,
        name,
        details,
        keywords,
        problem,
        category,
        serviceLocations,
        faqs,
        seo,
        images,
        websiteImages,
        imagesAll[0]
      );
    });
}

export function getConvertedServiceDetails(details = []) {
  return details
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((details) => {
      const id = getValidatedData(details.id, "number");
      const title = getValidatedData(details.Title);
      const description = getValidatedData(details.description);
      const images = getValidatedData(details.displayPic, "object", true)
        ? getConvertedImages([getValidatedData(details.displayPic, "object")])
        : [];
      const image = images.length > 0 ? images[0] : new ImageModal();

      return new ServiceDetailsModal(id, title, description, image);
    });
}
