export const locationFilterConfig={
    filters:[
        {
            label:"Name",
            placeholder:"Search Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"name",
        },
        {
            label:"GST",
            placeholder:"Search GST",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"gst_number",
        },
        {
            label:"Active / Inactive",
            placeholder:"ALL",
            filterPostfix:"",
            value:"",
            options:[{label:"Active",value:"true"},{label:"Inactive",value:"false"}],
            type:"select",
            key:"active",
        },
    ]
}