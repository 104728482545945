import { UserAddressModal } from "../../classes/classes";
import { getValidatedData } from "../constants";

export function getConvertedUserAddresses(allAddress = []) {
  return allAddress.filter((k)=>{
      return Object.keys(k).length>0
    }).map((address) => {
      const address1 = new UserAddressModal();
      address1.id = getValidatedData(address.id, "number");
      address1.pincode = getValidatedData(address.pincode);
      address1.city = getValidatedData(address.city);
      address1.name = getValidatedData(address.alias);
      address1.flat = getValidatedData(address.flat);
      address1.street = getValidatedData(address.street);
      address1.addressline1 = getValidatedData(address.addressline1);
      address1.landmark = getValidatedData(address.landmark);
      address1.active = getValidatedData(address.active, "boolean");
      address1.phoneNumber = getValidatedData(address.phoneNumber);
      address1.type = getValidatedData(address.type);
      address1.alias = getValidatedData(address.alias);
      return address1;
    })
}
