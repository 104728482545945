import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

class Breadcrumbs extends Component {


    render() {
        if (this.props.breadcrumbItems !== undefined && Array.isArray(this.props.breadcrumbItems)) {
            return (<React.Fragment>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="mb-0 font-size-18">{this.props.title}</h4>
                            <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    {
                                        this.props.breadcrumbItems.map((item,index) => {
                                            return (<BreadcrumbItem key={"breadcrumblist"+index} active={item.active}>
                                                {
                                                    (typeof item.route !== 'undefined' && item.route !== null && item.route !== "") ? (<Link to={item.route}>{item.title}</Link>) : (<Link to="#">{item.title}</Link>)
                                                }
                                            </BreadcrumbItem>)
                                        })
                                    }
                                </Breadcrumb>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment>);
        } else {
            return (
                <React.Fragment>
                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">{this.props.title}</h4>
                                <div className="page-title-right">
                                    <Breadcrumb listClassName="m-0">
                                        <BreadcrumbItem>
                                            <Link to="#">{this.props.title}</Link>
                                        </BreadcrumbItem>
                                        {
                                            (typeof this.props.breadcrumbItem === 'string') ? (<>
                                                <BreadcrumbItem active>
                                                    <Link to="#">{this.props.breadcrumbItem}</Link>
                                                </BreadcrumbItem>
                                            </>) : (<></>)
                                        }
                                    </Breadcrumb>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </React.Fragment>
            );
        }
    }

}

export default Breadcrumbs;