import {
  ImageModal,
  LocationModal,
  ServiceModal,
  TechnicianCityPincodeCustomModal,
  TechnicianCityPincodeModal,
  TechnicianDetailsModal,
  TechnicianServiceModal,
  UserModal,
} from "../../classes/classes";
import { getValidatedData } from "../constants";
import { getConvertedImages } from "./images";
import { getConvertedLocations } from "./locations";
import { getConvertedServices } from "./services";
import { getConvertedUsers } from "./users";

const getConvertedCityPincodes = (
  citypincodes = [],
  multiplepincodes = true
) => {
  return citypincodes
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((cp) => {
      const id = getValidatedData(cp.id, "number");
      const city_id = getValidatedData(cp.city_id, "number");
      const pincode_id = getValidatedData(cp.pincode_id, "number");
      let pincode_number = getValidatedData(cp.pincode_number);
      const city = getValidatedData(cp.city);
      let pincode_name = getValidatedData(cp.pincode_name);
      const active = getValidatedData(cp.active, "boolean");
      if (multiplepincodes) {
        pincode_number = pincode_number.split(",");
        pincode_name = pincode_name.split(",");
      }
      return new TechnicianCityPincodeModal(
        id,
        city,
        city_id,
        pincode_name,
        pincode_id,
        pincode_number,
        active
      );
    });
};

const getConvertedTechnicianServices = (services = []) => {
  return services
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((service) => {
      const id = getValidatedData(service.id, "number");
      const filterServices = getConvertedServices([
        getValidatedData(service.service, "object"),
      ]);
      const filterService =
        filterServices.length > 0 ? filterServices[0] : new ServiceModal();
      const cityPincodes = getConvertedCityPincodes(
        getValidatedData(service.city_pincode, "array")
      );

      return new TechnicianServiceModal(id, filterService, cityPincodes);
    });
};

export function getConvertedTechnicianDetails(details = []) {
  return details
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((detail) => {
      const id = getValidatedData(detail.id, "number");
      const technicians = getConvertedUsers([
        getValidatedData(detail.technician, "object"),
      ]);
      const technician =
        technicians.length > 0 ? technicians[0] : new UserModal();
      const available = getValidatedData(detail.availaible, "boolean");
      const aadhar = getValidatedData(detail.aadhar);
      const bankAccountNumber = getValidatedData(detail.bank_account_number);
      const ifsc = getValidatedData(detail.ifsc);
      const bankname = getValidatedData(detail.bankname);
      const approved = getValidatedData(detail.approved, "boolean");
      const accountHolderName = getValidatedData(detail.accountHolderName);
      const familyContactNumber = getValidatedData(
        detail.family_contact_number
      );
      const familyContactName = getValidatedData(detail.family_contact_name);
      const familyMemberRelationship = getValidatedData(
        detail.family_member_relationship
      );
      const bloodgroup = getValidatedData(detail.bloodgroup);
      const experience = getValidatedData(detail.experience, "number");
      const deposit = getValidatedData(detail.deposit, "boolean");
      const training = getValidatedData(detail.training, "boolean");
      const tshirt = getValidatedData(detail.tshirt, "boolean");
      const aadhaarImages = getConvertedImages(
        getValidatedData(detail.aadharcard_photo, "array")
      );
      const images = getConvertedImages([
        getValidatedData(detail.signature, "object"),
      ]);
      // console.log(images,"im")
      const image = images.length > 0 ? images[0] : new ImageModal();

      const covidcertificates = getConvertedImages([
        getValidatedData(detail.covidcertificate, "object"),
      ]);
      const covidcertificate =
        covidcertificates.length > 0 ? covidcertificates[0] : new ImageModal();

      const allservices = getConvertedCustomTechnicianServices(
        convertGroupByServices(getValidatedData(detail.services, "array"))
      );
      // const allservices=getConvertedTechnicianServices(getValidatedData(detail.services,'array'))
      return new TechnicianDetailsModal(
        id,
        technician,
        available,
        aadhar,
        allservices,
        approved,
        bankname,
        accountHolderName,
        bankAccountNumber,
        ifsc,
        familyContactNumber,
        familyContactName,
        familyMemberRelationship,
        bloodgroup,
        experience,
        aadhaarImages,
        image,
        deposit,
        training,
        tshirt,
        covidcertificate
      );
    });
}

const getConvertedCustomCityPincodes = (citypincodes = []) => {
  return citypincodes
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((cp) => {
      const id = getValidatedData(cp.id, "number");
      const cities = getConvertedLocations([
        getValidatedData(cp.city, "object"),
      ]);
      const city = cities.length > 0 ? cities[0] : new LocationModal();
      let pincodes = getValidatedData(cp.pincodes, "array");
      const active = getValidatedData(cp.active, "boolean");
      return new TechnicianCityPincodeCustomModal(id, city, pincodes, active);
    })
    .filter((c) => c.active);
};

const getConvertedCustomTechnicianServices = (services = []) => {
  return services
    .filter((k) => {
      return Object.keys(k).length > 0;
    })
    .map((service) => {
      let id = getValidatedData(service.id, "number");
      const filterServices = getConvertedServices([
        getValidatedData(service.service, "object"),
      ]);
      const filterService =
        filterServices.length > 0 ? filterServices[0] : new ServiceModal();
      const cityPincodes = getConvertedCustomCityPincodes(
        getValidatedData(service.cityPincodes, "array")
      );
      const filteredPincodes = cityPincodes.filter(
        (k) => k.id !== 0 && k.active
      );
      if (filteredPincodes.length > 0) {
        id = filteredPincodes[0].id;
      }
      return new TechnicianServiceModal(id, filterService, cityPincodes);
    });
};

const convertGroupByServices = (servicemapings = []) => {
  let serviceIds = [],
    servicesObj = {};
  let result = [];
  servicemapings
    .filter((h) => h.active)
    .forEach((sm) => {
      let d = Object.assign({}, sm);
      if (typeof servicesObj[sm.service.id] === "undefined") {
        d.cityPincodes = [
          {
            id: sm.id,
            city: sm.city,
            pincodes: getValidatedData(sm.pincodes).split(","),
            active: sm.active,
          },
        ];
        servicesObj[sm.service.id] = d;
        serviceIds.push(sm.service.id);
      } else {
        servicesObj[sm.service.id].cityPincodes.push({
          id: sm.id,
          city: sm.city,
          pincodes: getValidatedData(sm.pincodes).split(","),
          active: sm.active,
        });
      }
    });

  serviceIds.forEach((s) => {
    result.push(servicesObj[s]);
  });

  return result.map((r) => {
    return {
      service: r.service,
      cityPincodes: r.cityPincodes,
    };
  });
};
