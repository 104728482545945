import React from 'react';

const PreLoader=(props)=>{
    if(typeof props.show==='boolean' && props.show){
        return (<div id="preloader1">
            <div id="status">
                <div className="spinner-chase">
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                    <div className="chase-dot"></div>
                </div>
            </div>
        </div>)
    }else{
        return <></>
    }
}

export default PreLoader;