export const customerFilterConfig={
    filters:[
        {
            label:"Mobile",
            placeholder:"Search Mobile",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"phonenumber",
        },
        {
            label:"Username",
            placeholder:"Search Username",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"username",
        },
        {
            label:"Date of Birth",
            placeholder:"Search DOB",
            filterPostfix:"_contains",
            value:"",
            type:"text",
            key:"dob",
        }
    ]
}