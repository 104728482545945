import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Card, Table } from "reactstrap";
// Table Css
// import "../../tables.css";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../../constants/constants";
import { getConvertedUsers } from "../../../../constants/conversions/users";
import {
  doGetAllUsers,
  doPutUpdateUser,
  doDeleteUser
} from "../../../../services/apiServices";
import swal from "sweetalert";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../../store/layout/actions";
import CustomPagination from "../../../../components/Common/CustomPagination";
import FilterView from "../../../../components/Common/FilterView";
import { customerFilterConfig } from "./CustomerFilterConfig";

class Customers extends Component {
  state = {
    users: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
    filterQuery: "",
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Customers",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount = (
    currentPageNo = this.state.pagination.currentPageNo,
    filterQuery = this.state.filterQuery
  ) => {
    doGetAllUsers(
      "count",
      "blocked_eq=false&role.type_in=customer" +
      (filterQuery !== "" ? "&" + filterQuery : "")
    ).then(
      (res) => {
        //console.log("s", res.data);
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllUsers(
              currentPageNo,
              this.state.pagination.limit,
              filterQuery
            );
          }
        );
      },
      (err) => { }
    );
  };

  doPagination = (currentPageNo) => {
    this.getAllUsers(currentPageNo, this.state.pagination.limit);
  };

  getAllUsers = (
    currentPageNo = 0,
    limit = 1,
    filterQuery = this.state.filterQuery
  ) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers(
      "",
      "role.type_in=customer&_limit=" +
      limit +
      "&_start=" +
      (currentPageNo * limit) +
      (filterQuery !== "" ? "&" + filterQuery : "")
    ).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev) => {
          let st = prev;
          st.users = getConvertedUsers(
            getValidatedData(res.data, "array")
          ).filter((user) => user.role.type.toLowerCase() === "customer");
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          users: [],
        });
      }
    );
  };

  DeleteCustomer = (userId) => {
    console.log('user', userId);
    // let text = (user.blocked) ? "Active" : "Inactive";
    swal({
      title: "Are you sure you want to delete this customer?",
      text: 'You will not be able to retrieve the data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log("willDelete", willDelete);
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doDeleteUser(userId).then(
          (res) => {
            swal("Success", "Succesfully Deleted", "success");
            this.getAllUsers(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit
            );
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  doDeleteUser = (user) => {
    let text = (user.blocked) ? "Active" : "Inactive";
    swal({
      title: "Are you sure to make " + text + "?",
      text: 'You will be able to reactive "' + user.userName + '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doPutUpdateUser(user.id, JSON.stringify({ blocked: !user.blocked })).then(
          (res) => {
            swal("Success", "Succesfully Deleted", "success");
            this.getAllUsers(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit
            );
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  dofilterData = (query = "") => {
    this.getCount(0, query);
  };

  render() {
    const currentSno =
      this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Customers"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                {/* <Link to="/users/customers/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link> */}
                <span>
                  <Link
                    to="/users/customers/add"
                    style={{ padding: "7px" }}
                    className="btn btn-primary waves-effect waves-light btn-sm float-right"
                  >
                    Add New User <i className="fa fa-plus ml-1"></i>
                  </Link>
                </span>
              </Col>
              <Col md={12}>
                <FilterView
                  doFilter={this.dofilterData}
                  filterOptions={customerFilterConfig.filters}
                />
              </Col>
              <Col md="12">
                <Table bordered striped className="table mb-3 pr-0">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      {/* <th>Name</th> */}
                      <th>Mobile</th>
                      <th>Username</th>
                      <th>Date of Birth</th>
                      <th>Role</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {//console.log("users", this.state.users)} */}
                    {this.state.users.map((user, index) => {
                      return (
                        <React.Fragment key={user.id}>
                          <tr>
                            <td>{"" + (currentSno + index + 1)}</td>
                            {/* <td>{user.getFullName()}</td> */}
                            <td>{user.mobile}</td>
                            <td>{user.userName}</td>
                            <td>{user.dob}</td>
                            <td>{user.role.name}</td>
                            <td>{(user.blocked) ? (<i
                              className="cursor-pointer fa fa-circle text-danger"
                              title="Inactive"
                              onClick={() => this.doDeleteUser(user)}
                            ></i>) : (<i
                              className="cursor-pointer fa fa-circle text-success"
                              title="Active"
                              onClick={() => this.doDeleteUser(user)}
                            ></i>)}</td>
                            <td>
                              <Link to={"/users/customers/" + user.id}>
                                <i
                                  className="fa fa-edit text-warning mr-2"
                                  title="Edit"
                                ></i>
                              </Link>
                              <Link
                                to={"/users/customer/view/" + user.userName}
                              >
                                <i
                                  className="fa fa-eye text-primary ml-2"
                                  title="Bookings"
                                ></i>
                              </Link>

                              <i
                                className="cursor-pointer fa fa-trash text-danger ml-3 mr-2"
                                title="Delete"
                                onClick={() => this.DeleteCustomer(user.id)}
                              ></i>

                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Customers);
