import moment from "moment";
import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const DateTimeElement = (props) => {
  const onChange = (d) => {
    if (typeof d === "object" && d !== null) {
      // console.log(d)
      props.onChange(moment(d).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z");
    } else {
      props.onChange("");
    }
  };

  const getValue = (v) => {
    return moment(v);
  };
  return (
    <Datetime
      input={true}
      disabled={true}
      onChange={onChange}
      dateFormat="DD-MM-YYYY"
      timeFormat="hh:mm:ss A"
      value={getValue(props.value)}
      utc={true}
    />
  );
};

export default DateTimeElement;
