export const serviceFilterConfig={
    filters:[
        {
            label:"Name",
            placeholder:"Search Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"name",
        },
        {
            label:"Category",
            placeholder:"Search Category",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"category.name",
        }
    ]
}