import React, { Component } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import {
  doGetAllWalletCount,
  doGetAllWallets,
} from "../../../services/apiServices";
import moment from "moment";
import { Link } from "react-router-dom";
import CustomPagination from "../../../components/Common/CustomPagination";
import FilterView from "../../../components/Common/FilterView";
import { walletFilterConfig } from "./WalletFilterConfig";
export default class Wallets extends Component {
  state = {
    wallets: [],
    filterQuery: "",
    // customer_wallet: 0,
    // technician_wallet: 0,
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
  };
  componentDidMount() {
    this.getWallets(0, this.state.pagination.limit);
  }
  getWallets = (currentPageNo, limit, filterQuery = this.state.filterQuery) => {
    let query = `_limit=${limit}&_start=${(currentPageNo * limit) + ((filterQuery !== "") ? "&" + filterQuery : "")}`;
    doGetAllWalletCount(((filterQuery !== "") ? "&" + filterQuery : "")).then((res1) => {
      this.setState((prev) => {
        let st = prev;
        console.log('res1.data', res1.data);
        st.pagination.totalPages = Math.ceil(res1.data / st.pagination.limit);
        st.pagination.currentPageNo = currentPageNo;
        return st;
      });
      doGetAllWallets(query).then(
        (res) => {
          //  console.log(res);
          this.setState({ wallets: res.data });
        },
        (err) => { }
      );
    });
  };
  doPagination = (currentPageNo) => {
    this.getWallets(currentPageNo, this.state.pagination.limit);
  };

  dofilterData = (query = "") => {
    console.log(query);
    this.getWallets(0, this.state.pagination.limit, query);
    this.setState({
      filterQuery: query
    })
  }

  render() {

    return (
      <div className="page-content">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <h4 className="mb-0 font-size-18">Wallets</h4>
          {/* <span>
        <Link
          to="/offers/add"
          className="btn btn-primary waves-effect waves-light btn-sm"
        >
          Add New Offer <i className="mdi mdi-arrow-right ml-1"></i>
        </Link>
      </span> */}
        </div>
        <Row>
          <Col md={12}>
            <FilterView doFilter={this.dofilterData} filterOptions={walletFilterConfig.filters} />
          </Col>
          <Col md={12}>
            <Table
              striped
              bordered
              hover
              className="table mb-3 pr-0"
              style={{ verticalAlign: "middle" }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>Type</th>
                  <th>Amount</th>
                  {/* <th>Published Date</th> */}
                  {/* <th>State</th>
            <th>Published Date</th> */}
                </tr>
              </thead>
              <tbody>
                {this.state.wallets.map((wallet, i) => {
                  // console.log(offer.newuser);
                  return (
                    <tr key={i}>
                      <td>
                        {wallet.user.firstname + " " + wallet.user.lastname}
                      </td>
                      <td>{wallet.user.phonenumber}</td>
                      <td>{wallet.type}</td>
                      <td>{wallet.amount.toFixed(2)}</td>
                      {/*                     
                    <td>
                      {moment(wallet.published_at)
                        .utc()
                        .format("DD MMM YYYY HH:mm:ss")}
                    </td> */}

                      <td>
                        <Link to={`wallet/${wallet.id}`}>
                          {" "}
                          <Button color="danger">
                            <i className="fas fa-exchange-alt"></i>
                          </Button>
                        </Link>
                        {"    "}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
          {console.log('totalpage', this.state.pagination.totalPages)}
          <Col col={12}>
            <CustomPagination
              currentPage={this.state.pagination.currentPageNo}
              totalPages={this.state.pagination.totalPages}
              perSlide={5}
              doPagination={this.doPagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
