import React from "react";
import { Route, Redirect } from "react-router-dom";
const AppRoute = ({
	component: Component,
	layout: Layout,
	isAuthProtected,
	dispatch:dispatch,
	...rest
}) =>{

	return(
		<Route
			{...rest}
			render={props => {

				if (isAuthProtected && !sessionStorage.getItem("authToken")) {
					return (
						<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
					);
				}


				return (
					<Layout {...props}>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);
		}

export default AppRoute;

