import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";
import WalletAmount from "./WalletAmount";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withNamespaces } from "react-i18next";
import { doGetAuthProfile } from "../../services/authServices";
import { doGetAllPayments, doGetBookings, walletSummary } from "../../services/apiServices";
import moment from "moment";
import { connect } from "react-redux";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.count = 0;
    this.state = {
      reports: [
        { title: "Bookings", iconClass: "bx-copy-alt", description: 0 },

        {
          title: "Revenue",
          iconClass: "bx-archive-in",
          description: 0,
        },
        {
          title: "Average Price",
          iconClass: "bx-purchase-tag-alt",
          description: 0,
        },
      ],
      email: [
        { title: "Week", linkto: "#", isActive: false },
        { title: "Month", linkto: "#", isActive: false },
        { title: "Year", linkto: "#", isActive: true },
      ],
      modal: false,
      user: {},
      bookingsCount: 0,
      revenueArray: [],
      revenueArraybyMonth: [],
      revenueForCharts: [],
      totalRevenue: 0,
      topSellingCities: [],
      customer_wallet: 0,
      technician_wallet: 0,
      citySellingFrequency: {},
      recentBookings: [],
      customerWallet: 0,
      technicianWallet: 0,
    };
    this.togglemodal.bind(this);
  }

  togglemodal = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  componentDidMount() {
    this.init(this.props)
  }

  init = (propsData) => {
    const role = propsData.loginUser.role.name;
    const technicianQuery = "TECHNICIAN"
    const customerQuery = "CUSTOMER"

    if (("" + role).toLowerCase() === "super admin") {
      doGetAuthProfile().then((res) => {
        this.setState((prev) => {
          let st = prev;
          st.user = res.data;
          return st;
        });
      });

      doGetAuthProfile().then((res) => {
        this.setState((prev) => {
          let st = prev;
          st.user = res.data;
          return st;
        });
      });

      // doGetAllWallets(technicianQuery).then(
      //   (res) => {
      //     this.setState((prev) => {
      //       let st = prev;
      //       // st.technicianWallet = res.data;
      //       console.log('technicianWallet', res.data);
      //       return st;
      //     });
      //   },
      //   (err) => { }
      // );
      walletSummary(technicianQuery).then(
        (res) => {
          this.setState((prev) => {
            let st = prev;
            st.technicianWallet = res.data.totalAmount;
            // console.log('technicianWallet', res.data.totalAmount);
            return st;
          });
        },
        (err) => { }
      );

      walletSummary(customerQuery).then(
        (res) => {
          this.setState((prev) => {
            let st = prev;
            st.customerWallet = res.data.totalAmount;
            // console.log('CustomerWallet', res.data);
            return st;
          });
        },
        (err) => { }
      );

      doGetBookings("count").then((res) => {
        this.setState((prev) => {
          let st = prev;
          st.bookingsCount = res.data;
          st.reports[0].description = res.data;
          st.reports[2].description =
            st.reports[1].description / st.reports[0].description;
          return st;
        });
      });
      doGetAllPayments().then((res) => {
        // console.log(res.data.reduce(getSum, 0));
        this.setState((prev) => {
          let st = prev,
            amt = res.data.reduce(getSum, 0);
          st.revenueArray = res.data;
          st.totalRevenue = amt;
          console.log('res.data', res.data);
          st.reports[1].description = amt.toFixed(1);
          st.reports[2].description = Math.round(
            st.reports[1].description / st.reports[0].description
          );
          return st;
        });

        function getSum(total, num) {
          return total + num.amount;
        }

        const today = moment();
        const month = today.month();

        for (let i = 0; i <= month; i++) {
          let monthName = moment().month(i).format("MMM");
          let monthRevenue = 0;
          res.data.map((item) => {
            if (moment(item.created_at).month() == i) {
              monthRevenue += item.amount;
            }
          });
        }
      });

      doGetBookings("count").then((res) => {
        this.setState((prev) => {
          let st = prev;
          st.bookingsCount = res.data;
          st.reports[0].description = res.data;
          st.reports[2].description =
            st.reports[1].description / st.reports[0].description;
          return st;
        });
      });

      doGetAllPayments().then((res) => {
        // console.log(res.data.reduce(getSum, 0));
        this.setState((prev) => {
          let st = prev,
            amt = res.data.reduce(getSum, 0);
          st.revenueArray = res.data;
          st.totalRevenue = amt;
          st.reports[1].description = amt;
          st.reports[2].description = Math.round(
            st.reports[1].description / st.reports[0].description
          );
          return st;
        });

        function getSum(total, num) {
          return total + num.amount;
        }

        const today = moment();
        const month = today.month();

        for (let i = 0; i <= month; i++) {
          let monthName = moment().month(i).format("MMM");
          let monthRevenue = 0;
          res.data.map((item) => {
            if (moment(item.created_at).month() == i) {
              monthRevenue += item.amount;
            }
          });
          this.setState((prev) => {
            let st = prev;
            st.revenueArraybyMonth.push({
              month: monthName,
              revenue: monthRevenue,
            });

            return st;
          });
        }
        let arr = [];
        for (let j = 0; j < 12; j++) {
          arr.push(month >= j ? this.state.revenueArraybyMonth[j].revenue : 0);
        }
        this.setState({
          revenueForCharts: [
            {
              name: "Revenue",
              data: arr,
            },
          ],
        });
      });

      const startOfMonth = moment().subtract(1, "month").startOf("month");

      const endOfMonth = moment().subtract(1, "month").endOf("month");

      doGetBookings(
        "",
        `created_at_gt=${moment
          .utc(startOfMonth)
          .format()}&created_at_lt=${moment.utc(endOfMonth).format()}`
      ).then((res) => {
        // console.log(res.data);
        var countObj = res.data.reduce((a, c) => {
          a[c.address.city] = (a[c.address.city] || 0) + 1;
          return a;
        }, {});
        var top3 = Object.keys(countObj)
          .sort((a, b) => countObj[b] - countObj[a])
          .slice(0, 3);

        // console.log(countObj, top3);

        this.setState({
          citySellingFrequency: countObj,
          topSellingCities: top3,
        });
      });
      doGetBookings("", `_limit=10&_start=1`).then((res) => {
        this.setState({ recentBookings: res.data });
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.loginUser) !== JSON.stringify(nextProps.loginUser) && this.count === 0) {
      this.init(nextProps)
    }
  }

  render() {
    const role = this.props.loginUser.role.name;
    if (("" + role).toLowerCase() !== "super admin") {
      return <></>;
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={this.props.t("Dashboard")}
              breadcrumbItem={this.props.t("Dashboard")}
            />

            <Row>
              <Col xl="4">
                <WelcomeComp
                  user={this.state.user}
                  bookingsCount={this.state.bookingsCount}
                  totalRevenue={this.state.totalRevenue}
                />
                <MonthlyEarning
                  revenueArraybyMonth={
                    this.state.revenueArraybyMonth[moment().month()]
                  }
                />
                <WalletAmount
                  customerWallet={this.state.customerWallet}
                  technicianWallet={this.state.technicianWallet}

                />
              </Col>
              <Col xl="8">
                <Row>
                  {/* Reports Render */}
                  {this.state.reports.map((report, key) => (
                    <Col md="4" key={"_col_" + key}>
                      <Card className="mini-stats-wid">
                        <CardBody>
                          <Media>
                            <Media body>
                              <p className="text-muted font-weight-medium">
                                {report.title}
                              </p>
                              <h4 className="mb-0">{report.title == "Revenue" ? parseFloat(report?.description)?.toFixed(2) : report.description}</h4>
                            </Media>
                            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                              <span className="avatar-title">
                                <i
                                  className={
                                    "bx " + report.iconClass + " font-size-24"
                                  }
                                ></i>
                              </span>
                            </div>
                          </Media>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <TopCities
                  topSellingCities={this.state.topSellingCities}
                  citySellingFrequency={this.state.citySellingFrequency}
                />
              </Col>
            </Row>

            <Row>
              {/* <Col xl="4">
                <SocialSource />
              </Col>
              <Col xl="4">
                <ActivityComp />
              </Col> */}

              <Col xl="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4 float-sm-left">
                      Revenue By Month
                    </CardTitle>
                    {/* <div className="float-sm-right">
                      <ul className="nav nav-pills">
                        {this.state.email.map((mail, key) => (
                          <li className="nav-item" key={"_li_" + key}>
                            <Link
                              className={
                                mail.isActive ? "nav-link active" : "nav-link"
                              }
                              to={mail.linkto}
                            >
                              {mail.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div> */}
                    <div className="clearfix"></div>
                    <StackedColumnChart
                      revenueArraybyMonth={this.state.revenueForCharts}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12">
                <LatestTranaction recentBookings={this.state.recentBookings} />
              </Col>
            </Row>
          </Container>
        </div>
        <Modal
          isOpen={this.state.modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabindex="-1"
          toggle={this.togglemodal}
        >
          <div className="modal-content">
            <ModalHeader toggle={this.togglemodal}>Order Details</ModalHeader>
            <ModalBody>
              <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p>

              <div className="table-responsive">
                <Table className="table table-centered table-nowrap">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Product Name</th>
                      <th scope="col">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage1} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Wireless Headphone (Black)
                          </h5>
                          <p className="text-muted mb-0">$ 225 x 1</p>
                        </div>
                      </td>
                      <td>$ 255</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        <div>
                          <img src={modalimage2} alt="" className="avatar-sm" />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            Hoodie (Blue)
                          </h5>
                          <p className="text-muted mb-0">$ 145 x 1</p>
                        </div>
                      </td>
                      <td>$ 145</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Sub Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Shipping:</h6>
                      </td>
                      <td>Free</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <h6 className="m-0 text-right">Total:</h6>
                      </td>
                      <td>$ 400</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.togglemodal}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
const mapStatetoProps = (state) => {
  return { loginUser: state.Login.user };
};
export default connect(mapStatetoProps)(withNamespaces()(Dashboard));
