import { ImageModal, SEOModal } from "../../classes/classes"
import { getValidatedData } from "../constants"
import { getConvertedImages } from "./images"

export function getConvertedSEOS(seos=[],needOnlyActive=false){

    const data= seos.filter((k)=>{
      return Object.keys(k).length>0
    }).map((seo)=>{

        const id=getValidatedData(seo.id,'number')
        const title=getValidatedData(seo.metatitle)
        const description=getValidatedData(seo.metadescription)
        const keywords = getValidatedData(seo.metakeywords);
        const active=getValidatedData(seo.active,'boolean')
        
        const images = getValidatedData(seo.thumbnail, "object", true)? getConvertedImages([getValidatedData(seo.thumbnail, "object")])
          : [];
        const image = (images.length>0)?images[0]:new ImageModal()

        return new SEOModal(id,title,description,keywords,image,active);
    })

    if(needOnlyActive)
    return data.filter((k)=>k.active);
    return data;

}