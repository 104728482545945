import { BookingModal, BookingStatusMapModal, BookingStatusModal, DisputeModal, PaymentModal, ReviewModal, ServiceModal, UserAddressModal, UserModal } from "../../classes/classes";
import { getValidatedData } from "../constants";
import { getConvertedImages } from "./images";
import { getConvertedServices } from "./services";
import { getConvertedUserAddresses } from "./userAddress";
import { getConvertedUsers } from "./users";

export function getConvertedReviews(reviews = []) {
  return reviews.filter((k)=>{
      return Object.keys(k).length>0
    }).map((review) => {
    const id = getValidatedData(review.id, "number");
    const comments = getValidatedData(review.comments);
    const rating = getValidatedData(review.rating,'number');
    const active = getValidatedData(review.active,'boolean');
    const images = getConvertedImages(getValidatedData(review.images,'array'));
    const tshirt = getValidatedData(review.tshirt,'boolean');
    const greetedCustomer = getValidatedData(review.greeted_customer, "boolean");
    const cleanedWorkplace = getValidatedData(review.cleaned_workplace,'boolean');
    return new ReviewModal(id,comments,rating,images,tshirt,greetedCustomer,cleanedWorkplace,active);
  });
}

export function getConvertedDisputes(disputes = []) {
  return disputes.filter((k)=>{
      return Object.keys(k).length>0
    }).map((dispute) => {
    const id = getValidatedData(dispute.id, "number");
    const problem=getValidatedData(dispute.Problem);
    const active=getValidatedData(dispute.active,'boolean');
    const description=getValidatedData(dispute.description);
    const status=getValidatedData(dispute.status);
    return new DisputeModal(id,problem,description,active,status);
  });
}

export function getConvertedPayments(payments=[]){

    return payments.filter((k)=>{
      return Object.keys(k).length>0
    }).map((payment)=>{
        const id = getValidatedData(payment.id, "number");
        const uid = getValidatedData(payment.paymentUID);
        const amount = getValidatedData(payment.amount, "number");
        const status = getValidatedData(payment.status,'boolean');
        const refundId = getValidatedData(payment.refundId,'number');
        const type = getValidatedData(payment.type);
        const bookingid = getValidatedData(payment.bookingid, "number");
        return new PaymentModal(id, uid, bookingid,type, amount, refundId,status);
    })

}

export function getConvertedBookings(bookings=[]) {
    return bookings.filter((k)=>{
      return Object.keys(k).length>0
    }).map((booking)=>{
        const id=getValidatedData(booking.id,'number');
        const services = getConvertedServices([getValidatedData(booking.serviceid,'object')]);
        const service = (services.length>0)?services[0]:new ServiceModal();
        let users = (getValidatedData(booking.createdby,'object',true))?
        getConvertedUsers([getValidatedData(booking.createdby,'object')]):[];
        const createdby = users.length > 0 ? users[0] : new UserModal();
        users = getConvertedUsers([getValidatedData(booking.assignedto,'object')]);
        const assignedto = users.length > 0 ? users[0] : new UserModal();
        users = (getValidatedData(booking.assignedby,'object',true))?
        getConvertedUsers([getValidatedData(booking.assignedby,'object')]):[];
        const assignedby = users.length > 0 ? users[0] : new UserModal();
        users = (getValidatedData(booking.approved_by,'object',true))?
        getConvertedUsers([getValidatedData(booking.approved_by,'object')]):[];
        const approved_by = users.length > 0 ? users[0] : new UserModal();
        const bookingMedium=getValidatedData(booking.booking_medium);
        const fromTime = getValidatedData(booking.fromtime);
        const toTime = getValidatedData(booking.totime);
        const problem = getValidatedData(booking.problem);
        const code = getValidatedData(booking.bookingId);
        const gstId = getValidatedData(booking.gstId);
        const approvalStatus = getValidatedData(booking.approval_status);

        const payments = (getValidatedData(booking.paymentId, "object",true))?
        getConvertedPayments([getValidatedData(booking.paymentId, "object")]):[];
        const payment = (payments.length>0)?payments[0]:new PaymentModal()

        const disputes=(getValidatedData(booking.dispute, "object",true))?
        getConvertedDisputes([getValidatedData(booking.dispute, "object")]):[];
        const dispute = disputes.length > 0 ? disputes[0] : new DisputeModal();

        const reviews = getValidatedData(booking.review, "object", true)
          ? getConvertedReviews([getValidatedData(booking.review, "object")])
          : [];
        const review = reviews.length > 0 ? reviews[0] : new ReviewModal();

        const addresses=(getValidatedData(booking.address, "object",true))?
        getConvertedUserAddresses([getValidatedData(booking.address, "object")]):[];
        const address = addresses.length > 0 ? addresses[0] : new UserAddressModal();

        let res=new BookingModal(id,service,code,fromTime,toTime,problem,gstId,approvalStatus,payment,
          dispute,review,address,approved_by,createdby,assignedto,assignedby);;
          res.booking_medium=bookingMedium
        return res;
    })
}

export const getConvertedBookingStatus=(statuses=[])=>{

  return statuses.filter((k)=>{
      return Object.keys(k).length>0
    }).map((status)=>{
      const id = getValidatedData(status.id, "number");
      const name = getValidatedData(status.name);
      const description = getValidatedData(status.description);

      return new BookingStatusModal(id,name,description);
    });

}


export const getConvertedBookingStatusMaps=(maps=[])=>{

  return maps.filter((k)=>{
      return Object.keys(k).length>0
    }).map((item)=>{
      const id = getValidatedData(item.id, "number");
      const bookings=getConvertedBookings([getValidatedData(item.bookingid,"object")]);
      const booking=(bookings.length>0)?bookings[0]:new BookingModal()

      const bookingStatuses = getConvertedBookingStatus([getValidatedData(item.bookingstatusid,"object")])
      const bookingStatus = bookingStatuses.length > 0 ? bookingStatuses[0] : new BookingStatusModal();

      const otp = getValidatedData(item.otp, "number");
      const latitude = getValidatedData(item.latitude , "number");
      const longitude = getValidatedData(item.longitude, "number");
      return new BookingStatusMapModal(id,booking,bookingStatus,otp,latitude,longitude);
    });
      
}