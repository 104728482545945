import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Table, Card } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../constants/constants";
// Table Css
// import "../tables.css";
import {
  doGetAllLocations,
  doPutUpdateLocation,
} from "../../../services/apiServices";
import swal from "sweetalert";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import ReactSwitch from "react-switch";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import CustomPagination from "../../../components/Common/CustomPagination";
import { locationFilterConfig } from "./LocationFilterConfig";
import FilterView from "../../../components/Common/FilterView";
class Locations extends Component {
  state = {
    locations: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 5,
    },
    filterQuery: "",
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Cities",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount = (currentPageNo = this.state.pagination.currentPageNo, filterQuery = this.state.filterQuery) => {
    doGetAllLocations("count", ((filterQuery !== "") ? "&" + filterQuery : "")).then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllLocations(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit,
              filterQuery
            );
          }
        );
      },
      (err) => { }
    );
  };

  doPagination = (currentPageNo) => {
    this.getAllLocations(currentPageNo, this.state.pagination.limit);
  };

  getAllLocations = (currentPageNo = 0, limit = 1, filterQuery = this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllLocations("", "_limit=" + limit + "&_start=" + (currentPageNo*limit) + ((filterQuery !== "") ? "&" + filterQuery : "")).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev) => {
          let st = prev;
          st.locations = getConvertedLocations(getValidatedData(res.data, "array"));
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          locations: [],
        });
      }
    );
  };

  doUpdateActiveLocation = (location, active) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateLocation(location.id, JSON.stringify({ active: active })).then(
      (res) => {
        swal("Success", "Succesfully Updated", "success");
        this.getAllLocations();
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        swal("Error", "", "error");
      }
    );
  };

  doDeleteLocation = (location) => {
    swal({
      title: "Are you sure to delete?",
      text: 'You will not be able to recover "' + location.name + '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doPutUpdateLocation(
          location.id,
          JSON.stringify({ blocked: true })
        ).then(
          (res) => {
            swal("Success", "Succesfully Deleted", "success");
            this.getAllLocations();
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  dofilterData = (query = "") => {
    this.getCount(0, query);
  }

  render() {
    const currentSno = this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Cities"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                <Link to="/locations/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={locationFilterConfig.filters} />
              </Col>
              <Col md="12">
                <Table bordered hover className="table mb-3 pr-0">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>GST</th>
                      <th>Pincode Count</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.locations.map((location, index) => {
                      return (
                        <React.Fragment key={location.id}>
                          <tr>
                            <td>{"" + (currentSno + index + 1)}</td>
                            <td>{location.name}</td>
                            <td>{location.description}</td>
                            <td>{location.gst}</td>
                            <td>{"" + location.pincodes.length}</td>
                            <td>
                              <ReactSwitch
                                name="active"
                                uncheckedIcon={<Offsymbol />}
                                className="mr-1"
                                checkedIcon={<OnSymbol />}
                                onChange={() =>
                                  this.doUpdateActiveLocation(
                                    location,
                                    !location.active
                                  )
                                }
                                onColor="#626ed4"
                                checked={location.active}
                              />
                            </td>
                            <td>
                              <Link to={"/locations/" + location.id}>
                                <i
                                  className="fa fa-edit text-warning mr-2"
                                  title="Edit"
                                ></i>
                              </Link>
                              {/* <i onClick={()=>this.doDeleteLocation(location)} className="cursor-pointer fa fa-trash text-danger" title="Delete"></i> */}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Locations);
