import {
  POST_AUTH_LOGIN_URL,
  GET_AUTH_PROFILE_URL,
} from "../constants/apiserviceurls";
import { getAuthToken } from "../constants/constants";
import { RestAxiosService } from "./RestApiService";

export function doPostAuthLogin(body) {
  let headers = { "Content-Type": "application/json" };
  return new Promise((resolve, reject) => {
    return RestAxiosService(POST_AUTH_LOGIN_URL, "POST", body, headers).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doGetAuthProfile() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    return RestAxiosService(GET_AUTH_PROFILE_URL, "GET", false, headers).then(
      (res) => {
        // console.log(res);
        resolve(res);
      },
      (err) => {
        reject(err.data);
      }
    );
  });
}

export function doGetAuthLogout() {
  let headers = { "Content-Type": "application/json", ...getAuthToken() };
  return new Promise((resolve, reject) => {
    resolve("");
    // return RestAxiosService(GET_AUTH_LOGOUT_URL, 'GET',false,headers).then((res) => {
    //     console.log(res);
    //     resolve(res);
    // }, (err) => {
    //     reject(err.data);
    // })
  });
}

export function doGetAuthUpdateSession() {
  const auth = JSON.parse(sessionStorage.getItem("authToken") || {});

  return new Promise((resolve, reject) => {
    resolve(auth);
    // if(typeof auth.hashcode!=='undefined'){
    //     let headers = { "Content-Type": "application/json","Authorization": auth.hashcode};
    // return RestAxiosService(GET_AUTH_UPDATE_URL, 'GET',false,headers).then((res) => {
    //     console.log(res);
    //     resolve(res);
    // }, (err) => {
    //     reject(err.data);
    // })
    // }else{
    //     reject({ error:"no local data"});
    // }
  });
}
