import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// import Dashboard from "../pages/Adani/Dashboard/Dashboard";
import Dashboard from "../pages/Dashboard/index";
import Users from "../pages/Homevery/Users/Users";
import AddUser from "../pages/Homevery/Users/AddUser";
import Locations from "../pages/Homevery/Locations/Locations";
import FormAdvanced from "../pages/Forms/FormAdvanced";

import AddLocation from "../pages/Homevery/Locations/AddLocation";
import Categories from "../pages/Homevery/Categories/Categories";
import Services from "../pages/Homevery/Services/Services";
import AddService from "../pages/Homevery/Services/AddService";
import Customers from "../pages/Homevery/Users/Customer/Customers";
import AddCustomer from "../pages/Homevery/Users/Customer/AddCustomer";
import AddAdmin from "../pages/Homevery/Users/Admin/AddAdmin";
import Admins from "../pages/Homevery/Users/Admin/Admins";
import Technicians from "../pages/Homevery/Users/Technicians/Technicians";
import AddTechnician from "../pages/Homevery/Users/Technicians/AddTechnician";
import Bookings from "../pages/Homevery/Bookings/Bookings";
import AddBooking from "../pages/Homevery/Bookings/AddBooking";
import AddItem from "../pages/Homevery/Inventory/AddItem";
import InventoryItems from "../pages/Homevery/Inventory/InventoryItems";
import BookingOverview from "../pages/Homevery/Bookings/BookingOverview";
import SiteSettings from "../pages/Homevery/SiteSettings/SiteSettings";
import Offers from "../pages/Homevery/Offers/Offers";
import AddOffers from "../pages/Homevery/Offers/AddOffers";
import Payout from "../pages/Homevery/Payout/Payout";
import EditOffers from "../pages/Homevery/Offers/EditOffers";
import Wallets from "../pages/Homevery/Wallet/Wallets";
import WalletId from "../pages/Homevery/Wallet/WalletId";
import ViewSlider from "../pages/Homevery/Slider/ViewSlider";
import AddSlider from "../pages/Homevery/Slider/AddSlider";
import EditSlider from "../pages/Homevery/Slider/EditSlider";
import ContactUs from "../pages/Homevery/Contactus/ContactUs";
import ViewCustomer from "../pages/Homevery/Users/Customer/ViewCustomer";

const authProtectedRoutes = [
  { path: "/dashboard", exact: true, component: Dashboard },
  { path: "/users/admins/all", exact: true, component: Admins },
  { path: "/users/admins/:id", exact: true, component: AddAdmin },
  { path: "/users/customers/all", exact: true, component: Customers },
  { path: "/users/customers/:id", exact: true, component: AddCustomer },
  { path: "/users/customer/view/:id", exact: true, component: ViewCustomer },
  { path: "/users/technicians/all", exact: true, component: Technicians },
  { path: "/users/technicians/:id", exact: true, component: AddTechnician },
  { path: "/w", exact: true, component: FormAdvanced },
  // { path: "/users/all", exact: true, component: Users },
  // { path: "/users/:id", exact: true, component: AddUser },
  { path: "/locations/all", exact: true, component: Locations },
  { path: "/locations/:id", exact: true, component: AddLocation },
  { path: "/categories/all", exact: true, component: Categories },
  { path: "/services/all", exact: true, component: Services },
  { path: "/services/:id", exact: true, component: AddService },
  { path: "/bookings/all", exact: true, component: Bookings },
  { path: "/bookings/:id", exact: true, component: AddBooking },
  { path: "/booking/:id", exact: true, component: BookingOverview },

  // Inventory Routes
  { path: "/inventory/all", exact: true, component: InventoryItems },
  { path: "/inventory/:id", exact: true, component: AddItem },
  // SiteSettings Route
  { path: "/site/settings", exact: true, component: SiteSettings },
  { path: "/site/contacts", exact: true, component: ContactUs },
  // Offers Routes
  { path: "/offers/all", exact: true, component: Offers },
  { path: "/offers/add", exact: true, component: AddOffers },
  { path: "/payout", exact: true, component: Payout },
  { path: "/wallets", exact: true, component: Wallets },
  { path: "/offers/offers/edit/:id", exact: true, component: EditOffers },
  { path: "/wallet/:id", exact: true, component: WalletId },

  //Slider Routes
  { path: "/slider/all", exact: true, component: ViewSlider },
  { path: "/slider/add", exact: true, component: AddSlider },
  { path: "/slider/slider/edit/:id", exact: true, component: EditSlider },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
];

export { authProtectedRoutes, publicRoutes };
