import React, { Component } from "react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");

    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    const role = this.props.loginUser.role.name;
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{this.props.t("Dashboard")}</li>
            <li>
              <Link to="/dashboard" className=" waves-effect">
                <i className="bx bx-home-circle"></i>
                <span>{this.props.t("Home")}</span>
              </Link>
            </li>
            {["super admin"].indexOf(("" + role).toLowerCase()) !== -1 ? (
              <>
                <li className="menu-title">{this.props.t("Users")}</li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-user-friends"></i>
                    {/* fas fa-user-friends */}
                    <span>{this.props.t("Customers")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/users/customers/all">
                        {this.props.t("View Customers")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/users/customers/add">
                        {this.props.t("Add Customer")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-users-cog"></i>
                    <span>{this.props.t("Technicians")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/users/technicians/all">
                        {this.props.t("View Technicians")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/users/technicians/add">
                        {this.props.t("Add Technician")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-user-cog"></i>
                    <span>{this.props.t("Admins")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/users/admins/all">
                        {this.props.t("View Admins")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/users/admins/add">
                        {this.props.t("Add Admin")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <></>
            )}
            {["super admin", "finance admin"].indexOf(
              ("" + role).toLowerCase()
            ) !== -1 ? (
              <>
                <li className="menu-title">{this.props.t("Payments")}</li>
                <li>
                  <Link to="/payout" className=" waves-effect">
                    <i className="fas fa-money-check-alt"></i>
                    <span>{this.props.t("Payout")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/wallets" className=" waves-effect">
                    <i className="fas fa-dollar-sign"></i>
                    <span>{this.props.t("Wallets")}</span>
                  </Link>
                </li>
              </>
            ) : (
              <></>
            )}
            {/* <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-home-circle"></i>
                                <span>{this.props.t('Website Management')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/blogs">{this.props.t('Blogs')}</Link></li>
                                <li><Link to="/careers">{this.props.t('Careers')}</Link></li>
                            </ul>
                        </li> */}
            {["super admin", "service admin"].indexOf(
              ("" + role).toLowerCase()
            ) !== -1 ? (
              <>
                <li className="menu-title">{this.props.t("Bookings")}</li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-home-circle"></i>
                    <span>{this.props.t("Booking Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/bookings/all">
                        {this.props.t("View Bookings")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/bookings/add">
                        {this.props.t("Add Booking")}
                      </Link>
                    </li>
                    {/* <li><Link to="/locations/add">{this.props.t('Add')}</Link></li> */}
                  </ul>
                </li>
                {/* Inventory */}
                <li className="menu-title">{this.props.t("Inventory")}</li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-store"></i>
                    <span>{this.props.t("Inventory Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/inventory/all">
                        {this.props.t("View Items")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventory/add">
                        {this.props.t("Add Item")}
                      </Link>
                    </li>
                    {/* <li><Link to="/locations/add">{this.props.t('Add')}</Link></li> */}
                  </ul>
                </li>
                {/* Inventory End */}
                <li className="menu-title">{this.props.t("Services")}</li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bx-home-circle"></i>
                    <span>{this.props.t("Services Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/categories/all">
                        {this.props.t("View Categories")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/all">
                        {this.props.t("View Services")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/add">
                        {this.props.t("Add Service")}
                      </Link>
                    </li>

                    {/* <li><Link to="/locations/add">{this.props.t('Add')}</Link></li> */}
                  </ul>
                </li>
                <li className="menu-title">{this.props.t("Settings")}</li>
                <li>
                  <Link to="/site/settings">
                    <i className="fas fa-cog"></i> 
                    <span>{this.props.t("Site")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/site/contacts">
                    <i className="fas fa-phone-alt"></i>{" "}
                    <span>{this.props.t("Contacts")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-city"></i>
                    <span>{this.props.t("Operational Cities")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/locations/all">
                        {this.props.t("View Cities")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/locations/add">
                        {this.props.t("Add City")}
                      </Link>
                    </li>
                    {/* <li><Link to="/locations/add">{this.props.t('Add')}</Link></li> */}
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-bullhorn"></i>
                    <span>{this.props.t("Offers")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/offers/all">
                        {this.props.t("View Offers")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/offers/add">{this.props.t("Add Offers")}</Link>
                    </li>
                  </ul>
                </li>{" "}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-sliders-h"></i>
                    <span>{this.props.t("Slider")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/slider/all">
                        {this.props.t("View Sliders")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/slider/add">{this.props.t("Add Slider")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <></>
            )}
            {/* {["super admin", "service admin"].indexOf(
              ("" + role).toLowerCase()
            ) !== -1 ? (
              <>
                <li className="menu-title">{this.props.t("Settings")}</li>
                <li>
                  <Link to="/site/settings">
                    <i className="fas fa-cog"></i> {this.props.t("Site")}
                  </Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-city"></i>
                    <span>{this.props.t("Operational Cities")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/locations/all">
                        {this.props.t("View Cities")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/locations/add">
                        {this.props.t("Add City")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-bullhorn"></i>
                    <span>{this.props.t("Offers")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/offers/all">
                        {this.props.t("View Offers")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/offers/add">{this.props.t("Add Offers")}</Link>
                    </li>
                  </ul>
                </li>{" "}
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-sliders-h"></i>
                    <span>{this.props.t("Slider")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/slider/all">
                        {this.props.t("View Sliders")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/slider/add">{this.props.t("Add Slider")}</Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <></>
            )} */}
            {/* <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-pie-chart"></i>
                                <span>{this.props.t('Reports')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/reports/QA-Management">{this.props.t('QA Management')}</Link></li>
                                <li><Link to="/reports/Material-Management">{this.props.t('Material Management')}</Link></li>
                                <li><Link to="/reports/Audit-Management">{this.props.t('Audit Management')}</Link></li>
                            </ul>
                        </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withRouter(
  connect(mapStatetoProps)(withNamespaces()(SidebarContent))
);
