import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Table } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../../constants/constants";
import { getConvertedUsers } from "../../../../constants/conversions/users";
import {
  doGetAllUsers,
  doGetTechnicianDetailsForExcel,
  doPutUpdateUser,
} from "../../../../services/apiServices";
// Css For Tables
// import "../../tables.css";

import swal from "sweetalert";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../../store/layout/actions";
import CustomPagination from "../../../../components/Common/CustomPagination";
import FilterView from "../../../../components/Common/FilterView";
import { technicianFilterConfig } from "./TechnicianFilterConfig";
import { getConvertedTechnicianDetails } from "../../../../constants/conversions/techniciandetails";

class Technicians extends Component {
  state = {
    details: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 10,
    },
    filterQuery:"",
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Technicians",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount = (currentPageNo=this.state.pagination.currentPageNo,filterQuery=this.state.filterQuery) => {
    doGetTechnicianDetailsForExcel("technician.role.type_eq=technician"+((filterQuery!=="")?"&"+filterQuery:""),"count",).then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllUsers(
              currentPageNo,
              this.state.pagination.limit,
              filterQuery
            );
          }
        );
      },
      (err) => {}
    );
  };

  doPagination = (currentPageNo) => {
    this.getAllUsers(currentPageNo, this.state.pagination.limit);
  };

  getAllUsers = (currentPageNo = 0, limit = 1,filterQuery=this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetTechnicianDetailsForExcel("technician.role.type_eq=technician&_limit=" +
        limit +
        "&_start=" +
        (currentPageNo*limit)+((filterQuery!=="")?"&"+filterQuery:"")
    ).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        console.log(res.data)
        this.setState((prev) => {
          let st = prev;
          st.details = getConvertedTechnicianDetails(
            getValidatedData(res.data, "array")
          )
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          details: [],
        });
      }
    );
  };

  doDeleteUser = (user) => {
    let text=(user.blocked)?"Active":"Inactive";
    swal({
      title: "Are you sure to make "+text+"?",
      text: 'You will not be able to recover "' + user.getFullName() + '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.props.dispatch(changePreloaderStatus(true));
        doPutUpdateUser(user.id, JSON.stringify({ blocked: !user.blocked })).then(
          (res) => {
            swal("Success", "Succesfully Deleted", "success");
            this.setState((prev) => {
              let st = prev;
              st.pagination.count--;
              return st;
            });
            this.getAllUsers(
              this.state.pagination.currentPageNo,
              this.state.pagination.limit
            );
          },
          (err) => {
            this.props.dispatch(changePreloaderStatus(false));
            swal("Error", "", "error");
          }
        );
      }
    });
  };

  dofilterData=(query="") => {
    this.getCount(0,query);
  }

  render() {
    const currentSno =
      this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Technicians"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                {/* <Link to="/users/technicians/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link> */}
                <span>
                  <Link
                    to="/users/technicians/add"
                    style={{ padding: "7px" }}
                    className="btn btn-primary waves-effect waves-light btn-sm float-right"
                  >
                    Add New Technician <i className="fa fa-plus ml-1"></i>
                  </Link>
                </span>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={technicianFilterConfig.filters} />
              </Col>
              <Col md="12">
                <Table striped bordered hover className="table mb-3 pr-0">
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Mobile</th>
                      <th>Email</th>
                      <th>Username</th>
                      <th>Approved</th>
                      <th>Active</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.details.map((detail, index) => {
                      return (
                        <React.Fragment key={detail.id}>
                          <tr>
                            <td>{"" + (currentSno + index + 1)}</td>
                            <td>{detail.technician.getFullName()}</td>
                            <td>{detail.technician.mobile}</td>
                            <td>{detail.technician.email}</td>
                            <td>{detail.technician.userName}</td>
                            <td className="text-center">{(!detail.approved)?(<i
                                  className="fa fa-circle text-danger"
                                  title="Inactive"
                                ></i>):(<i
                                  className="fa fa-circle text-success"
                                  title="Active"
                                ></i>)}</td>
                            <td className="text-center">{(detail.technician.blocked)?(<i
                                  className="cursor-pointer fa fa-circle text-danger"
                                  title="Inactive"
                                  onClick={() => this.doDeleteUser(detail.technician)}
                                ></i>):(<i
                                  className="cursor-pointer fa fa-circle text-success"
                                  title="Active"
                                  onClick={() => this.doDeleteUser(detail.technician)}
                                ></i>)}
                                </td>
                            <td className="text-center">
                              <Link to={"/users/technicians/" + detail.technician.id}>
                                <i
                                  className="fa fa-edit text-warning mr-2"
                                  title="Edit"
                                ></i>
                              </Link>
                              
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Technicians);
