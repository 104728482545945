import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap";
import {
  ImageModal,
  UserAddressModal,
  UserModal,
  UserRoleModal,
} from "../../../../classes/classes";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../../components/homevery/SwitchSymbol/SwitchSymbol";
import {
  getExt,
  getImageUrl,
  getValidatedData,
  numbersregexp,
} from "../../../../constants/constants";
import {
  getConvertedRoles,
  getConvertedUsers,
} from "../../../../constants/conversions/users";
import {
  doGetAllUserRoles,
  doGetAllUsers,
  doPostCreateAdmin,
  doPostCreateUser,
  doPutUpdateUser,
} from "../../../../services/apiServices";
import { changePreloaderStatus } from "../../../../store/layout/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { connect } from "react-redux";
import swal from "sweetalert";

//i18n
import { withNamespaces } from "react-i18next";
import AddressCard from "../Components/AddressCard";
import { doUploadFiles } from "../../../../constants/functions";
const animatedComponents = makeAnimated();
const restrictedext = ["png", "jpg", "jpeg", "webp"];
class AddAdmin extends Component {
  state = {
    action: "",
    user: new UserModal(),
    userProfileUpload: {
      file: {
        name: "files",
        value: "",
        filename: "",
      },
      count: 0,
    },
    roles: [],
    breadcrumbItems: [],
  };

  componentDidMount() {
    const queryPath = this.props.match.params;
    this.initData(queryPath.id);
    this.getAllRoles();
  }

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        user: new UserModal(),
        userProfileUpload: {
          file: {
            name: "files",
            value: "",
            filename: "",
          },
          count: 0,
        },
        breadcrumbItems: [
          {
            active: true,
            route: "/users/admins/all",
            title: "Admins",
          },
          {
            active: true,
            route: "#",
            title: "New Admin",
          },
        ],
      });
    } else {
      this.getUserData(id);
    }
  };

  getUserData = (id = "") => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers(id).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        this.setState({
          action: "edit",
          user: users[0],
          userProfileUpload: {
            file: {
              name: "files",
              value: "",
              filename: "",
            },
            count: 0,
          },
          breadcrumbItems: [
            {
              active: true,
              route: "/users/admins/all",
              title: "Admins",
            },
            {
              active: true,
              route: "/user/" + id,
              title: users[0].getFullName(),
            },
          ],
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          action: "add",
          user: new UserModal(),
          userProfileUpload: {
            file: {
              name: "files",
              value: "",
              filename: "",
            },
            count: 0,
          },
          breadcrumbItems: [
            {
              active: true,
              route: "/users/admins/all",
              title: "Admins",
            },
            {
              active: true,
              route: "#",
              title: "New Admin",
            },
          ],
        });
      }
    );
  };

  getAllRoles = () => {
    doGetAllUserRoles().then(
      (res) => {
        let roles = [];
        if (res.data && res.data.roles) {
          roles = getValidatedData(res.data.roles, "array");
        }
        this.setState({
          roles: getConvertedRoles(roles).filter(
            (role) =>
              role.type.toLowerCase() === "admin" ||
              role.type.toLowerCase() === "super_admin" ||
              role.type.toLowerCase() === "finance_admin" ||
              role.type.toLowerCase() === "service_admin" 
          ),
        });
      },
      (err) => {
        this.setState({
          roles: [],
        });
      }
    );
  };

  onHandleChange = (e) => {
    const { name, files, value } = e.target;
    switch (name) {
      case "profilePic":
        if (files.length > 0) {
          let imagesSelected = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (restrictedext.indexOf(getExt(file.name).toLowerCase()) !== -1) {
              imagesSelected.push({
                name: "files",
                value: file,
                filename: file.name,
              });
            }
          }
          this.setState((prev) => {
            let st = prev;
            st.userProfileUpload.file = { ...imagesSelected[0] };
            return st;
          });
        } else {
          this.setState((prev) => {
            let st = prev;
            st.userProfileUpload.file = {
              name: "files",
              value: "",
              filename: "",
            };
            st.userProfileUpload.count++;
            return st;
          });
        }
        break;
      case "firstName":
        this.setState((prev) => {
          let st = prev;
          st.user.firstName = value;
          return st;
        });
        break;
      case "lastName":
        this.setState((prev) => {
          let st = prev;
          st.user.lastName = value;
          return st;
        });
        break;
      case "userName":
        this.setState((prev) => {
          let st = prev;
          st.user.userName = value;
          return st;
        });
        break;
      case "email":
        this.setState((prev) => {
          let st = prev;
          st.user.email = value;
          return st;
        });
        break;
      case "password":
        this.setState((prev) => {
          let st = prev;
          st.user.password = value;
          return st;
        });
        break;
      case "dob":
        this.setState((prev) => {
          let st = prev;
          st.user.dob = value;
          return st;
        });
        break;
      case "loginType":
        this.setState((prev) => {
          let st = prev;
          st.user.loginType = value;
          return st;
        });
        break;
      case "mobile":
        this.setState((prev) => {
          let st = prev;
          st.user.mobile = value;
          return st;
        });
        break;
      case "alternateMobile":
        this.setState((prev) => {
          let st = prev;
          st.user.alternateMobile = value;
          return st;
        });
        break;
      case "role":
        this.setState((prev) => {
          let st = prev;
          const sRoles = st.roles.filter((role) => "" + role.id === "" + value);
          st.user.role = sRoles.length > 0 ? sRoles[0] : new UserRoleModal();
          return st;
        });
        break;
      case "confirmed":
        this.setState((prev) => {
          let st = prev;
          st.user.confirmed = value;
          return st;
        });
        break;
      case "blocked":
        this.setState((prev) => {
          let st = prev;
          st.user.blocked = value;
          return st;
        });
        break;
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  doFormSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    const roleId=request.role;
    delete request.role;
    doPostCreateAdmin(JSON.stringify(request)).then(
      (res) => {
        const users = getConvertedUsers([
          getValidatedData(res.data.user, "object"),
        ]);
        this.setState(
          {
            action: "edit",
            user: users[0],
          },
          () => {
            const selectedRole = this.state.roles.filter(
              (role) => role.type.toLowerCase() === "super_admin"
            );
            const request1 = {
              role: (roleId!==0)?roleId:(selectedRole.length > 0 ? selectedRole[0].id : undefined),
            };
            this.doFormUpdateSubmit(request1, users[0].id);
            swal("Success", "Succesfully Created", "success");
            this.props.history.push("/users/admins/" + users[0].id);
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  doFormUpdateSubmit = (request, id = -1) => {
    const userId = id !== -1 ? id : this.state.user.id;
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateUser(userId, JSON.stringify(request)).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        this.setState(
          {
            action: "edit",
            user: users[0],
          },
          () => {
            if (id === -1) swal("Success", "Succesfully Updated", "success");
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const user = this.state.user;
    if (
      (this.state.action === "edit" &&
        user.role.id !== 0 &&
        user.loginType !== "") ||
      (this.state.action === "add" && user.loginType !== "")
    ) {
      const callback = (images = [], replace = true) => {
        let request = null;
        if (this.state.action === "add") {
          request = user.getAddRequest(this.props.loginUser);
          if (replace) {
            request.profilepic = images.length > 0 ? images[0].id : undefined;
          }
          this.doFormSubmit(request);
        } else {
          request = user.getUpdateRequest(this.props.loginUser);
          if (replace) {
            request.profilepic = images.length > 0 ? images[0].id : undefined;
          }
          this.doFormUpdateSubmit(request);
        }
      };
      if (this.state.userProfileUpload.file.value !== "") {
        doUploadFiles([this.state.userProfileUpload.file], callback);
      } else {
        callback([], false);
      }
    }
  };

  onHandleAddress = (e, index) => {
    const { name, value } = e.target;
    switch (name) {
      case "flat":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].flat = value;
          return st;
        });
        break;
      case "street":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].street = value;
          return st;
        });
        break;
      case "addressline1":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].addressline1 = value;
          return st;
        });
        break;
      case "landmark":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].landmark = value;
          return st;
        });
        break;
      case "city":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].city = value;
          return st;
        });
        break;
      case "pincode":
        this.setState((prev) => {
          let st = prev;
          if (numbersregexp.test(value) && value.length <= 6)
            st.user.addresses[index].pincode = value;
          return st;
        });
        break;
      case "active":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].active = value;
          return st;
        });
        break;
      case "alias":
        this.setState((prev) => {
          let st = prev;
          st.user.addresses[index].name = value;
          return st;
        });
        break;

      default:
    }
  };

  addAddress = () => {
    this.setState((prev) => {
      let st = prev;
      st.user.addresses.push(new UserAddressModal());
      return st;
    });
  };

  onDeleteAddress = (index) => {
    this.setState((prev) => {
      let st = prev;
      st.user.addresses.splice(index, 1);
      return st;
    });
  };

  onDeleteProfilePic = () => {
    this.setState((prev) => {
      let st = prev;
      st.user.profilePic = new ImageModal();
      return st;
    });
  };

  render() {
    const loginTypes = ["mobile", "web"];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " Admin Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Form className="row userForm" onSubmit={this.onFormSubmit}>
              <Col md={6}>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="firstName"
                    type="text"
                    className="form-control"
                    value={this.state.user.firstName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Surname</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="lastName"
                    type="text"
                    className="form-control"
                    value={this.state.user.lastName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="userName"
                    type="text"
                    className="form-control"
                    value={this.state.user.userName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="email"
                    type="email"
                    className="form-control"
                    value={this.state.user.email}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Mobile Number</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="mobile"
                    type="text"
                    className="form-control"
                    value={this.state.user.mobile}
                    pattern="[6789]{1,1}[0-9]{9}"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Alternate Mobile Number</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="alternateMobile"
                    type="text"
                    className="form-control"
                    value={this.state.user.alternateMobile}
                    pattern="[6789]{1,1}[0-9]{9}"
                    required={this.state.user.alternateMobile.trim() !== ""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="password"
                    type="text"
                    className="form-control"
                    value={this.state.user.password}
                    required={
                      this.state.action === "add" ||
                      this.state.user.password !== ""
                    }
                  />
                </FormGroup>
              </Col>
              {this.state.user.profilePic.id !== 0 ? (
                <>
                  <Col md={6}>
                    <FormGroup>
                      <img
                        alt="."
                        src={getImageUrl(this.state.user.profilePic.url)}
                        width="100"
                        className="img-fluid d-inline-block"
                      />
                      <i
                        className="fa fa-trash text-danger ml-2 cursor-pointer"
                        onClick={this.onDeleteProfilePic}
                      ></i>
                    </FormGroup>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Profile Pic</Label>
                      <Input
                        onChange={this.onHandleChange}
                        name="profilePic"
                        type="file"
                        key={"userpic" + this.state.userProfileUpload.count}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label>Dob</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="dob"
                    type="date"
                    className="form-control"
                    value={this.state.user.dob}
                  />
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label>Login Type</Label>
                  <Select
                    name="loginType"
                    value={{
                      label: this.state.user.loginType,
                      value: this.state.user.loginType,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange({
                        target: { name: "loginType", value: e.value },
                      })
                    }
                    options={loginTypes.map((type) => ({
                      label: type,
                      value: type,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col> */}
                <>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Role</Label>
                      <Select
                        name="role"
                        value={{
                          label: this.state.user.role.name,
                          value: "" + this.state.user.role.id,
                        }}
                        isMulti={false}
                        onChange={(e) =>
                          this.onHandleChange({
                            target: { name: "role", value: "" + e.value },
                          })
                        }
                        options={this.state.roles.map((role) => ({
                          label: role.name,
                          value: "" + role.id,
                        }))}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        required
                      />
                    </FormGroup>
                  </Col>
                </>
              {/* <Col md={6}>
                <FormGroup>
                  <Label className="d-block">Blocked</Label>
                  <ReactSwitch
                    name="blocked"
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "blocked",
                          value: !this.state.user.blocked,
                        },
                      })
                    }
                    checked={this.state.user.blocked}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="d-block">Confirmed</Label>
                  <ReactSwitch
                    name="confirmed"
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "confirmed",
                          value: !this.state.user.confirmed,
                        },
                      })
                    }
                    checked={this.state.user.confirmed}
                  />
                </FormGroup>
              </Col> */}
              <Col md={12}>
                <div className="bg-light px-2 py-3">
                  <h5>
                    Address Section{" "}
                    <i
                      className="fa fa-plus cursor-pointer text-success float-right mr-3"
                      onClick={() => this.addAddress()}
                    ></i>
                  </h5>
                  {this.state.user.addresses.map((address, i1) => {
                    return (
                      <AddressCard
                        address={address}
                        onHandleChange={(e) => this.onHandleAddress(e, i1)}
                        deleteAddress={() => this.onDeleteAddress(i1)}
                      />
                    );
                  })}
                </div>
              </Col>
              <Col md={6}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withNamespaces()(connect(mapStateToProps)(AddAdmin));
