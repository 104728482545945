import { UserModal, UserRoleModal } from "../../classes/classes";
import { getValidatedData } from "../constants";
import { getConvertedImages } from "./images";
import { getConvertedUserAddresses } from "./userAddress";

export function getConvertedUsers(allUsers=[]){
    return allUsers.filter((k)=>{
      return k && Object.keys(k).length > 0;
    }).map((userData)=>{
        const user=new UserModal();
        const userDataRole=getValidatedData(userData.role,'object');
        const role=new UserRoleModal(getValidatedData(userDataRole.id,'number'),
        getValidatedData(userDataRole.name),
        getValidatedData(userDataRole.type))
        user.role=role;
        user.id=getValidatedData(userData.id,'number');
        user.firstName=getValidatedData(userData.firstname);
        user.userName=getValidatedData(userData.username);
        user.lastName=getValidatedData(userData.lastname);
        user.email=getValidatedData(userData.email);
        user.dob=getValidatedData(userData.dob);
        user.mobile=getValidatedData(userData.phonenumber);
        user.provider=getValidatedData(userData.provider);
        user.alternateMobile=getValidatedData(userData.alternatephonenumber);
        user.loginType=(getValidatedData(userData.loginType)!=="")?getValidatedData(userData.loginType):"WEBSITE";
        user.confirmed=getValidatedData(userData.confirmed,'boolean');
        user.provider=getValidatedData(userData.provider);
        user.blocked=getValidatedData(userData.blocked,'boolean');
        user.addresses = getConvertedUserAddresses(getValidatedData(userData.address, "array"));
        const images=getConvertedImages([getValidatedData(userData.profilepic,'object')])
        if(images.length>0){
          user.profilePic=images[0];
        }
        return user;
    }).filter(user=>(user.id!==0))
}

export function getConvertedRoles(roles=[]){
    return roles.filter((k)=>{
      return Object.keys(k).length>0
    }).map((role)=>{
        return new UserRoleModal(getValidatedData(role.id,'number'),
        getValidatedData(role.name),
        getValidatedData(role.type));
    }).filter(role=>role.id!==0);
}