import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap";
import { UserModal, UserRoleModal } from "../../../classes/classes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import { getValidatedData } from "../../../constants/constants";
import {
  getConvertedRoles,
  getConvertedUsers,
} from "../../../constants/conversions/users";
import {
  doGetAllUserRoles,
  doGetAllUsers,
  doPostCreateUser,
  doPutUpdateUser,
} from "../../../services/apiServices";
import { changePreloaderStatus } from "../../../store/layout/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./AddUser.css";
import { connect } from "react-redux";
import swal from "sweetalert";

//i18n
import { withNamespaces } from "react-i18next";
const animatedComponents = makeAnimated();
class AddUser extends Component {
  state = {
    action: "",
    user: new UserModal(),
    roles: [],
    breadcrumbItems: [],
  };

  componentDidMount() {
    const queryPath = this.props.match.params;
    this.initData(queryPath.id);
    this.getAllRoles();
  }

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        user: new UserModal(),
        breadcrumbItems: [
          {
            active: true,
            route: "/user/all",
            title: "Users",
          },
          {
            active: true,
            route: "#",
            title: "New User",
          },
        ],
      });
    } else {
      this.getUserData(id);
    }
  };

  getUserData = (id = "") => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllUsers(id).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        this.setState({
          action: "edit",
          user: users[0],
          breadcrumbItems: [
            {
              active: true,
              route: "/user/all",
              title: "Users",
            },
            {
              active: true,
              route: "/user/" + id,
              title: users[0].getFullName(),
            },
          ],
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          action: "add",
          user: new UserModal(),
          breadcrumbItems: [
            {
              active: true,
              route: "/user/all",
              title: "Users",
            },
            {
              active: true,
              route: "#",
              title: "New User",
            },
          ],
        });
      }
    );
  };

  getAllRoles = () => {
    doGetAllUserRoles().then(
      (res) => {
        let roles = [];
        if (res.data && res.data.roles) {
          roles = getValidatedData(res.data.roles, "array");
        }
        this.setState({
          roles: getConvertedRoles(roles),
        });
      },
      (err) => {
        this.setState({
          roles: [],
        });
      }
    );
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        this.setState((prev) => {
          let st = prev;
          st.user.firstName = value;
          return st;
        });
        break;
      case "lastName":
        this.setState((prev) => {
          let st = prev;
          st.user.lastName = value;
          return st;
        });
        break;
      case "userName":
        this.setState((prev) => {
          let st = prev;
          st.user.userName = value;
          return st;
        });
        break;
      case "email":
        this.setState((prev) => {
          let st = prev;
          st.user.email = value;
          return st;
        });
        break;
      case "password":
        this.setState((prev) => {
          let st = prev;
          st.user.password = value;
          return st;
        });
        break;
      case "loginType":
        this.setState((prev) => {
          let st = prev;
          st.user.loginType = value;
          return st;
        });
        break;
      case "mobile":
        this.setState((prev) => {
          let st = prev;
          st.user.mobile = value;
          return st;
        });
        break;
      case "alternateMobile":
        this.setState((prev) => {
          let st = prev;
          st.user.alternateMobile = value;
          return st;
        });
        break;
      case "role":
        this.setState((prev) => {
          let st = prev;
          const sRoles = st.roles.filter((role) => "" + role.id === "" + value);
          st.user.role = sRoles.length > 0 ? sRoles[0] : new UserRoleModal();
          return st;
        });
        break;
      case "confirmed":
        this.setState((prev) => {
          let st = prev;
          st.user.confirmed = value;
          return st;
        });
        break;
      case "blocked":
        this.setState((prev) => {
          let st = prev;
          st.user.blocked = value;
          return st;
        });
        break;
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  doFormSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPostCreateUser(JSON.stringify(request)).then(
      (res) => {
        const users = getConvertedUsers([
          getValidatedData(res.data.user, "object"),
        ]);
        this.setState(
          {
            action: "edit",
            user: users[0],
          },
          () => {
            swal("Success", "Succesfully Created", "success");
            this.props.history.push("/users/" + users[0].id);
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  doFormUpdateSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateUser(this.state.user.id, JSON.stringify(request)).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const users = getConvertedUsers([getValidatedData(res.data, "object")]);
        this.setState(
          {
            action: "edit",
            user: users[0],
          },
          () => {
            swal("Success", "Succesfully Updated", "success");
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        //console.log(err.data);
      }
    );
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const user = this.state.user;
    if (
      (this.state.action === "edit" &&
        user.role.id !== 0 &&
        user.loginType !== "") ||
      (this.state.action === "add" && user.loginType !== "")
    ) {
      //console.log(user.getAddRequest(this.props.loginUser));
      if (this.state.action === "add") {
        this.doFormSubmit(user.getAddRequest(this.props.loginUser));
      } else {
        this.doFormUpdateSubmit(user.getUpdateRequest(this.props.loginUser));
      }
    }
  };

  render() {
    const loginTypes = ["mobile", "web"];
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " User Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Form className="row userForm" onSubmit={this.onFormSubmit}>
              <Col md={6}>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="firstName"
                    type="text"
                    className="form-control"
                    value={this.state.user.firstName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Surname</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="lastName"
                    type="text"
                    className="form-control"
                    value={this.state.user.lastName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="userName"
                    type="text"
                    className="form-control"
                    value={this.state.user.userName}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Email</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="email"
                    type="email"
                    className="form-control"
                    value={this.state.user.email}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Mobile Number</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="mobile"
                    type="text"
                    className="form-control"
                    value={this.state.user.mobile}
                    pattern="[6789]{1,1}[0-9]{9}"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Alternate Mobile Number</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="alternateMobile"
                    type="text"
                    className="form-control"
                    value={this.state.user.alternateMobile}
                    pattern="[6789]{1,1}[0-9]{9}"
                    required={this.state.user.alternateMobile.trim() !== ""}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="password"
                    type="text"
                    className="form-control"
                    value={this.state.user.password}
                    required={
                      this.state.action === "add" ||
                      this.state.user.password !== ""
                    }
                  />
                </FormGroup>
              </Col>
              {this.state.action === "add" ? (
                <></>
              ) : (
                <>
                  <Col md={6}></Col>
                </>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label>Login Type</Label>
                  <Select
                    name="loginType"
                    value={{
                      label: this.state.user.loginType,
                      value: this.state.user.loginType,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange({
                        target: { name: "loginType", value: e.value },
                      })
                    }
                    options={loginTypes.map((type) => ({
                      label: type,
                      value: type,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              {this.state.action === "add" ? (
                <></>
              ) : (
                <>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Role</Label>
                      <Select
                        name="role"
                        value={{
                          label: this.state.user.role.name,
                          value: "" + this.state.user.role.id,
                        }}
                        isMulti={false}
                        onChange={(e) =>
                          this.onHandleChange({
                            target: { name: "role", value: "" + e.value },
                          })
                        }
                        options={this.state.roles.map((role) => ({
                          label: role.name,
                          value: "" + role.id,
                        }))}
                        classNamePrefix="select2-selection"
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        required
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col md={6}>
                <FormGroup>
                  <Label className="d-block">Blocked</Label>
                  <ReactSwitch
                    name="blocked"
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "blocked",
                          value: !this.state.user.blocked,
                        },
                      })
                    }
                    checked={this.state.user.blocked}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="d-block">Confirmed</Label>
                  <ReactSwitch
                    name="confirmed"
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "confirmed",
                          value: !this.state.user.confirmed,
                        },
                      })
                    }
                    checked={this.state.user.confirmed}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <Button color="primary" type="submit">
                  Submit
                </Button>
              </Col>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withNamespaces()(connect(mapStateToProps)(AddUser));
