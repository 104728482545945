import React, { Component } from "react";
import ReactSwitch from "react-switch";
import {
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Button,
} from "reactstrap";
import {
  ImageModal,
  LocationModal,
  LocationPincodeModal,
} from "../../../classes/classes";
import PincodeExcel from "../../../assets/documents/PincodeExcel.xlsx";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import {
  getExt,
  getImageUrl,
  getValidatedData,
  numbersregexp,
} from "../../../constants/constants";
import { getConvertedUsers } from "../../../constants/conversions/users";
import {
  doGetAllLocations,
  doPostCreateLocation,
  doPutUpdateLocation,
} from "../../../services/apiServices";
import { changePreloaderStatus } from "../../../store/layout/actions";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./AddLocation.css";
import { connect } from "react-redux";
import swal from "sweetalert";
import * as XLSX from "xlsx";

//i18n
import { withNamespaces } from "react-i18next";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import { doUploadFiles } from "../../../constants/functions";
const animatedComponents = makeAnimated();
export const restrictedext = ["xls", "xlsx", "csv"];

class AddLocation extends Component {
  state = {
    action: "",
    location: new LocationModal(),
    pincode: "",
    pinname: "",
    breadcrumbItems: [],
    displayPic: {
      name: "files",
      value: "",
      filename: "",
      fileCount: 0,
    },
    pincodeFileSelectionCount: 0,
  };

  componentDidMount() {
    const queryPath = this.props.match.params;
    this.initData(queryPath.id);
  }

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        location: new LocationModal(),
        pincode: "",
        pincodeFileSelectionCount: 0,
        breadcrumbItems: [
          {
            active: true,
            route: "/locations/all",
            title: "Locations",
          },
          {
            active: true,
            route: "#",
            title: "New Location",
          },
        ],
      });
    } else {
      this.getLocationData(id);
    }
  };

  getLocationData = (id = "") => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllLocations(id).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const locations = getConvertedLocations([
          getValidatedData(res.data, "object"),
        ]);
        this.setState({
          action: "edit",
          location: locations[0],
          pincode: "",
          breadcrumbItems: [
            {
              active: true,
              route: "/locations/all",
              title: "Locations",
            },
            {
              active: true,
              route: "/locations/" + id,
              title: locations[0].name,
            },
          ],
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          action: "add",
          pincode: "",
          location: new LocationModal(),
          breadcrumbItems: [
            {
              active: true,
              route: "/locations/all",
              title: "Locations",
            },
            {
              active: true,
              route: "#",
              title: "New Location",
            },
          ],
        });
      }
    );
  };

  dosetExcelData = (file) => {
    const reader = new FileReader();
    const k = this;
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      /* Update state */
      data.splice(0, 1);
      data = data
        .map((d) => {
          let value1 = "" + d[0],
            value = "" + d[1];
          let pincodeData = new LocationPincodeModal();
          if (
            value.length === 6 &&
            /^[1-9][0-9]{5}$/.test("" + value) &&
            value1 !== ""
          ) {
            pincodeData = new LocationPincodeModal(0, value, value1, true);
          }
          return pincodeData;
        })
        .filter((p) => p.code !== "");
      k.setState((prev) => {
        let st = prev;
        st.location.pincodes = st.location.pincodes.concat(data);
        st.location.pincodes = st.location.pincodes.filter((p, i) => {
          const index = st.location.pincodes.findIndex(
            (s) => s.code === p.code
          );
          return index === i;
        });
        st.pincodeFileSelectionCount++;
        return st;
      });
    };
    reader.readAsBinaryString(file);
  };

  onHandleChange = (e) => {
    const { name, value, files } = e.target;
    switch (name) {
      case "displayPic":
        if (
          files.length > 0 &&
          ["png", "jpg", "jpeg", "webp"].indexOf(
            getExt(files[0].name).toLowerCase()
          ) !== -1
        ) {
          this.setState((prev) => {
            let st = prev;
            st.displayPic = {
              ...st.displayPic,
              value: files[0],
              filename: files[0].name,
            };
            return st;
          });
        }
        break;
      case "pincodesExcel":
        if (
          files.length > 0 &&
          restrictedext.indexOf(getExt(files[0].name).toLowerCase()) !== -1
        ) {
          this.dosetExcelData(files[0]);
        }
        break;
      case "name":
        this.setState((prev) => {
          let st = prev;
          st.location.name = value;
          return st;
        });
        break;
      case "gst":
        this.setState((prev) => {
          let st = prev;
          st.location.gst = value;
          return st;
        });
        break;
      case "description":
        this.setState((prev) => {
          let st = prev;
          st.location.description = value;
          return st;
        });
        break;
      case "active":
        this.setState((prev) => {
          let st = prev;
          st.location.active = value;
          return st;
        });
        break;
      case "pincode":
        if (value.length <= 6 && numbersregexp.test(value)) {
          this.setState({
            pincode: value,
          });
        }
        break;
      case "pinname":
        this.setState({
          pinname: value,
        });
        break;
      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  doFormSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPostCreateLocation(JSON.stringify(request)).then(
      (res) => {
        const locations = getConvertedLocations([
          getValidatedData(res.data, "object"),
        ]);
        this.setState(
          {
            action: "edit",
            location: locations[0],
            pincode: "",
          },
          () => {
            swal("Success", "Succesfully Created", "success");
            this.props.history.push("/locations/" + locations[0].id);
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        console.log(err.data);
      }
    );
  };

  doFormUpdateSubmit = (request) => {
    this.props.dispatch(changePreloaderStatus(true));
    doPutUpdateLocation(this.state.location.id, JSON.stringify(request)).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        const locations = getConvertedLocations([
          getValidatedData(res.data, "object"),
        ]);
        this.setState(
          {
            action: "edit",
            location: locations[0],
            pincode: "",
          },
          () => {
            swal("Success", "Succesfully Updated", "success");
          }
        );
      },
      (err) => {
        swal("Error", "", "error");
        this.props.dispatch(changePreloaderStatus(false));
        console.log(err.data);
      }
    );
  };

  onFormSubmit = async (e) => {
    e.preventDefault();
    const location = this.state.location;
    if (location.pincodes.length > 0) {
      const request = location.getAddRequest(this.props.loginUser);
      this.props.dispatch(changePreloaderStatus(true));
      if (this.state.displayPic.value !== "") {
        const files = await doUploadFiles([this.state.displayPic]);
        if (files.length > 0) {
          request.displayPic = files[0].id;
        }
      }
      if (this.state.action === "add") {
        this.doFormSubmit(request);
      } else {
        this.doFormUpdateSubmit(request);
      }
    }
  };

  onDeletePinCode = (index = 0) => {
    let pincode = this.state.location.pincodes[index];
    if (pincode.id === 0) {
      this.setState((prev) => {
        let st = prev;
        st.location.pincodes.splice(index, 1);
        return st;
      });
    } else {
      this.setState((prev) => {
        let st = prev;
        st.location.pincodes[index].active = false;
        return st;
      });
    }
  };

  onAddPinCode = () => {
    const value = this.state.pincode;
    const value1 = this.state.pinname;
    if (value.length === 6 && /^[1-9][0-9]{5}$/.test(value) && value1 !== "") {
      this.setState((prev) => {
        let st = prev;
        st.pincode = "";
        st.pinname = "";
        st.location.pincodes.push(
          new LocationPincodeModal(0, value, value1, true)
        );
        return st;
      });
    } else {
      alert("Please check pincode or name is not valid");
    }
  };

  onDeleteLocPic = () => {
    this.setState((prev) => {
      let st = prev;
      st.location.displayPic = new ImageModal();
      return st;
    });
  };

  render() {
    const pincodes = this.state.location.pincodes;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.props.t(this.state.action + " Location Data")}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Form className="row userForm" onSubmit={this.onFormSubmit}>
              <Col md={6}>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="name"
                    type="text"
                    className="form-control"
                    value={this.state.location.name}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Description</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="description"
                    type="textarea"
                    className="form-control"
                    value={this.state.location.description}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>GST</Label>
                  <Input
                    onChange={this.onHandleChange}
                    name="gst"
                    type="text"
                    className="form-control"
                    value={this.state.location.gst}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Picture</Label>
                  {this.state.location.displayPic.id === 0 ? (
                    <>
                      <Input
                        onChange={this.onHandleChange}
                        name="displayPic"
                        type="file"
                        className="form-control"
                        key={"displayPic" + this.state.displayPic.fileCount}
                      />
                    </>
                  ) : (
                    <div className="d-flex align-items-center">
                      <img
                        src={getImageUrl(this.state.location.displayPic.url)}
                        alt="."
                        className="img-fluid"
                        width="100px"
                      />
                      <i
                        className="ml-2 fa fa-trash cursor-pointer text-danger"
                        onClick={() => this.onDeleteLocPic()}
                      ></i>
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label className="d-block">Status</Label>
                  <ReactSwitch
                    name="active"
                    uncheckedIcon={<Offsymbol />}
                    className="mr-1"
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={(e) =>
                      this.onHandleChange({
                        target: {
                          name: "active",
                          value: !this.state.location.active,
                        },
                      })
                    }
                    checked={this.state.location.active}
                  />
                </FormGroup>
              </Col>
              <Col md={6}></Col>
              <Col md={6}>
                <h5 className="mb-3">Add Pincode</h5>
                <div className="d-flex mb-3">
                  <Input
                    onChange={this.onHandleChange}
                    name="pincode"
                    type="text"
                    style={{ maxWidth: "200px" }}
                    className="form-control mr-3"
                    value={this.state.pincode}
                    placeholder="Enter Pincode"
                  />
                  <Input
                    onChange={this.onHandleChange}
                    name="pinname"
                    type="text"
                    style={{ maxWidth: "200px" }}
                    className="form-control mr-3"
                    value={this.state.pinname}
                    placeholder="Enter Name"
                  />

                  <Button
                    color="primary"
                    onClick={this.onAddPinCode}
                    className=""
                    type="button"
                  >
                    Add
                  </Button>
                </div>
                <div className="mb-3">
                  <Label>
                    Pincode Excel Upload{" "}
                    <Link to={PincodeExcel} target={"_blank"}>
                      Sample file
                    </Link>
                  </Label>
                  <Input
                    type="file"
                    onChange={this.onHandleChange}
                    name="pincodesExcel"
                    key={"pincodesExcel" + this.state.pincodeFileSelectionCount}
                  />
                </div>
              </Col>
              <Col md={6}>
                <FormGroup>
                  {pincodes.length > 0 ? (
                    <>
                      <Label className="d-block">Pincodes</Label>
                    </>
                  ) : (
                    <></>
                  )}
                  {pincodes.map((pincode, index) => {
                    if (!pincode.active) return <></>;
                    return (
                      <>
                        <div
                          style={{ borderRadius: "1rem" }}
                          className="py-2 px-3 bg-primary text-light mr-2 mb-2 d-inline-block"
                        >
                          <p className="mb-0">
                            {pincode.name + " (" + pincode.code + ")"}{" "}
                            <i
                              className="cursor-pointer fa fa-trash text-danger"
                              onClick={() => this.onDeletePinCode(index)}
                            ></i>
                          </p>
                        </div>
                      </>
                    );
                  })}
                </FormGroup>
              </Col>

              <Col md={6}>
                <Button color="primary" type="submit">
                  Save
                </Button>
              </Col>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return { loginUser: state.Login.user };
};
export default withNamespaces()(connect(mapStateToProps)(AddLocation));
