import React, { useState } from "react";
import "./custompagination.scss"

const CustomPagination = function (props) {
  const [pageNumber, setPageNumber] = useState("");
  const currentPage = props.currentPage || 0;
  const totalPages = typeof (props.totalPages === "number")
    ? props.totalPages
    : 0;
  const perSlide = props.perSlide || 5;

  const handlePageChange = function (event) {
    setPageNumber(event.target.value);
  };

  const handlePageSubmit = function (event) {
    event.preventDefault();
    const enteredPageNumber = parseInt(pageNumber);
    if (
      !isNaN(enteredPageNumber) &&
      enteredPageNumber > 0 &&
      enteredPageNumber <= totalPages
    ) {
      if (typeof props.doPagination !== "undefined") {
        props.doPagination(enteredPageNumber - 1);
      }
    }
  };

  const doPagination = function (num) {
    if (num > -1 && num < totalPages) {
      if (typeof props.doPagination !== "undefined") props.doPagination(num);
    }
  };

  if (totalPages < 1 || currentPage < 0) {
    return <></>;
  } else {
    return (
      <div className="dataTables_paginate">
        <ul className="pagination">
          <li
            className={
              "page-item " + (currentPage > 0 ? "cursor-pointer" : "disabled")
            }
            onClick={(e) => doPagination(currentPage - 1)}
          >
            <div aria-label="Previous" className="page-link noselect">
              Previous
            </div>
          </li>
          {getPaginationList(currentPage, totalPages, perSlide).map(
            (value, index) => {
              return (
                <li
                  className={
                    "page-item cursor-pointer " +
                    (currentPage === value ? "active" : "")
                  }
                  key={"page" + index}
                  onClick={(e) => doPagination(value)}
                >
                  <div className="page-link noselect">{value + 1}</div>
                </li>
              );
            }
          )}
          <li
            className={
              "page-item cursor-pointer " +
              (currentPage < totalPages - 1 ? "" : "disabled")
            }
            onClick={(e) => doPagination(currentPage + 1)}
          >
            <div aria-label="Next" className="page-link noselect">
              <span>Next</span>
            </div>
          </li>
          <li className="page-item total-pages">
            <div className="page-link noselect">
              Total : {totalPages}
            </div>
          </li>
        </ul>
        <form className="page-number-form" onSubmit={handlePageSubmit}>
          <input
            type="number"
            min="1"
            max={totalPages}
            value={pageNumber}
            onChange={handlePageChange}
          />
          <button type="submit">Go</button>
        </form>

      </div>
    );
  }
};

const getPaginationList = (currentPage, totalPages, perSlide = 5) => {
  let pageslist = [];
  if (totalPages > perSlide) {
    let tmp = 0;
    let i = 0;
    if (
      currentPage > parseInt(perSlide / 2) &&
      !(currentPage + 1 >= totalPages - 1 || currentPage + 1 >= totalPages)
    ) {
      console.log(
        "if " + currentPage + " " + parseInt(perSlide / 2) + " " + perSlide
      );
      i = currentPage - parseInt(perSlide / 2);
      if (totalPages - i < perSlide) {
        i = totalPages - perSlide;
      }
      for (; i < totalPages; i++) {
        if (tmp === perSlide) {
          break;
        }
        pageslist.push(i);
        tmp++;
      }
    } else {
      console.log(
        "else " + currentPage + " " + parseInt(perSlide / 2) + " " + perSlide
      );
      i = 0;
      if (currentPage + 1 === totalPages - 1) {
        i = currentPage - parseInt(perSlide / 2);
      } else if (currentPage + 1 > totalPages - 1) {
        i = currentPage - parseInt(perSlide / 2) - 1;
      }
      if (totalPages - i < perSlide) {
        i = totalPages - perSlide;
      }
      for (; i < totalPages; i++) {
        if (tmp === perSlide) {
          break;
        }
        pageslist.push(i);
        tmp++;
      }
    }
  } else {
    for (let i = 0; i < totalPages; i++) {
      pageslist.push(i);
    }
  }
  return pageslist;
};

export default CustomPagination;
