export const bookingFilterConfig={
    filters:[
        {
            label:"Booking Id",
            placeholder:"Search Booking Id",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"bookingId",
        },
        {
            label:"Service",
            placeholder:"Search Service",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"serviceid.name",
        },
        {
            label:"Assigned",
            placeholder:"ALL",
            filterPostfix:"_null",
            value:"",
            options:[{label:"Assigned",value:"false"},{label:"Not Assigned",value:"true"}],
            type:"select",
            key:"assignedto",
        },
        {
            label:"Review",
            placeholder:"ALL",
            filterPostfix:"_null",
            value:"",
            options:[{label:"Reviewed",value:"false"},{label:"Not Reviewed",value:"true"}],
            type:"select",
            key:"review",
        }
    ]
}