import React from "react";
import Select from "react-select";
import ReactSwitch from "react-switch";
import { Card, CardBody, Col, FormGroup, Input, Label } from "reactstrap";
import {
  Offsymbol,
  OnSymbol,
} from "../../../../components/homevery/SwitchSymbol/SwitchSymbol";

import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const AddressCard = (props) => {
  const onHandleChange = props.onHandleChange;
  const deleteAddress = props.deleteAddress;
  const address = props.address;
  return (
    <Card>
      <CardBody className="row">
        <Col md={12}>
          <h5>
            {address.name !== "" ? address.name : "New Address"}{" "}
            <i
              className="fa cursor-pointer 
            fa-trash text-danger float-right mr-3"
              onClick={() => deleteAddress()}
            ></i>
          </h5>
        </Col>
        {/* <Col md={6}>
          <FormGroup>
            <Label>Name</Label>
            <Input
              onChange={onHandleChange}
              name="name"
              type="text"
              className="form-control"
              value={address.name}
              placeholder=""
              required
            />
          </FormGroup>
        </Col> */}
        <Col md={6}>
          <FormGroup>
            <Label>Flat</Label>
            <Input
              onChange={onHandleChange}
              name="flat"
              type="text"
              className="form-control"
              value={address.flat}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Street</Label>
            <Input
              onChange={onHandleChange}
              name="street"
              type="text"
              className="form-control"
              value={address.street}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Address Line</Label>
            <Input
              onChange={onHandleChange}
              name="addressline1"
              type="text"
              className="form-control"
              value={address.addressline1}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Landmark</Label>
            <Input
              onChange={onHandleChange}
              name="landmark"
              type="text"
              className="form-control"
              value={address.landmark}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>City</Label>
            <Input
              onChange={onHandleChange}
              name="city"
              type="text"
              className="form-control"
              value={address.city}
              placeholder=""
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <label>Address Type</label>
          <Select
            name="alias"
            isMulti={false}
            value={{
              label: address.type,
              value: address.type,
            }}
            options={[
              {
                label: "Home",
                value: "Home",
              },
              {
                label: "Work",
                value: "Work",
              },
              {
                label: "Other",
                value: "Other",
              },
            ]}
            onChange={(e) => {
              onHandleChange({
                target: {
                  name: "alias",
                  value: e.value,
                },
              });
              //console.log("Address", address);
            }}
            classNamePrefix="select2-selection"
            closeMenuOnSelect={true}
            components={animatedComponents}
            required
          />
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Pincode</Label>
            <Input
              onChange={onHandleChange}
              name="pincode"
              type="text"
              className="form-control"
              value={address.pincode}
              placeholder=""
              pattern="^[1-9][0-9]{5,5}"
              required
            />
          </FormGroup>
        </Col>
        <Col md={6}>
            <FormGroup>
              <Label>Phone Number</Label>
              <Input 
                onChange={onHandleChange}
                name="phonenumber"
                type="text"
                className="form-control"
                placeholder="Enter Your address related phone number"
                required
                value={address.phoneNumber}
              />
            </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label className="d-block">Active</Label>
            <ReactSwitch
              name="active"
              uncheckedIcon={<Offsymbol />}
              className="mr-1"
              checkedIcon={<OnSymbol />}
              onColor="#626ed4"
              onChange={(e) =>
                onHandleChange({
                  target: {
                    name: "active",
                    value: !address.active,
                  },
                })
              }
              checked={address.active}
            />
          </FormGroup>
        </Col>
      </CardBody>
    </Card>
  );
};

export default AddressCard;
