import { FaqModal } from "../../classes/classes"
import { getValidatedData } from "../constants";

export function getConvertedFaqs(faqs,needOnlyActive=false){

    const data= faqs.filter((k)=>{
      return Object.keys(k).length>0
    }).map((faq)=>{

        const id=getValidatedData(faq.id,'number');
        const title=getValidatedData(faq.title);
        const type = getValidatedData(faq.type);
        const active=getValidatedData(faq.active,'boolean');
        const description=getValidatedData(faq.description);

        return new FaqModal(id, type,title, description, active);
    })

    if(needOnlyActive)
    return data.filter((k)=>k.active);
    return data;
}