import moment from "moment";
import { doPostUploadFiles } from "../services/apiServices";
import { convertToFormData } from "./constants";

export const doUploadFiles = async (files, callback = () => {}) => {
  const formDatas = files.map((file) => convertToFormData(file));
  let result = [];
  await doPostUploadFiles(formDatas).then(
    (res) => {
      // console.log(res);
      result = res
        .map((r) => (r.data.length > 0 ? r.data[0] : { id: -1 }))
        .filter((r) => r.id !== -1);
      callback(result);
    },
    (err) => {
      callback(result);
    }
  );
  return result;
};


export const addMomentMinutes=(m,durationInMinutes=330)=>{
  if(moment(m).isValid()){
    return moment(m).add(durationInMinutes, 'minutes')
  }else{
    return moment()
  }
}