import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import swal from "sweetalert";
import { doUploadFiles } from "../../../constants/functions";
import { doPostSlider } from "../../../services/apiServices";
import SliderFormComponent from "./SliderFormComponent";

class AddSlider extends Component {
  state = {
    title: "",
    type: "",
    order: "",
    image: "",
    iframeurl: "ssd",
    description: "",
    active: true,
  };
  handleChange = (key, value) => {
    this.setState((st) => {
      let temp = st;
      if (key === "image") {
        temp[key] = value;
      } else temp[key] = value;
      return temp;
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const callback = (images = [], replace = true) => {
      let request = {
        title: this.state.title,
        type: this.state.type,
        order: this.state.order,
        image: images.length > 0 ? images[0].id : undefined,
        iframeUrl: this.state.iframeurl,
        description: this.state.description,
        active: this.state.active,
      };
      doPostSlider(request).then(
        (res) => {
          swal("Success", "Slider added successfully", "success");
          const { location, history } = this.props;
          history.push("/slider/all");
        },
        (err) => {
          swal("Error", "Something went wrong", "error");
          //console.log(err);
        }
      );

      //console.log(request);
    };
    if (this.state.image !== "") {
      let image = {
        name: "files",
        value: this.state.image,
        filename: this.state.image.name,
      };
      doUploadFiles([image], callback);
    } else {
      callback([], false);
    }
  };

  render() {
    return (
      <div className="page-content">
        <div>
          <h4 className="mb-0 font-size-18">Add Slider</h4>
        </div>
        <SliderFormComponent
          state={this.state}
          handleChange={this.handleChange}
        />
        <Row>
          <Col md={6}>
            <Button
              color="primary"
              type=""
              onClick={(e) => this.handleSubmit(e)}
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(AddSlider);
