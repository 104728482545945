import React, { Component } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ReactSwitch from "react-switch";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import { InventoryItemModal } from "../../../classes/classes";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import { INVENTORY_ADD_ITEM } from "../../../constants/apiserviceurls";
import { getValidatedData } from "../../../constants/constants";
import { getConvertedLocations } from "../../../constants/conversions/locations";
import { getConvertedServices } from "../../../constants/conversions/services";
import {
  doGetAllLocations,
  doGetAllServices,
  doGetInventoryItem,
  doPostAddInventoryItem,
  doPutUpdateInventoryItem,
} from "../../../services/apiServices";
import { changePreloaderStatus } from "../../../store/actions";

const animatedComponents = makeAnimated();

class AddItem extends Component {
  state = {
    services: [],
    action: "",
    locations: [],
    item: new InventoryItemModal(),
    breadCrumbItems: [],
    city: "",
  };

  onHandleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "itemName":
        //console.log("itemName :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.item_name = value;
          return st;
        });
        break;
      case "itemDesc":
        //console.log("itemDesc :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.item_description = value;
          return st;
        });

        break;
      case "itemType":
        //console.log("item Type :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.item_type = value;
          return st;
        });

        break;

      case "itemPrice":
        //console.log("item Price :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.item_price = value;
          st.item.price_mask = this.getItemMask(
            value,
            this.state.item.commission,
            18
          ).toString();
          return st;
        });

        break;

      case "Service":
        //console.log("Service___ :-", value);
        doGetAllServices(value).then(
          (response) => {
            //console.log("Single Asset Response :- ", response.data);
            this.setState((prev) => {
              let st = prev;
              st.item.service = response.data;
              return st;
            });
          },
          (err) => {
            //console.log("Single Error :- ", err);
          }
        );

        break;

      case "City":
        //console.log("City :- ", value);
        doGetAllLocations(value).then(
          (response) => {
            //console.log("Single Location Response :- ", response.data.name);
            this.setState((prev) => {
              let st = prev;
              st.item.city = response.data;
              return st;
            });
            this.setState({
              city: response.data.name,
            });
          },
          (err) => {
            //console.log("Single Location Error :- ", err);
          }
        );

        break;

      case "itemMask":
        //console.log("Item Mask :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.item_mask = value;
          return st;
        });

        break;

      case "commision":
        //console.log("Commision :- ", value);
        this.setState((prev) => {
          let st = prev;
          st.item.commission = value;
          st.item.price_mask = this.getItemMask(
            this.state.item.item_price,
            value,
            18
          ).toString();
          return st;
        });
        //console.log(
        //   "Item Mask :- ",
        //   this.getItemMask(this.state.item.item_price, value, 18)
        // );
        break;

      default:
        break;
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const queryPath = this.props.match.params;
    const queryPath1 = nextProps.match.params;
    if (queryPath.id !== queryPath1.id) {
      this.initData(queryPath1.id);
    }
  }

  componentDidMount() {
    this.getAllServices();
    this.getAllLocations();
    const queryPath = this.props.match.params;
    //console.log("Query Path :- ", queryPath);
    this.initData(queryPath.id);
    //console.log("Starting State :- ", this.state.item);
  }

  initData = (id) => {
    if (id === "add") {
      this.setState({
        action: "add",
        item: new InventoryItemModal(),
        breadCrumbItems: [
          {
            active: true,
            route: "/inventory/all",
            title: "Inventory",
          },
          {
            active: true,
            route: "#",
            title: "New Item",
          },
        ],
      });
    } else {
      this.getItem(id);
    }
  };

  getItem = (id) => {
    // this.props.dispatch(changePreloaderStatus(true));
    doGetInventoryItem(id).then(
      (res) => {
        // this.props.dispatch(changePreloaderStatus(false));
        const item = getValidatedData(res.data, "object");
        //console.log("Items :- ", item);
        this.setState({
          action: "edit",
          breadCrumbItems: [
            {
              active: true,
              route: "inventory/all",
              title: "inventory",
            },
            {
              active: true,
              route: "#",
              title: item.id,
            },
          ],
          item: item,
        });
      },
      (err) => {
        //console.log("Item Response Error :- ", err);
        this.setState({
          action: "add",
          item: new InventoryItemModal(),
          breadCrumbItems: [
            {
              active: true,
              route: "inventory/all",
              title: "Inventory",
            },
            {
              active: true,
              route: "#",
              title: "New Item",
            },
          ],
        });
      }
    );
  };

  getAllServices = () => {
    doGetAllServices().then(
      (res) => {
        //console.log("Get All Services Result :- ", res.data);
        this.setState({
          services: getConvertedServices(getValidatedData(res.data, "array")),
        });
      },
      (err) => {
        this.setState({
          services: [],
        });
      }
    );
  };
  getAllLocations = () => {
    doGetAllLocations().then(
      (res) => {
        //console.log("Locations :- ", res.data);
        this.setState({
          locations: getConvertedLocations(getValidatedData(res.data, "array")),
        });
      },
      (err) => {
        this.setState({
          locations: [],
        });
      }
    );
  };

  updateInventoryItem = (data) => {
    swal({
      title: "Are you sure to Update?",
      text:
        'You will not be able to recover "' +
        this.state.item.item_name +
        '" Data',
      type: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        doPutUpdateInventoryItem(this.props.match.params.id, data).then(
          (response) => {
            //console.log("Update Item Response :- ", response);
            if (response.status === 200) {
              swal(
                "Success",
                "Updation of Item is Successfull",
                "success"
              ).then((updated) => {
                if (updated) {
                  this.getItem(this.props.match.params.id);
                }
              });
            } else {
              swal("Error", "SomeThing Unexpected Happened", "error");
            }
          },
          (err) => {
            //console.log("Update Item Error :- ", err);
            swal("Error", "SomeThing Unexpected Happened", "error");
          }
        );
      } else {
        swal("Cancelled", "Updation Cancelled", "error");
        this.getItem(this.props.match.params.id);
        //console.log(this.state.item);
      }
    });
  };

  AddInventoryItem = (data) => {
    //console.log("Add Inventory Item Data :- ", data);
    doPostAddInventoryItem(data).then(
      (res) => {
        //console.log("Do Add Inventory Item Response :- ", res);
        if (res.status === 200) {
          swal("Success", "Item Addition is Successfull", "success").then(
            () => {
              window.location.reload();
            }
          );
        } else {
          swal("Error", "SomeThing Unexpected Happened!", "error");
        }
      },
      (err) => {
        //console.log("Do Add Item Error :- ", err);
        swal(
          "Error",
          err.data.message !== undefined ? err.data.message : err.data,
          "error"
        );
      }
    );
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    {
      this.state.action === "edit"
        ? this.updateInventoryItem(this.state.item)
        : this.AddInventoryItem(this.state.item);
    }
  };

  // TODO: Get Services Based on City
  getServicesBasedOnCity = (services = [], cityName = "") => {
    return services.filter((s) => {
      const selectedCities = s.serviceLocations
        .filter((sl) => {
          return sl.city.name.toLowerCase() === cityName.toLowerCase();
        })
        .filter((s) => s.city.active);
      return selectedCities.length > 0;
    });
  };

  getItemMask(itemPrice, commission, gst) {
    let itemMask =
      Number(itemPrice) + Number(itemPrice * (commission / 100) * (gst / 100));
    return itemMask;
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={this.state.action + " Inventory Item"}
              breadCrumbItems={this.state.breadCrumbItems}
            />
            <Form className="row userForm" onSubmit={this.onFormSubmit}>
              <Col md={6}>
                <FormGroup>
                  <Label>Item Name</Label>
                  <Input
                    name="itemName"
                    value={this.state.item.item_name}
                    onChange={this.onHandleChange}
                    placeholder="Enter Item Name"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Item Description</Label>
                  <Input
                    name="itemDesc"
                    value={this.state.item.item_description}
                    onChange={this.onHandleChange}
                    placeholder="Enter Item Description"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Item Type</Label>
                  <Select
                    name="itemType"
                    isMulti={false}
                    value={{
                      label: this.state.item.item_type,
                      value: this.state.item.item_type,
                    }}
                    onChange={(e) => {
                      this.onHandleChange({
                        target: {
                          name: "itemType",
                          value: e.value,
                        },
                      });
                      //console.log("Type :- ", e.value);
                    }}
                    options={[
                      {
                        label: "Service Charge",
                        value: "SERVICECHARGE",
                      },
                      {
                        label: "Spare Part",
                        value: "SPAREPARTS",
                      },
                    ]}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Item Price</Label>
                  <Input
                    name="itemPrice"
                    value={this.state.item.item_price}
                    onChange={this.onHandleChange}
                    placeholder="Enter Item Price"
                    type="number"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>City</Label>
                  <Select
                    name="City"
                    isMulti={false}
                    value={{
                      label: this.state.item.city.name,
                      value: this.state.item.city.id,
                    }}
                    onChange={(e) => {
                      this.onHandleChange({
                        target: {
                          name: "City",
                          value: e.value,
                        },
                      });
                      // console.log("Selected City NAme :- ", this.state.city);
                    }}
                    options={this.state.locations.map((location) => ({
                      label: location.name,
                      value: location.id,
                    }))}
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Service</Label>
                  <Select
                    name="Service"
                    value={{
                      label: this.state.item.service.name,
                      value: this.state.item.service.id,
                    }}
                    isMulti={false}
                    onChange={(e) =>
                      this.onHandleChange(
                        {
                          target: {
                            name: "Service",
                            value: e.value,
                          },
                        }
                        //console.log("1", this.state.item.service),
                        //console.log(e)
                      )
                    }
                    options={this.getServicesBasedOnCity(
                      this.state.services,
                      this.state.city
                    ).map((s) => ({
                      label: s.name,
                      value: s.id,
                    }))}
                    placeholder="Choose Service"
                    classNamePrefix="select2-selection"
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    required
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Commision</Label>
                  <Input
                    name="commision"
                    value={this.state.item.commission}
                    onChange={this.onHandleChange}
                    type="number"
                    placeholder="Enter Item Commision"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Price Mask</Label>
                  <Input
                    name="itemMask"
                    value={this.getItemMask(
                      this.state.item.item_price,
                      this.state.item.commission,
                      18
                    )}
                    onChange={this.onHandleChange}
                    placeholder="Enter Item Mask"
                    required
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <Button color="primary" type="submit">
                  {this.state.action === "edit" ? "Update" : "Save"}
                </Button>
              </Col>
            </Form>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default AddItem;
