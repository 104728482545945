export const offerFilterConfig={
    filters:[
        {
            label:"Name",
            placeholder:"Search Name",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"name",
        },
        {
            label:"City",
            placeholder:"Search City",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"city.name",
        },
        {
            label:"Service",
            placeholder:"Search Service",
            type:"text",
            value:"",
            filterPostfix:"_contains",
            key:"service.name",
        },
        {
            label:"From Date",
            placeholder:"Search Date",
            type:"date",
            value:"",
            filterPostfix:"_gte",
            key:"fromDate",
        },
        {
            label:"To Date",
            placeholder:"Search Date",
            type:"date",
            value:"",
            filterPostfix:"_lte",
            key:"toDate",
        }
    ]
}