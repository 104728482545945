import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Table, Card } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getValidatedData } from "../../../constants/constants";
// Tables Css
//import "../tables.css";
import {
  doGetAllServices,
  doPutUpdateService,
} from "../../../services/apiServices";
import { connect } from "react-redux";
import { changePreloaderStatus } from "../../../store/layout/actions";
import { getConvertedServices } from "../../../constants/conversions/services";
import {
  Offsymbol,
  OnSymbol,
} from "../../../components/homevery/SwitchSymbol/SwitchSymbol";
import CustomPagination from "../../../components/Common/CustomPagination";
import { serviceFilterConfig } from "./ServiceFilterConfig";
import FilterView from "../../../components/Common/FilterView";

class Services extends Component {
  state = {
    services: [],
    pagination: {
      count: 0,
      currentPageNo: 0,
      totalPages: 0,
      limit: 5,
    },
    filterQuery:"",
    breadcrumbItems: [
      {
        active: true,
        route: "#",
        title: "Services",
      },
    ],
  };

  componentDidMount() {
    this.getCount();
  }

  getCount = (currentPageNo=this.state.pagination.currentPageNo,filterQuery=this.state.filterQuery) => {
    doGetAllServices("count", ((filterQuery!=="")?"&"+filterQuery:"")).then(
      (res) => {
        this.setState(
          (prev) => {
            let st = prev;
            try {
              st.pagination.count = parseInt(res.data);
            } catch (r) {
              st.pagination.count = 0;
            }
            return st;
          },
          () => {
            this.getAllServices(
              currentPageNo,
              this.state.pagination.limit,
              filterQuery
            );
          }
        );
      },
      (err) => {}
    );
  };

  doPagination = (currentPageNo) => {
    this.getAllServices(currentPageNo, this.state.pagination.limit);
  };

  getAllServices = (currentPageNo = 0, limit = 1,filterQuery=this.state.filterQuery) => {
    this.props.dispatch(changePreloaderStatus(true));
    doGetAllServices("", "_limit=" + limit + "&_start=" + (currentPageNo*limit)+((filterQuery!=="")?"&"+filterQuery:"")).then(
      (res) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState((prev) => {
          let st = prev;
          st.services=getConvertedServices(getValidatedData(res.data, "array"));
          st.pagination.limit = limit;
          const total = st.pagination.count / st.pagination.limit;
          st.pagination.totalPages = Math.ceil(total);
          st.pagination.currentPageNo = currentPageNo;
          return st;
        });
      },
      (err) => {
        this.props.dispatch(changePreloaderStatus(false));
        this.setState({
          services: [],
        });
      }
    );
  };

  dofilterData=(query="") => {
    this.getCount(0,query);
  }

  render() {
    const currentSno=this.state.pagination.currentPageNo * this.state.pagination.limit;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Services"}
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col md="12" className="mb-3 mt-3">
                <Link to="/services/add" className="float-right">
                  <Button className="btn-success">
                    <i className="fa fa-plus"></i>
                  </Button>
                </Link>
              </Col>
              <Col md={12}>
                <FilterView doFilter={this.dofilterData} filterOptions={serviceFilterConfig.filters} />
              </Col>
              <Col md="12">
                <Table
                  // striped
                  bordered
                  hover
                  className="table mb-3 pr-0"
                >
                  <thead>
                    <tr>
                      <th>S.no</th>
                      <th>Name</th>
                      <th>Category</th>
                      <th>Faqs</th>
                      <th>Service Locations</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log("service",this.state.services)};
                    {this.state.services.map((service, index) => {
                      return (
                        <React.Fragment key={service.id}>
                          <tr>
                            <td>{"" + (currentSno+index + 1)}</td>
                            <td>{service.name}</td>
                            <td>{service.category.name}</td>
                            <td>{"" + service.faqs.length}</td>
                            <td>{"" + service.serviceLocations.length}</td>
                            <td>
                              <Link to={"/services/" + service.id}>
                                <i
                                  className="fa fa-edit text-warning mr-2"
                                  title="Edit"
                                ></i>
                              </Link>
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
              <Col col={12}>
                <CustomPagination
                  currentPage={this.state.pagination.currentPageNo}
                  totalPages={this.state.pagination.totalPages}
                  perSlide={5}
                  doPagination={this.doPagination}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(Services);
